import "./App.css";
import React, { useEffect } from "react";
import OTP from "./component/OTP";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";

import ForgotPassword from "./component/ForgotPassword";
import Dashboard from "./component/Dashboard";
import PrivateRoute from "./component/PrivateRoute";
import Home from "./component/Home";
import Eventspage from "./component/Eventspage";
import EventInvitation from "./component/eventComponent/EventInvitation";
import SignUp from "./component/Signup/SignUp";
import Login from "./component/Login/Login";
import ServiceProviderLogin from "./component/ServiceProviderLogin/ServiceProviderLogin";
import { RSVPInvitationPage } from "./component/RSVPInvitationPage/RSVPInvitationPage";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "./store/authSlice";
import { getToken } from "./util/Cookies";
import { getRoleFromToken, isTokenExpired } from "./util/authUtils";
import ProtectedRoute from "./component/ProtectedRoute/ProtectedRoute";
import GuestReponseList from "./component/GusetsResponseList/GuestReponseList";
import ServiceProviderSignup from "./component/ServiceProviderSignup/ServiceProviderSignup";
import ServiceProviderOTP from "./component/ServiceProviderOTP";
import ServiceProviderForgotPassword from "./component/ServiceProviderForgotPassword";
import ServiceProviderDashboard from "./component/ServiceProvider/ServiceProviderDashboard";
import EventServices from "./component/EventServices/EventServices";
import EventFormDashboardAdd from "./component/eventComponent/EventFormDashboardAdd";
import EventFormDashboardEdit from "./component/eventComponent/EventFormDashboardEdit";
import EventProviderServices from "./component/EventServices/EventProviderServices";
import MyAccountpage from "./component/MyAccountpage";
import Servicespage from "./component/Services/Servicespage";
import Aboutuspage from "./component/Aboutuspage";
import Contactuspage from "./component/Contactuspage";
import Privacypolicypage from "./component/Privacypolicypage";
import ComingSoon from "./component/ComingSoon/ComingSoon";
import { homePagePath } from "./constant";

function App() {
  const dispatch = useDispatch();
  const token = getToken();
  const isLoggedIn = token && !isTokenExpired(token);
  const role = token ? getRoleFromToken(token) : null;

  useEffect(() => {
    if (isLoggedIn) {
      console.log("Allready Logged In ", token);
      dispatch(setCredentials({ token }));
    }
  }, [dispatch, isLoggedIn]);

  const getRedirectPath = () => {
    switch (role) {
      case "customer":
        return "/dashboard";
      case "admin":
        return "/admin-dashboard";
      case "provider":
        return "/service-provider/dashboard";
      default:
        return homePagePath;
    }
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                <Navigate to={getRedirectPath()} replace />
              ) : (
                <Login />
              )
            }
          />
          <Route
            exact
            path="/invitation/:sharedToken"
            // Component={EventInvitation}
            Component={RSVPInvitationPage}
          />
          {/* <Route path="/" element={<ComingSoon />} /> */}

          <Route
            path={homePagePath}
            element={
              isLoggedIn ? (
                <Navigate to={getRedirectPath()} replace />
              ) : (
                <Home />
              )
            }
          />

          <Route path="/otp" Component={OTP} />
          <Route
            path="/signup"
            element={
              isLoggedIn ? (
                <Navigate to={getRedirectPath()} replace />
              ) : (
                <SignUp />
              )
            }
          />
          <Route path="/forgot-password" Component={ForgotPassword}></Route>

          <Route path="/about-us" Component={Aboutuspage}></Route>
          <Route path="/contact" Component={Contactuspage}></Route>
          <Route path="/privacy-policy" Component={Privacypolicypage}></Route>

          <Route exact={true} path="/event/:slug" Component={Eventspage} />

          {/* <Route exact={true} path="/service/:slug" Component={Servicespage} /> */}

          <Route
            path="/service/:slug"
            element={
              isLoggedIn && role === "provider" ? (
                <Navigate to="/service-provider/dashboard" replace />
              ) : (
                <Servicespage />
              )
            }
          />

          <Route
            path="/service-provider/login"
            element={
              isLoggedIn ? (
                <Navigate to="/service-provider/dashboard" replace />
              ) : (
                <ServiceProviderLogin />
              )
            }
          />
          <Route path="/service-provider/otp" Component={ServiceProviderOTP} />
          <Route
            path="/service-provider/signup"
            element={
              isLoggedIn ? (
                <Navigate to="/service-provider/dashboard" replace />
              ) : (
                <ServiceProviderSignup />
              )
            }
          />
          <Route
            path="/service-provider/forgot-password"
            Component={ServiceProviderForgotPassword}
          ></Route>

          {/* This Path Should be for Customer only  */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiredRole="customer">
                <Dashboard />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/dashboard/my-account"
            element={
              <ProtectedRoute requiredRole="customer">
                <MyAccountpage />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/dashboard/event/guests"
            element={
              <ProtectedRoute requiredRole="customer">
                <GuestReponseList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/dashboard/event/create"
            element={
              <ProtectedRoute requiredRole="customer">
                <EventFormDashboardAdd />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/dashboard/event/edit"
            element={
              <ProtectedRoute requiredRole="customer">
                <EventFormDashboardEdit />
              </ProtectedRoute>
            }
          ></Route>

          {/* <Route
            path="/dashboard/event/know-what-how"
            element={
              <ProtectedRoute requiredRole="customer">
                <EventChatGPT />
              </ProtectedRoute>
            }
          ></Route> */}

          {/* This Path Should be for Customer only  */}

          {/* This Path Should be for Provider only  */}

          <Route
            path="/providers"
            element={
              <ProtectedRoute requiredRole="customer">
                <EventServices />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/provider/services"
            element={
              <ProtectedRoute requiredRole="customer">
                <EventProviderServices />
              </ProtectedRoute>
            }
          ></Route>

          {/* Admin Routes */}
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute requiredRole="admin">
                {/* <AdminDashboard /> */}
                <h1>Admin</h1>
              </ProtectedRoute>
            }
          />

          {/* This Path Should be for Provider Only */}
          <Route
            path="/service-provider/dashboard"
            element={
              <ProtectedRoute requiredRole="provider">
                <ServiceProviderDashboard />
              </ProtectedRoute>
            }
          ></Route>

          {/* Fallback URL  */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
