import React, { useEffect, useState, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Checkbox,
  Typography,
  Autocomplete,
  TextField,
  createFilterOptions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { searchSelectEventText } from "../../constant";

/**
 * Reusable TreeCheckbox component with responsive layout.
 * @param {Object[]} data - Array of event categories with nested events.
 * @param {Function} onSelectionChange - Callback function to get selected events.
 */
const CollapseExpandTree = ({
  data,
  onSelectionChange,
  preSelectedData,
  categoryChildListName,
  categoryName,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isTabletOrLarger, setIsTabletOrLarger] = useState(
    window.innerWidth >= 768
  );
  const [selectedEvents, setSelectedEvents] = useState([...preSelectedData]);
  const [expanded, setExpanded] = useState(null);

  const availableEvents = useMemo(() => {
    return data
      ?.map((category) => {
        return category?.[categoryChildListName] || [];
      })
      .flat()
      .sort((a, b) => a?.[categoryName].localeCompare(b?.[categoryName]));
  }, [data]);

  // Handle event selection
  const handleEventChange = (selectedEvent) => {
    const isSelected = selectedEvents.some((e) => e.id === selectedEvent.id);
    const newSelected = isSelected
      ? selectedEvents.filter((e) => e.id !== selectedEvent.id)
      : [...selectedEvents, selectedEvent];

    setSelectedEvents(newSelected);
    onSelectionChange(newSelected);
  };

  // Handle expand/collapse toggle
  const handleExpand = (categoryId) => {
    setExpanded(expanded === categoryId ? null : categoryId);
  };

  // ResizeObserver for detecting width changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) return;
      const newWidth = entries[0].contentRect.width;
      if (newWidth !== screenWidth) {
        setScreenWidth(newWidth);
        setIsTabletOrLarger(newWidth >= 768);
      }
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, [screenWidth]);

  // Memoized grouped data (only for tablet/laptop, else show rows directly)
  const groupedData = useMemo(() => {
    return isTabletOrLarger
      ? data.reduce((acc, _, i) => {
          if (i % 2 === 0) acc.push(data.slice(i, i + 2));
          return acc;
        }, [])
      : data.map((item) => [item]);
  }, [data, isTabletOrLarger]);

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    trim: true,
    stringify: (option) => option?.[categoryName],
  });

  return (
    <>
      <Autocomplete
        multiple
        freeSolo
        filterOptions={(options, state) => {
          // Filter options only if the length of the input is greater than or equal to 3 characters
          if (state.inputValue.length >= 3) {
            return options.filter((option) =>
              option?.[categoryName]
                ?.toLowerCase()
                .includes(state.inputValue.toLowerCase())
            );
          }
          return []; // Return an empty array if less than 3 characters are entered
        }}
        limitTags={1}
        id="events-select"
        options={availableEvents}
        value={selectedEvents}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.[categoryName]}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selectedEvents?.some((e) => e.id === option.id)}
                onChange={() => handleEventChange(option)}
              />
              {option?.[categoryName]}
            </li>
          );
        }}
        style={{ width: "100%", marginBottom: "20px" }}
        renderInput={(params) => (
          <TextField {...params} label={searchSelectEventText} />
        )}
        onChange={(_, newEvents) => {
          setSelectedEvents(newEvents);
          onSelectionChange(newEvents);
        }}
      />
      {groupedData.map((pair, idx) => (
        <div
          key={idx}
          style={{
            display: "flex",
            flexDirection: isTabletOrLarger ? "row" : "column",
            gap: "16px",
            marginBottom: "16px",
            justifyContent: "space-between",
          }}
        >
          {pair.map((category) => (
            <div
              key={category.id}
              style={{
                flex: "1",
                border: "1px solid #ddd",
                borderRadius: 8,
                minWidth: isTabletOrLarger ? "48%" : "100%",
              }}
            >
              <Accordion
                sx={{ height: "100%" }}
                expanded={expanded === category.id}
                disableGutters
              >
                <AccordionSummary
                  onClick={() => handleExpand(category.id)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "8px 16px",
                  }}
                >
                  {expanded === category.id ? <RemoveIcon /> : <AddIcon />}
                  <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                    {category[categoryName]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ wordBreak: "break-word" }}>
                    {category?.[categoryChildListName]?.map((event) => (
                      <ListItem key={event.id} sx={{ pl: 2 }}>
                        <Checkbox
                          checked={selectedEvents.some(
                            (e) => e.id === event.id
                          )}
                          onChange={() => handleEventChange(event)}
                        />
                        <Typography>{event[categoryName]}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default CollapseExpandTree;
