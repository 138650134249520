import React, { useEffect } from "react";
import { CssBaseline, Stack, IconButton } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Footer from "./Footer";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import "./eventComponent/EventTabs/EventTabs.css";
import Services from "../ProviderServices.json";
import CreateEventCTA from "./EventServices/CreateEventCTA";
import { homePagePath } from "../constant";

export const Aboutuspage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const role = useSelector((state) => state.auth.role);

  const backtoHome = () => {
    isLoggedIn
      ? role === "customer"
        ? navigate("/dashboard")
        : navigate("/service-provider/dashboard")
      : navigate(homePagePath);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
      </Stack>
      <div className={`main-container ${!isLoggedIn && "no-auth"}`}>
        <div className="frontend-container">
          <div style={{ width: "100%" }}>
            <div className="event-form-inner">
              <div
                className={`event-form-body ${
                  isLoggedIn && role === "provider" ? "alter full" : "list"
                }`}
              >
                <div className="event-form-body-inner">
                  <div className="event-form-head">
                    <div className="event-service-head-inner">
                      <IconButton
                        onClick={backtoHome}
                        style={{
                          backgroundColor: "#0cc0df",
                          color: "#fff",
                          padding: "8px",
                        }}
                      >
                        <KeyboardBackspaceOutlinedIcon />
                      </IconButton>
                      <div>
                        <h1 className="font-legal font-25px">About us</h1>
                        {/* <div className="event-rsvp">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="event-form-content event-service-content">
                    <div className="event-service-data">
                      <p>
                        Hosting events have never been easier! At Thurent, we’re
                        revolutionizing the way you plan and execute events by
                        combining the convenience of technology with the power
                        of choice. Whether it’s a wedding, housewarming,
                        birthday, or any special occasion, our app empowers you
                        to effortlessly host memorable events with confidence.
                      </p>

                      <p>
                        Our app simplifies event hosting by enabling you to
                        compare and connect with a wide range of service
                        providers, including priests, caterers, decorators,
                        rental services, and photographers/videographers. What
                        sets us apart? We’ve integrated cutting-edge AI into our
                        platform, guiding you through every step of the event
                        planning process. From suggesting what you need to
                        helping you execute your vision, our AI assistant
                        ensures no detail is overlooked.
                      </p>

                      <p>
                        We’re not just a tech solution; we’re a community. Our
                        journey began with a shared passion for helping people
                        celebrate life’s most cherished moments. We have a
                        friend/family round investment raised already, backed by
                        those who believe in our mission. We’re committed to
                        making event hosting stress-free, enjoyable, and
                        personalized.
                      </p>
                    </div>
                  </div>
                </div>
                {(!isLoggedIn || (isLoggedIn && role === "customer")) && (
                  <div className="event-service-list sidebar">
                    <CreateEventCTA />
                    {Services.length > 0 &&
                      Services.map((item) => (
                        <Link
                          key={item.id}
                          className="event-service"
                          to={`/service/${item.slug}`}
                        >
                          <div className="event-service-head">
                            <div>
                              <img
                                style={{
                                  display: "block",
                                  width: "30px",
                                }}
                                src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                alt={item.serviceCardName}
                              />
                              <div>
                                <h3>{item.serviceCardName}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
      <ConfirmDialog />
    </div>
  );
};
export default Aboutuspage;
