import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik"; // Formik import
import * as Yup from "yup"; // Yup for validation
import { signup } from "../../Apiservices/Auth/servers/signUpServer";
import "./SignUp.css";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Message } from "primereact/message";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { setToken } from "../../util/Cookies";
import { handlePendingEvent, setCredentials } from "../../store/authSlice";
import { homePagePath, passwordRegEx } from "../../constant";
import BackToHome from "../BacktoHomeComponent/BackToHome";

const SignUp = () => {
  const phoneRegExp = /^[6-9]\d{9}$/;
  const dispatch = useDispatch();
  const authState = useSelector((state) => state);
  const { loading, user, error } = authState || {};
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showErrorDialogVisible, setShowErrorDialogVisible] = useState(false);
  const [errorDialogCont, setErrorDialogCont] = useState({});

  const showErrorDialog = (msg) => {
    setShowErrorDialogVisible(true);
    switch (msg) {
      case "User already exists.":
        setErrorDialogCont({
          content: "A user with this email or mobile number already exists.",
          btn: true,
        });
        break;
      default:
        setErrorDialogCont({ content: msg, btn: false });
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      mobileNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email address"),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required")
        .test(
          "is-valid-phone-or-email",
          "Invalid mobile number",
          function (value) {
            if (!value) return false; // Empty check is already handled by .required()
            const isValidPhone = phoneRegExp.test(value);
            console.log(isValidPhone);
            return isValidPhone;
          }
        ),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(
          passwordRegEx,
          "Password must include AT LEAST ONE uppercase, lowercase, number and special character"
        )
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const data = await signup(values);
        const { message, token } = data;
        if (data.token) {
          // Signup Successful
          console.log("Signup Successful");
          setToken(data.token);
          dispatch(setCredentials(data));
          dispatch(handlePendingEvent());
          navigate("/dashboard");
        }
      } catch (error) {
        showErrorDialog(error.message || "");
        console.log("Error: In Signup ");
        console.log(error);
      }
    },
  });
  return (
    <div className="auth-screens-bg">
      <Dialog
        className="auth-dialog"
        visible={showErrorDialogVisible}
        modal
        header={`Error`}
        footer={
          errorDialogCont.btn ? (
            <div>
              <Button
                size="small"
                label="Login"
                onClick={() => navigate("/login")}
              />
              <Button
                size="small"
                label="Forgot Password"
                onClick={() => navigate("/forgot-password")}
              />
            </div>
          ) : (
            <Button
              size="small"
              label="OK"
              onClick={() => setShowErrorDialogVisible(false)}
            />
          )
        }
        onHide={() => {
          if (!showErrorDialogVisible) return;
          setShowErrorDialogVisible(false);
        }}
      >
        <h4 style={{ fontWeight: "500" }}>{errorDialogCont.content}</h4>
      </Dialog>
      <div className="login-container">
        <div>
          <div>
            {/* <h2>Login</h2> */}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/thurent.png`}
              alt="Logo"
              className="auth-logo"
              onClick={() => {
                navigate(homePagePath);
              }}
            />
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                name="firstName"
                placeholder="Full Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                className="login-input"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <span className="error">{formik.errors.firstName}</span>
              )}

              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="login-input"
              />
              {formik.touched.email && formik.errors.email && (
                <span className="error">{formik.errors.email}</span>
              )}

              <input
                type="text"
                name="mobileNumber"
                maxLength="10"
                placeholder="Mobile Number"
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                className="login-input"
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                <span className="error">{formik.errors.mobileNumber}</span>
              )}
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="login-input"
                />
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={handleClickShowPassword}
                  edge="end"
                  sx={{ position: "absolute", right: "14px", top: "5px" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>

              {formik.touched.password && formik.errors.password && (
                <span className="error">{formik.errors.password}</span>
              )}

              <button
                type="submit"
                disabled={loading}
                className="signup-button"
              >
                {loading ? "SIGNING UP..." : "SIGNUP"}
              </button>
              {error && <span className="error">Error: {error}</span>}
            </form>

            <button
              className="login-button-secondary"
              onClick={() => navigate("/login")}
            >
              Existing User? LOGIN{" "}
            </button>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "0.95rem",
                fontWeight: "500",
              }}
            >
              Already have an account?{" "}
              <Link to="/login" style={{ color: "#FF895D", margin: "0 4px" }}>
                Login
              </Link>
            </div> */}
            <div
              style={{
                fontSize: "0.95rem",
                fontWeight: "500",
                marginTop: "15px",
                textAlign: "left",
              }}
            >
              By creating an account or logging in, you agree to Thurent’s{" "}
              <Link
                to="/privacy-policy"
                style={{ color: "#FF895D", margin: "0 4px" }}
              >
                Privacy Policy
              </Link>
              .
            </div>
            <div style={{ marginTop: "0.75rem" }}>
              <Message
                className="pwd-info"
                severity="info"
                text="Password must include AT LEAST ONE uppercase, lowercase, number and special character."
              />
            </div>
            <BackToHome />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
