import React, { useCallback, useState, useEffect } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Alert from "@mui/material/Alert";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "../EventTabs/EventTabs.css"; // Icons
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import formatDate from "../../../util/FormateDate";
import EventForm from "../EventForm2";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import {
  CREATION_FORM,
  EDIT_FORM,
  getFormTypeData,
  getIsFirstVisit,
  setFormType,
  // setIsFirstVisit,
} from "../../../services/AuthServices";
import {
  fetchEvent,
  deleteEvent,
} from "../../../Apiservices/Auth/servers/fetchEvents";
import { fetchProviderData } from "../../../Apiservices/Auth/servers/fetchProviderData";
import config from "../../../config";
import {
  createEvent,
  updateEvent,
} from "../../../Apiservices/Auth/servers/createEvents";
import CreateUpdateEventDialog from "../../CreateUpdateEventDialog/CreateUpdateEventDialog";

import providerData from "../../../ProviderServices.json";

const EventTabs = () => {
  const [loading, setLoading] = useState(true);
  //const [providerData, setProviderData] = useState([]);
  const [events, setEvents] = useState({ upcoming: [], past: [], cancel: [] });
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const navigate = useNavigate();

  const providerListStorageKey = config.providerListStorageKey;
  const providerList = localStorage.getItem(providerListStorageKey);

  if (providerList) {
    const info = JSON.parse(providerList);
    if (info.serviceDetails.id !== 2) {
      navigate("/providers", { state: info });
      window.scrollTo({ top: 0 });
    }
  }

  const handleCloseDialog = () => {
    setDialogVisible(false);
    setSelectedEvent(null);
  };

  const handleSubmitEvent = async ({ eventDetails }) => {
    handleCloseDialog();
    setLoading(true);
    try {
      if (isUpdate) {
        console.log("Updating event:", eventDetails);
        await updateEvent(eventDetails, eventDetails.id);
      } else {
        console.log("Creating new event:", eventDetails);

        await createEvent(eventDetails);
      }
    } catch (error) {
      console.log("Something Went Wrong while Creating/updating Event ");
      console.log(error);
    } finally {
      await fetchEvents();
      setLoading(false);
      // setIsFirstVisit(false);
    }

    // Close the dialog after submitting
    // handleCloseDialog();
  };

  const openCreateEventDialog = () => {
    // navigate("/dashboard/event/create");
    setIsUpdate(false);
    setDialogVisible(true);
    setSelectedEvent(null);
  };

  const openUpdateEventDialog = (event) => {
    setIsUpdate(true); // Set to update mode
    setSelectedEvent(event); // Set event data
    console.log(event);
    setDialogVisible(true);
  };

  /* const fetchProviders = useCallback(async () => {
    // setLoading(true);
    try {
      const provider_data = await fetchProviderData();
      setProviderData(provider_data);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      // setLoading(false);
    }
  }, []); */

  const EventTemplate = React.memo((event) => {
    const [isInvitedByExists, setIsInvitedByExists] = useState(
      event?.invitedBy?.trim()
    );
    const [hostName, setHostName] = useState("");
    const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
    const [invitedByInputDialogVisible, setInvitedByInputDialogVisible] =
      useState(false);
    const shareToken = event.shareToken; // Assuming shareToken comes from the event
    const handleClick = () => {
      if (shareToken && shareToken.length > 0)
        navigate("/dashboard/event/guests", { state: { shareToken } });
    };

    const handleEventService = (service) => {
      if (event && Object.keys(event).length > 0) {
        const info = {
          serviceDetails: service,
          eventDetails: event,
        };
        navigate("/providers", { state: info });
        window.scrollTo({ top: 0 });
      }
    };

    const editEventHandle = () => {
      if (event && Object.keys(event).length > 0) {
        openUpdateEventDialog(event);
      }
    };

    // Function to handle Host Name submit
    const handleHostNameSubmit = async (hostName, eventId) => {
      try {
        // Call your API here to save the hostName
        const eventData = {
          invitedBy: hostName,
        };
        const success = await updateEvent(eventData, eventId);

        if (success) {
          // Perform operations if the event was successfully updated
          console.log("Event updated successfully.");
          setIsInvitedByExists(true);
          setInviteDialogVisible(true);
          setInvitedByInputDialogVisible(false);
        } else {
          // Handle the failure case
          console.error("Failed to update event.");
        }
      } catch (error) {
        // Handle any errors (e.g., network issues)
        console.error("An error occurred while saving the host name", error);
        setInvitedByInputDialogVisible(false);
      }
    };

    const shareUrl = `${config.INVITATION_URL}/${shareToken}`;
    const shareTitle = `Check out this event: ${event.eventTitle}`;
    const whatsappMessage = encodeURIComponent(
      `Check out this event: ${shareUrl}`
    );

    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Try using the Clipboard API
        navigator.clipboard
          .writeText(shareUrl)
          .then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
          })
          .catch((err) => {
            console.error("Clipboard API failed, trying fallback...", err);
            fallbackCopyTextToClipboard(shareUrl); // Use fallback if API fails
          });
      } else {
        // If Clipboard API is not supported, fallback
        fallbackCopyTextToClipboard(shareUrl);
      }
    };

    // Fallback function to copy text using execCommand('copy')
    const fallbackCopyTextToClipboard = (text) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
        } else {
          console.error("Fallback copy failed.");
        }
      } catch (err) {
        console.error("Fallback: Unable to copy", err);
      }

      document.body.removeChild(textArea); // Clean up the DOM
    };
    const toggleShareOptions = () => {
      if (isInvitedByExists) {
        setInviteDialogVisible(true); // Show dialog on share button click}
      } else {
        setInvitedByInputDialogVisible(true);
      }
    };

    const hideDialog = () => {
      setInviteDialogVisible(false); // Hide dialog
    };

    const handleShare = (platform) => {
      const fullUrl = `${shareUrl}?token=${shareToken}`;
      let url = "";

      switch (platform) {
        case "whatsapp":
          url = `https://api.whatsapp.com/send?text=${whatsappMessage}`;
          break;
        case "facebook":
          url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            fullUrl
          )}`;
          break;
        case "twitter":
          url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            fullUrl
          )}&text=${encodeURIComponent(shareTitle)}`;
          break;
        case "gmail":
          const subject = encodeURIComponent(
            "Invitation to " + event.eventTitle
          );
          const body = encodeURIComponent(
            `Hi,\n\nI would like to invite you to this event:\n\n${shareUrl}\n\nBest Regards.`
          );
          url = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subject}&body=${body}`;
          break;
        default:
          console.log("Invalid platform");
          return;
      }

      window.open(url, "_blank");
    };

    const deleteEventHandle = async (eventId) => {
      //alert(eventId)
      setLoading(true);
      try {
        const response = await deleteEvent(eventId);
      } catch (error) {
        console.error("Failed to services:", error);
      } finally {
        fetchEvents();
        setLoading(false);
        window.scrollTo({ top: 0 });
      }
    };

    const deleteEventConfirm = (eventId) => {
      confirmDialog({
        message: "Do you want to cancel this event?",
        header: "Cancel Confirmation",
        defaultFocus: "reject",
        acceptClassName: "p-button-danger",
        accept: () => deleteEventHandle(eventId),
        reject,
      });
    };

    const reject = () => {};

    return (
      <Card className="elevated-card">
        {event.cancel === true && (
          <div className="event-cancel">
            <span>Cancelled</span>
          </div>
        )}
        {/* Event Date */}
        <div className="elevated-card-inner">
          <div className="event-header">
            <div>
              <h2 className="event-title">{event.eventTitle}</h2>
              <div className="event-description">
                {event.desc || "No description available."}
              </div>
            </div>
            {event.cancel !== true && event.past !== true && (
              <div className="event-edit">
                <Button
                  link
                  icon="pi pi-pen-to-square"
                  onClick={editEventHandle}
                  label="EDIT"
                  size="small"
                  style={{ padding: 0, color: "#fff" }}
                />
                <Button
                  link
                  icon="pi pi-times"
                  onClick={() => deleteEventConfirm(event.id)}
                  label="CANCEL"
                  size="small"
                  style={{ padding: 0, color: "#fff" }}
                />
              </div>
            )}
          </div>
          <div className="event-body">
            <div className="event-metainfo">
              <div className="event-meta">
                <div className="event-middle">
                  {(event.endDate === null ||
                    event.locationLat === null ||
                    event.locationLong === null ||
                    event.area === null ||
                    event.city === null ||
                    event.state === null ||
                    event.Location === null) &&
                    event.cancel !== true &&
                    event.past !== true && (
                      <Alert
                        severity="warning"
                        sx={{
                          width: "100%",
                          backgroundColor: "#fedbce",
                          color: "#353755",
                          fontWeight: "600",
                        }}
                      >
                        Your Event Creation Status - Incomplete
                        <br />
                        <small>
                          Please update <u>Event End date & time</u> and{" "}
                          <u>Location/Venue</u>.{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={editEventHandle}
                          >
                            Click Here
                          </strong>
                        </small>
                      </Alert>
                    )}
                  <div className="event-date">
                    <CalendarMonthOutlinedIcon style={{ fontSize: "24px" }} />
                    <div>
                      {formatDate(event.Date)} &mdash;{" "}
                      {event.endDate !== null
                        ? formatDate(event.endDate)
                        : "NA"}
                    </div>
                  </div>
                  {event.Location !== null && (
                    <div className="event-location">
                      <LocationOnOutlinedIcon
                        style={{ fontSize: "24px", marginTop: "5px" }}
                      />
                      <div>{event.Location}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="event-invite">
                <div className="event-service-head">
                  <div>
                    <img
                      style={{
                        display: "block",
                        width: "70px",
                      }}
                      src={`${process.env.PUBLIC_URL}/assets/images/2.png`}
                      alt="Invite"
                    />
                    <div>
                      <h3>Invite</h3>
                      {event.rsvpCounts && event.rsvpCounts.totalCount > 0 ? (
                        <div className="event-rsvp">
                          <Tag
                            style={{ backgroundColor: "#0cc0df" }}
                            value={`Total: ${event.rsvpCounts.totalCount || 0}`}
                          ></Tag>
                          <Tag
                            style={{ backgroundColor: "#FF895D" }}
                            value={`Attending: ${
                              event.rsvpCounts.comingCount || 0
                            }`}
                          ></Tag>
                          <Tag
                            style={{ backgroundColor: "#737373" }}
                            value={`Not Attending: ${
                              event.rsvpCounts.notComingCount || 0
                            }`}
                          ></Tag>
                          <span className="vm" onClick={handleClick}>
                            View Details
                          </span>
                        </div>
                      ) : (
                        <div className="event-rsvp">No Invitation sent</div>
                      )}
                      {event.cancel !== true && event.past !== true && (
                        <div className="share-invite-container">
                          <span className="vm" onClick={toggleShareOptions}>
                            Send Invitation
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {event.cancel !== true &&
              event.past !== true &&
              providerData.length > 0 && (
                <div className="event-services">
                  {providerData
                    .filter((item) => item.id !== 2)
                    .filter((item) =>
                      event.eventMasterDetails?.is_priest_required === false
                        ? item.id !== 7
                        : true
                    )
                    .map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="event-service"
                          onClick={() => handleEventService(item)}
                        >
                          <div className="event-service-head">
                            <div>
                              <img
                                style={{
                                  display: "block",
                                  width: "70px",
                                }}
                                src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                alt={item.serviceName}
                              />
                              <div>
                                <h3>
                                  {item.serviceName}{" "}
                                  {item.id === 1 && event.eventTitle}
                                </h3>
                                <div className="event-rsvp">
                                  {item.serviceDescription}
                                </div>
                              </div>
                            </div>
                            <div className="pi pi-chevron-right"></div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
        </div>
        {/* Dialog for Share Options */}
        <Dialog
          header={
            <div>
              Invite
              {/* Info Icon with Tooltip */}
            </div>
          }
          visible={inviteDialogVisible}
          style={{ width: "calc(100% - 15px)", maxWidth: "500px" }}
          modal
          onHide={hideDialog}
          headerStyle={{ margin: 0, padding: "15px 25px" }}
        >
          <div className="p-fluid">
            <div className="p-inputgroup mb-3">
              <div style={{ position: "relative", width: "100%" }}>
                <InputText
                  value={shareUrl}
                  readOnly
                  style={{ flexGrow: 1, width: "100%", paddingRight: "58px" }}
                />
                <Button
                  id="info-icon"
                  label={copySuccess ? "Copied" : "Copy"}
                  icon="pi pi-copy"
                  onClick={handleCopy}
                  style={{
                    position: "absolute",
                    right: "0",
                    borderRadius: "0 6px 6px 0",
                    backgroundColor: copySuccess ? "#FF895D" : "#0cc0df",
                  }} // Change color on success
                />
                {/* <i
                className="pi pi-info-circle"
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                id="info-icon" // Add an ID to reference the icon for the tooltip
              /> */}
                <Tooltip
                  position="mouse"
                  at="right+5 bottom"
                  target="#info-icon"
                  content="You can use this link to send invitation using any other method."
                />
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <label style={{ display: "block", marginBottom: "10px" }}>
                Share on Social Media
              </label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  icon="pi pi-whatsapp"
                  className="p-button-rounded"
                  onClick={() => handleShare("whatsapp")}
                  style={{ backgroundColor: "#25D366", color: "white" }} // WhatsApp color
                  aria-label="WhatsApp"
                />
                <Button
                  icon="pi pi-facebook"
                  className="p-button-rounded"
                  style={{ backgroundColor: "#3B5998", color: "white" }} // Facebook color
                  aria-label="Facebook"
                  onClick={() => handleShare("facebook")}
                />
                <Button
                  icon="pi pi-twitter"
                  className="p-button-rounded"
                  style={{ backgroundColor: "#1DA1F2", color: "white" }} // Twitter color
                  aria-label="Twitter"
                  onClick={() => handleShare("twitter")}
                />
                <Button
                  className="p-button-rounded"
                  icon="pi pi-envelope" // Use an appropriate icon for Gmail
                  style={{ backgroundColor: "#1DA1F2", color: "white" }} // Twitter color
                  onClick={() => handleShare("gmail")}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          header="Enter Host Name"
          visible={invitedByInputDialogVisible}
          style={{ width: "calc(100% - 15px)", maxWidth: "400px" }}
          modal
          onHide={() => setInvitedByInputDialogVisible(false)}
          footer={
            <div>
              <Button
                label="Submit"
                icon="pi pi-check"
                onClick={() => handleHostNameSubmit(hostName, event.id)}
                disabled={!hostName.trim()}
                className="custom-button"
                size="small"
              />
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => {
                  setInvitedByInputDialogVisible(false);
                }}
                className="custom-button cancel"
                size="small"
              />
            </div>
          }
        >
          <div>
            <label style={{ display: "block", marginBottom: "10px" }}>
              Host Name<span style={{ color: "red" }}>*</span>
            </label>
            <InputText
              value={hostName}
              onChange={(e) => setHostName(e.target.value)}
              placeholder="Enter host name"
              style={{ width: "100%" }}
            />
          </div>
        </Dialog>
      </Card>
    );
  });
  const CreateEventCTALocal = () => {
    return (
      <div className="create-event-cta2-container">
        <div className="create-event-cta2-header">
          <h3>What Event are you Planning</h3>
        </div>
        <div className="create-event-cta2-action-btn">
          <p>Create event to see how easy it is.</p>
          <Button
            className="custom-button"
            icon="pi pi-plus"
            label="Create Event"
            size="small"
            onClick={openCreateEventDialog}
          />
        </div>
      </div>
    );
  };

  const EventCreateButton = () => (
    <div className="events-container dashboard">
      <div
        className="events-header-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <CreateEventCTALocal></CreateEventCTALocal>
        {/* <Button
          className="custom-button"
          icon="pi pi-plus"
          label="Create  Event"
          size="small"
          onClick={openCreateEventDialog}
        /> */}
        <CreateUpdateEventDialog
          isVisible={dialogVisible}
          onClose={handleCloseDialog}
          eventDetails={selectedEvent}
          isUpdate={isUpdate}
          onSubmit={handleSubmitEvent}
        />
      </div>
    </div>
  );

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const { upcomingEvents, pastEvents, cancelledEvents } =
        await fetchEvent();
      setEvents({
        upcoming: formatEvents(upcomingEvents),
        past: formatEvents(pastEvents),
        cancel: formatEvents(cancelledEvents),
      });
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFormType = () => {
    const { formType, formId } = getFormTypeData();
    setFormType(null);
    if (formType === CREATION_FORM) {
      // navigate("/dashboard/event/create");
      openCreateEventDialog();
    } else if (formType === EDIT_FORM) {
      const existedEvent = events.upcoming.find((event) => event.id === formId);

      if (existedEvent && Object.keys(existedEvent).length > 0) {
        // navigate("/dashboard/event/edit", { state: existedEvent });
        openUpdateEventDialog(existedEvent);
      }
    } else {
      console.log("No valid form type set");
    }
  };

  events.past.length > 0 &&
    events.past.map((event) => {
      event["past"] = true;
    });

  events.cancel.length > 0 &&
    events.cancel.map((event) => {
      event["cancel"] = true;
    });

  useEffect(() => {
    //fetchProviders();
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (!loading) {
      handleFormType();
    }
  }, [loading, events]);

  const formatEvents = (events) => {
    return events.map(
      ({
        id,
        status,
        eventTitle,
        eventType,
        eventId,
        eventMasterDetails,
        startDate,
        description,
        venue,
        shareToken,
        rsvpCounts,
        startTime,
        endDate,
        endTime,
        locationLat,
        locationLong,
        area,
        city,
        state,
        invitedBy,
      }) => ({
        id,
        status,
        eventTitle: eventTitle || eventType,
        eventMasterDetails: eventMasterDetails,
        eventId: eventId,
        Date: startDate,
        desc: description,
        Location: venue,
        shareToken,
        rsvpCounts,
        startTime,
        endDate,
        endTime,
        locationLat,
        locationLong,
        area,
        city,
        state,
        invitedBy,
      })
    );
  };

  const upcomingEventCount = () => {
    return (
      <span>
        Your Upcoming Events{" "}
        <span className="badge">
          {events.upcoming.length > 0 ? events.upcoming.length : "0"}
        </span>
      </span>
    );
  };

  const pastEventCount = () => {
    return (
      <span>
        Your Past Events{" "}
        <span className="badge">
          {events.past.length > 0 ? events.past.length : "0"}
        </span>
      </span>
    );
  };

  const cancelEventCount = () => {
    return (
      <span>
        Cancelled Events{" "}
        <span className="badge">
          {events.cancel.length > 0 ? events.cancel.length : "0"}
        </span>
      </span>
    );
  };

  return (
    <div className="tab-container">
      <div className="frontend-container">
        <div style={{ position: "relative", width: "100%" }}>
          <EventCreateButton />
          <ConfirmDialog />
          <TabView style={{ width: "100%", marginTop: "75px" }}>
            <TabPanel header={upcomingEventCount()}>
              <div className="events-container" style={{ width: "100%" }}>
                {loading ? (
                  <h3 style={{ textAlign: "center" }}>
                    <i
                      className="pi pi-spin pi-spinner-dotted"
                      style={{ fontSize: "2rem", color: "#0cc0df" }}
                    ></i>
                  </h3>
                ) : events.upcoming.length > 0 ? (
                  <div className="events-list">
                    {events.upcoming
                      .sort((a, b) => (a.id > b.id ? -1 : 1))
                      .map((event, index) => (
                        <EventTemplate key={index} {...event} />
                      ))}
                  </div>
                ) : (
                  <h3 style={{ textAlign: "center" }}>
                    No Upcoming Events found.
                  </h3>
                )}
              </div>
            </TabPanel>

            {/* Second Tab: Past Events */}
            <TabPanel header={pastEventCount()}>
              <div className="events-container" style={{ width: "100%" }}>
                {loading ? (
                  <h3 style={{ textAlign: "center" }}>
                    <i
                      className="pi pi-spin pi-spinner-dotted"
                      style={{ fontSize: "2rem", color: "#0cc0df" }}
                    ></i>
                  </h3>
                ) : events.past.length > 0 ? (
                  <div className="events-list">
                    {events.past
                      .sort((a, b) => (a.id > b.id ? -1 : 1))
                      .map((event, index) => (
                        <EventTemplate key={index} {...event} />
                      ))}
                  </div>
                ) : (
                  <h3 style={{ textAlign: "center" }}>No Past Events found.</h3>
                )}
              </div>
            </TabPanel>

            {/* Third Tab: Cancelled Events */}
            <TabPanel header={cancelEventCount()}>
              <div className="events-container" style={{ width: "100%" }}>
                {loading ? (
                  <h3 style={{ textAlign: "center" }}>
                    <i
                      className="pi pi-spin pi-spinner-dotted"
                      style={{ fontSize: "2rem", color: "#0cc0df" }}
                    ></i>
                  </h3>
                ) : events.cancel.length > 0 ? (
                  <div className="events-list">
                    {events.cancel
                      .sort((a, b) => (a.id > b.id ? -1 : 1))
                      .map((event, index) => (
                        <EventTemplate key={index} {...event} />
                      ))}
                  </div>
                ) : (
                  <h3 style={{ textAlign: "center" }}>
                    No Cancelled Events found.
                  </h3>
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default EventTabs;
