import React from "react";
import Services from "../../ProviderServices.json";
import CreateEvent from "../eventComponent/EventTabs/CreateEvent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import config from "../../config";
import { Button } from "primereact/button";

function DefaultService({ id, buttonLabel }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const providerListStorageKey = config.providerListStorageKey;
  const service = Services.find((obj) => obj.id === id);

  const navtoProviders = (service) => {
    var info = {
      serviceDetails: service,
      eventDetails: {},
    };
    if (isLoggedIn) {
      service.id === 2
        ? navigate("/dashboard", { state: info })
        : navigate("/providers", { state: info });
      window.scrollTo({ top: 0 });
    } else {
      alertdialog(info);
    }
  };

  const alertdialog = (info) => {
    confirmDialog({
      message: `You need login to check How easy it is.`,
      header: "Please Login or Sign-Up",
      defaultFocus: "accept",
      accept: () => {
        localStorage.setItem(providerListStorageKey, JSON.stringify(info));
        navigate("/login");
      },
    });
  };

  const WhyThurentComponent = ({ WhyThurentdata }) => {
    // Check if WhyThurentdata is valid
    if (
      !WhyThurentdata ||
      typeof WhyThurentdata !== "object" ||
      !WhyThurentdata.whyArray ||
      !Array.isArray(WhyThurentdata.whyArray)
    ) {
      return <div>No data available</div>;
    }

    return (
      <div>
        <h2 className="font-legal">{WhyThurentdata.title || "Why Thurent?"}</h2>
        <div className="middle-content-services">
          {WhyThurentdata.whyArray.map((item, index) => {
            // Handle cases where title or description is missing or blank
            const title = item?.title?.trim() || "";
            const description = item?.description?.trim() || "";

            return (
              <div className="event-service alter" key={index}>
                <div className="event-service-head">
                  <div>
                    <div>
                      <h3 className="font-legal">{title}:</h3>
                      <div className="event-rsvp">{description}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        Morbi hendrerit felis leo, eget ornare mi consectetur a. Proin dignissim
        justo non neque semper, at viverra augue rutrum:
      </p>

      <p style={{ textAlign: "center" }}>
        <Button
          className="custom-button cancel"
          icon="pi pi-arrow-right"
          iconPos="right"
          label={buttonLabel}
          onClick={() => navtoProviders(service)}
        />
      </p>

      {isLoggedIn ? (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Create an event and find yourself how easy it is.</h3>
              <CreateEvent />
            </div>
          </div>
        </div>
      ) : (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Start Planning Smarter—Join Thurent Now!</h3>
              <Button
                className="custom-button"
                icon="pi pi-sign-in"
                iconPos="right"
                label="Sign up"
                onClick={() => navigate("/signup")}
                size="small"
              />
            </div>
          </div>
        </div>
      )}

      <WhyThurentComponent WhyThurentdata={service.WhyThurent} />

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus turpis
        justo, porta non pulvinar id, dictum at metus. Donec congue vulputate
        tortor. Curabitur varius rutrum lacus et porta. Mauris laoreet eget arcu
        mollis tempor. Nunc id interdum augue. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos himenaeos. Donec
        iaculis, leo in dapibus molestie, quam nulla sodales lectus, at rutrum
        nunc turpis ac dolor. Mauris sed elit interdum, tristique enim ut,
        varius augue. Donec varius varius nulla, ut auctor ligula. Vestibulum
        molestie luctus interdum. Nullam fringilla volutpat hendrerit. Aliquam
        erat volutpat. In aliquet consectetur sapien nec molestie. Aliquam
        varius leo volutpat arcu vestibulum ornare eget sit amet erat. Maecenas
        rutrum mattis convallis. Quisque ante nisl, hendrerit id dolor a,
        eleifend cursus turpis.
      </p>

      <p>
        Praesent dignissim sapien vitae mollis hendrerit. Vestibulum sodales,
        tellus sit amet convallis finibus, mauris tellus posuere est, a tempus
        ex est a libero. Praesent pretium feugiat nunc, et rhoncus sapien luctus
        ut. Integer a suscipit nisl, eget laoreet justo. Praesent eu tristique
        ex. Ut rutrum odio in velit ultrices, a accumsan dui facilisis.
        Curabitur semper consectetur eleifend. Morbi hendrerit felis leo, eget
        ornare mi consectetur a. Proin dignissim justo non neque semper, at
        viverra augue rutrum.
      </p>

      {/* <div className="event-service-list">
                <div className="event-service alter">
                    <div className="event-service-head">
                        <h3>Create an event and find yourself how easy it is.</h3>
                        <CreateEvent />
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default DefaultService;
