import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { CssBaseline, Box, Stack, Paper } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import ManageServicesTabContent from "./ManageServicesTabContent";
import ManageProfileTabContent from "./ManageProfileTabContent";
import "./ServiceProviderDashboard.css";

const ServiceProviderDashboard = () => {
  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"spdashboard"} />
      </Stack>
      <div className="main-container">
        <div className="frontend-container">
          <div style={{ width: "100%" }}>
            <Stack
              direction="row"
              gap={2}
              sx={{
                flexDirection: "column",
                alignItems: { xs: "flex-start", md: "stretch" },
                textAlign: { xs: "center", md: "left" },
                marginBottom: "20px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <TabView style={{ width: "100%" }}>
                  <TabPanel header={`Manage Services`}>
                    <ManageServicesTabContent />
                  </TabPanel>

                  <TabPanel header="Manage Orders">
                    <Paper sx={{ p: 2 }}>Manage Orders</Paper>
                  </TabPanel>

                  <TabPanel header="My Account">
                    <ManageProfileTabContent />
                  </TabPanel>
                </TabView>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServiceProviderDashboard;
