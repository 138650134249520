import React from "react";
import { Button } from "primereact/button";

const MyButton1 = ({ title = "Button", method = () => {}, icon = null }) => {
  return (
    <Button
      label={title}
      icon={icon}
      onClick={method}
      className="p-button-outlined"
      style={{
        color: "#ffffff", // White text color
        fontWeight: "bold", // Bold font
        backgroundColor: "#FF895D", // Orange background
        borderColor: "#FF895D", // Orange border
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle elevation
        padding: "10px 15px", // Add padding for a better feel
        borderRadius: "4px", // Smooth rounded corners
        transition: "transform 0.2s, box-shadow 0.2s", // Smooth hover effects
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-2px)"; // Lift on hover
        e.currentTarget.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)"; // Reset on mouse leave
        e.currentTarget.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
      }}
    />
  );
};

export default MyButton1;
