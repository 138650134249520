const config = {
  companyName: "Thurent.com",
  eventLocalStorageKey: "createEventFormSession",
  eventEditLocalStorageKey: "editEventFormSession",
  providerListStorageKey: "providerListSession",
  cartItemKey: "cartItemSession",
  
  API_BASE_URL:
    "https://backend-dev.thurent.com/api",
  // API_BASE_URL: 'http://localhost:3001/api',
  INVITATION_URL: "https://thurent.com/invitation",
  // Add other configurations if needed
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTcyNTU2NTc2MCwiZXhwIjoxNzI1NTY5MzYwfQ.yHdfylPCyDVYm_8aSEpoC_Yc5YOF-z4__diuPB_C1mQ",

  emailAddress: "nick@thurent.com",
  phoneNumber: "+91 74167-34499",
  companyFullName: "THURENT TECH SERVICES PRIVATE LIMITED",
  contactAddress: "1-8-19/2, New Dilsukhnagar \n Hyderabad- 500060 \n TELANGANA INDIA"
};

export default config;
