import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { Autocomplete, Box, TextField } from "@mui/material";
import { fetchCategoryEvents } from "../../Apiservices/Auth/servers/fetchCategoryEvents";
import useWindowSize from "../../util/useWindowSize";

const CreateUpdateEventDialog = ({
  isVisible,
  onClose,
  eventDetails,
  isUpdate,
  onSubmit,
}) => {
  const [event, setEvent] = useState(
    eventDetails || { eventTitle: "", eventDesc: "", address: "" }
  );
  const [eventType, setEventType] = useState(null);
  const [whoseEvent, setWhoseEvent] = useState("");
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [eventData, setEventData] = useState([]);
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const { width, height } = useWindowSize();

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ["places", "maps"],
  });

  const mapRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = ""; // Add token if required
        const [events_data] = await Promise.all([fetchCategoryEvents(token)]);
        setEventData(events_data);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    if (!isUpdate) {
      fetchData();
    } else {
      setWhoseEvent(eventDetails?.desc);
      setAddress(eventDetails?.location ?? "");
      setLocation({
        lat: parseFloat(eventDetails?.locationLat) || 0,
        lng: parseFloat(eventDetails?.locationLong) || 0,
      });
      setArea(eventDetails?.area || "");
      setCity(eventDetails?.city || "");
      setState(eventDetails?.state || "");
      setStartDate(eventDetails?.Date ? new Date(eventDetails.Date) : null);
      setEndDate(eventDetails?.endDate ? new Date(eventDetails.endDate) : null);
    }
  }, []);

  useEffect(() => {
    // Only fetch the current location if the lat and lng are not available from eventDetails
    if (!location.lat || !location.lng) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
            fetchAddress(latitude, longitude);
          },
          (error) => console.error("Error fetching location:", error)
        );
      }
    } else {
      // Call fetchAddress immediately to get the address based on the provided lat/lng
      fetchAddress(location.lat, location.lng);
    }
  }, [location.lat, location.lng, isLoaded]); // Only trigger when lat/lng changes

  const fetchAddress = (lat, lng) => {
    if (isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const result = results[0];
          const addressComponents = result.address_components;
          const getComponent = (type) =>
            addressComponents.find((comp) => comp.types.includes(type))
              ?.long_name || "";

          setAddress(result.formatted_address);
          setArea(getComponent("sublocality") || getComponent("locality"));
          setCity(getComponent("locality"));
          setState(getComponent("administrative_area_level_1"));
          setCountry(getComponent("country"));
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    }
  };

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLocation({ lat, lng });
    fetchAddress(lat, lng);
  };

  const handleMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setLocation({ lat, lng });
    fetchAddress(lat, lng);
  };

  const useCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude });
          fetchAddress(latitude, longitude);
        },
        (error) => {
          console.error("Error fetching location:", error);
          alert("Unable to fetch location. Please enable location services.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const startCalendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  const closeStartCalendar = () => {
    if (startCalendarRef.current) {
      startCalendarRef.current.hide();
    }
  };

  const closeEndCalendar = () => {
    if (endCalendarRef.current) {
      endCalendarRef.current.hide();
    }
  };

  const validateCreateForm = () => {
    const newErrors = {};
    if (!eventType?.event) {
      newErrors.eventType = "Event type is required.";
    }
    if (!address) {
      newErrors.address = "Event location is required.";
    }
    if (!whoseEvent) {
      newErrors.whoseEvent = "Please specify whose event it is.";
    }
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validateUpdateForm = () => {
    const newErrors = {};
    if (!address) {
      newErrors.address = "Event location is required.";
    }
    if (!whoseEvent) {
      newErrors.whoseEvent = "Please specify whose event it is.";
    }
    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    let tempEventDetails;
    if (!isUpdate) {
      if (!validateCreateForm()) {
        return;
      }
      tempEventDetails = {
        eventTitle: event?.eventTitle || "",
        event: {
          id: eventType?.id || null,
          event: eventType?.event || "",
        },
        eventDesc: whoseEvent || "",

        eventId: eventType?.id || null,
        eventType: eventType?.event || "",
        // description: whoseEvent || "",
        Address: address || "",
        area: area || "",
        city: city || "",
        state: state || "",
        locationLat: location.lat || null,
        locationLong: location.lng || null,
        Date: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      };
    } else {
      if (!validateUpdateForm) return;
      tempEventDetails = {
        id: eventDetails.id,
        eventDesc: whoseEvent,
        Address: address,
        startDate: startDate,
        endDate: endDate ? endDate.toISOString() : null,
        locationLat: location.lat || null,
        locationLong: location.lng || null,
        area,
        city,
        state,
      };
    }

    if (onSubmit) {
      onSubmit({ eventDetails: tempEventDetails });
    }
  };

  const handleEventInputChange = (event, value) => {
    const inputValue = value?.trim() || "";
    if (inputValue.length >= 3) {
      const newFilteredOptions = eventData
        .filter((e) => {
          return (
            (e.name?.toLowerCase() || "").includes(inputValue.toLowerCase()) ||
            (e.description?.toLowerCase() || "").includes(
              inputValue.toLowerCase()
            )
          );
        })
        .map((e) => e.name);
      setFilteredOptions(newFilteredOptions);
    } else {
      setFilteredOptions([]);
    }
    handleEventChange("_", inputValue);
  };

  const handleEventChange = (field, value) => {
    const selectedEvent = eventData.find((e) => e.name === value) || {
      id: "",
      name: value,
    };
    setEventType({ event: selectedEvent.name, id: selectedEvent.id });
  };

  const renderAutocomplete = (label, options, field) => {
    const isEventTypeError =
      !eventType?.event || eventType.event.trim().length < 3;
    return (
      <Autocomplete
        options={filteredOptions || []}
        getOptionLabel={(option) => option || ""}
        freeSolo
        value={eventType?.event || ""}
        onInputChange={handleEventInputChange}
        onChange={(e, value) => handleEventChange(field, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            size="small"
            error={isEventTypeError}
            // helperText={
            // isEventTypeError ? "Enter 3 or more letters." : ""
            // }
            sx={{ marginBottom: "8px", width: "100%" }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option + Math.random()}
            style={{ listStyle: "none" }}
          >
            {option}
          </li>
        )}
      />
    );
  };

  return (
    <Dialog
      header={
        isUpdate
          ? `Update "${eventDetails?.eventTitle ?? ""}" Event`
          : "What Event are you Planning"
      }
      visible={isVisible}
      onHide={onClose}
      closable={true}
      closeOnEscape={false}
      breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      className="custom-dialog"
    >
      <div className="event-form-inner">
        <div className="event-form-body list popup">
          <Box
            sx={{ position: "relative", marginBottom: { xs: "15px", md: 0 } }}
          >
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={{
                  height: width > 768 ? "100%" : "40vh",
                  width: "100%",
                }}
                center={location}
                zoom={15}
                onClick={handleMapClick}
                onLoad={(map) => (mapRef.current = map)}
              >
                <MarkerF
                  position={location}
                  draggable
                  onDragEnd={handleMarkerDragEnd}
                />
              </GoogleMap>
            ) : (
              <div>Loading Map...</div>
            )}
            <Button
              size="small"
              label="Use Current Location"
              onClick={useCurrentLocation}
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                width: "220px",
              }}
            />
          </Box>
          <div>
            <Box>
              {!isUpdate &&
                renderAutocomplete(
                  "Enter 3 or more letters",
                  eventData?.map((e) => e.name) || [],
                  "event"
                )}
              <TextField
                name="whoseEvent"
                label="Whose Event is it?"
                value={whoseEvent}
                onChange={(e) => setWhoseEvent(e.target.value)}
                size="small"
                variant="outlined"
                error={Boolean(errors.whoseEvent)}
                helperText={errors.whoseEvent || ""}
                sx={{ marginBottom: "8px", width: "100%" }}
              />
              <TextField
                label="Event Location/Venue"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                size="small"
                error={Boolean(errors.address)}
                helperText={errors.address || ""}
                sx={{ marginBottom: "8px", width: "100%" }}
              />
              <Calendar
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.value);
                  if (endDate && e.value) {
                    if (e.value > endDate) {
                      setEndDate(e.value);
                    } else {
                      //No changes
                    }
                    // setEndDate(null);
                  } else {
                    setEndDate(e.value);
                  }
                }}
                placeholder="Event Start Date"
                style={{ width: "100%", marginBottom: "10px" }}
                showTime
                minDate={new Date()}
                // maxDate={endDate || null}
                showIcon
                error={Boolean(errors.startDate)}
                helperText={errors.startDate}
                ref={startCalendarRef}
                footerTemplate={(e) => (
                  <div className="p-datepicker-buttonbar">
                    <button
                      aria-label="OK"
                      className="p-button-secondary p-button-text p-button p-component"
                      type="button"
                      onClick={closeStartCalendar}
                    >
                      <span className="p-button-label p-c">OK</span>
                    </button>
                  </div>
                )}
              />
              <Calendar
                value={endDate}
                onChange={(e) => setEndDate(e.value)}
                placeholder="Event End Date"
                style={{ width: "100%", marginBottom: "10px" }}
                showTime
                disabled={!startDate}
                minDate={startDate || new Date()}
                showIcon
                error={Boolean(errors.endDate)}
                helperText={errors.endDate}
                ref={endCalendarRef}
                footerTemplate={(e) => (
                  <div className="p-datepicker-buttonbar">
                    <button
                      aria-label="OK"
                      className="p-button-secondary p-button-text p-button p-component"
                      type="button"
                      onClick={closeEndCalendar}
                    >
                      <span className="p-button-label p-c">OK</span>
                    </button>
                  </div>
                )}
              />
              {errors.dateRange && (
                <div style={{ color: "red" }}>{errors.dateRange}</div>
              )}
              <div className="btns group full">
                <Button
                  size="small"
                  type="submit"
                  className="custom-button"
                  label="Submit"
                  onClick={handleSubmit}
                />
                <Button
                  size="small"
                  className="custom-button cancel"
                  label="Cancel"
                  onClick={onClose}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateUpdateEventDialog;
