import React, { useState } from "react";
import { Link } from "react-router-dom";
import eventsvideo from "../../assets/videos/events3.mp4";
import "./CreateEventSection.css";
import EventForm from "../eventComponent/EventForm2";
import Services from "../../ProviderServices.json";

const CreateEventSection = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted className="video-background">
        <source src={eventsvideo} type="video/mp4" />
        Sorry Your browser is not supporting the Videoplay.
      </video>
      <div className="video-container-contents">
        {/* Left side message */}
        <div className="frontend-container">
          <div className="video-container-inner">
            <div className="message">
              Event hosting solution helps <br />
              <Link to={`/service/${Services[0].slug}`}>
                Event details (What & how to),
              </Link>
              <Link to={`/service/${Services[1].slug}`}>Send Invitations,</Link>
              <Link to={`/service/${Services[2].slug}`}>
                Book a Priest Service,
              </Link>
              <Link to={`/service/${Services[3].slug}`}>
                Rent from Tent Houses
              </Link>{" "}
              and{" "}
              <Link to={`/service/${Services[4].slug}`}>Order Catering</Link>
            </div>
            <EventForm></EventForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventSection;
