import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Typography,
} from "@mui/material";
import {
  getTentHouseProviderServices,
  getTentHouseProviderAddedServices,
  syncTentHouseProviderServices,
} from "../../Apiservices/Auth/servers/fetchProviderData";

function TentHouseService(props) {
  const INDICATOR_CONFIG = {
    highlightRow: true, // Highlights the entire row if `toSave` is true
    highlightCell: false, // Highlights individual cells that have been changed
    showRedDot: false, // Shows a red dot (🔴) before service name if `toSave` is true
    showUnsavedLabel: true, // Displays "(Unsaved)" next to service name if `toSave` is true
  };
  const [gridData, setGridData] = useState([]);
  const [filteredGridData, setFilteredGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  // Fetch services data
  const resultedServicesFunc = useCallback(async () => {
    setLoading(true);
    try {
      const tentHouseServices = await getTentHouseProviderServices(); // All services
      const addedServices = await getTentHouseProviderAddedServices(); // Services that are already mapped
      const tempGridData = tentHouseServices.map((service) => {
        const existingService = addedServices.find(
          (added) => added.tent_house_service_id === service.id
        );

        return {
          id: service.id,
          tent_house_service_id: service.id,
          service_name: service.service_name,
          description: service.description,
          eventMappingId: existingService.eventMappingId,
          price: existingService ? existingService.price : null,
          discount_price: existingService
            ? existingService.discount_price
            : null,
          discount_valid_upto: existingService
            ? new Date(existingService.discount_valid_upto)
            : null,
          toSave: false,
          alreadyMapped: !!existingService,
          errorMessage: "",
        };
      });

      tempGridData.sort((a, b) => {
        if (a.alreadyMapped === b.alreadyMapped) {
          return a.service_name.localeCompare(b.service_name);
        }
        return a.alreadyMapped ? -1 : 1;
      });

      setGridData(tempGridData);
      setFilteredGridData(tempGridData);
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    resultedServicesFunc();
  }, []);
  const handleInputChange = (id, field, value) => {
    // Update gridData first
    const updatedGridData = gridData.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          [field]: value,
          toSave: true,
          changedFields: [...new Set([...(item.changedFields || []), field])],
          errorMessage: "", // Clear previous errors
        };

        // Error handling: If Discount Price or Valid Upto is entered but no Price, show error
        if (
          field === "discount_price" &&
          value &&
          Number(value) > Number(updatedItem.price)
        ) {
          updatedItem.errorMessage =
            "Discount price must be less than or equal to price.";
        }

        if (
          field === "price" &&
          updatedItem.discount_price &&
          Number(updatedItem.discount_price) > Number(value)
        ) {
          updatedItem.errorMessage =
            "Discount price must be less than or equal to price.";
        }

        // Date validation: Discount valid date should not be in the past
        if (field === "discount_valid_upto") {
          const selectedDate = new Date(value);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set time to start of the day for proper comparison

          if (selectedDate < today) {
            updatedItem.errorMessage =
              "Discount valid date cannot be in the past.";
          }
        }
        return updatedItem;
      }
      return item;
    });

    setGridData(updatedGridData);

    // Apply filter again to update filteredGridData
    const updatedFilteredData = updatedGridData.filter(
      (service) =>
        service.service_name.toLowerCase().includes(searchTerm) ||
        service.description.toLowerCase().includes(searchTerm)
    );

    setFilteredGridData(updatedFilteredData);
    setHasChanges(updatedGridData.some((service) => service.toSave));
    setHasErrors(updatedGridData.some((service) => service.errorMessage));
  };

  // Reset a row
  const handleRemoveService = (id) => {
    const tempUpdatedGridData = gridData.map((service) =>
      service.id === id
        ? {
            ...service,
            price: null,
            discount_price: null,
            discount_valid_upto: null,
            toSave: true, // Mark it as needing to be saved
            changedFields: ["price", "discount_price", "discount_valid_upto"],
          }
        : service
    );

    setGridData(tempUpdatedGridData);
    setHasChanges(true);

    // Apply filter again to update filteredGridData
    const updatedFilteredData = tempUpdatedGridData.filter(
      (service) =>
        service.service_name.toLowerCase().includes(searchTerm) ||
        service.description.toLowerCase().includes(searchTerm)
    );

    setFilteredGridData(updatedFilteredData);
  };

  // Handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    if (value === "") {
      setFilteredGridData(gridData);
    } else {
      setFilteredGridData(
        gridData.filter(
          (service) =>
            service.service_name.toLowerCase().includes(value) ||
            service.description.toLowerCase().includes(value)
        )
      );
    }
  };

  const handleSaveChanges = async () => {
    if (hasErrors) return;

    const changesToSave = filteredGridData.filter((service) => service.toSave);
    try {
      await syncTentHouseProviderServices(changesToSave);
      setHasChanges(false);
      await resultedServicesFunc();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  // Show loading spinner while fetching
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper style={{ padding: "20px" }}>
      <h3>Item List</h3>
      <TextField
        fullWidth
        label="Search Items"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: "10px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!hasChanges}
        >
          Save Changes
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Item Name</b>
              </TableCell>
              <TableCell>
                <b>Price</b>
              </TableCell>
              <TableCell>
                <b>Discount Price</b>
              </TableCell>
              <TableCell>
                <b>Discount Valid Upto</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredGridData.map((service) => {
              const isRowHighlighted =
                INDICATOR_CONFIG.highlightRow && service.toSave; // Row Highlighting Condition

              return (
                <TableRow
                  key={service.id}
                  style={{
                    backgroundColor: isRowHighlighted ? "#ffe6e6" : "inherit", // Row turns light red if changes exist
                  }}
                >
                  {/* Service Name with Indicators */}
                  <TableCell
                    style={{
                      backgroundColor:
                        INDICATOR_CONFIG.highlightCell &&
                        service.changedFields?.includes("service_name")
                          ? "#ffeb99"
                          : "inherit",
                    }}
                  >
                    {INDICATOR_CONFIG.showRedDot && service.toSave && (
                      <span style={{ color: "red", marginRight: "5px" }}>
                        ●
                      </span>
                    )}
                    {service.service_name}
                    {INDICATOR_CONFIG.showUnsavedLabel && service.toSave && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        (Unsaved)
                      </span>
                    )}
                    {service.errorMessage && (
                      <Typography
                        color="error"
                        variant="caption"
                        display="block"
                      >
                        {service.errorMessage}
                      </Typography>
                    )}
                  </TableCell>

                  {/* Price */}
                  <TableCell
                    style={{
                      backgroundColor:
                        INDICATOR_CONFIG.highlightCell &&
                        service.changedFields?.includes("price")
                          ? "#ffeb99"
                          : isRowHighlighted
                          ? "#ffe6e6"
                          : "inherit",
                    }}
                  >
                    <TextField
                      value={service.price || ""}
                      onChange={(e) =>
                        handleInputChange(service.id, "price", e.target.value)
                      }
                      type="number"
                      variant="outlined"
                      size="small"
                      style={{ width: "100px" }}
                    />
                  </TableCell>

                  {/* Discount Price */}
                  <TableCell
                    style={{
                      backgroundColor:
                        INDICATOR_CONFIG.highlightCell &&
                        service.changedFields?.includes("discount_price")
                          ? "#ffeb99"
                          : isRowHighlighted
                          ? "#ffe6e6"
                          : "inherit",
                    }}
                  >
                    <TextField
                      value={service.discount_price || ""}
                      onChange={(e) =>
                        handleInputChange(
                          service.id,
                          "discount_price",
                          e.target.value
                        )
                      }
                      type="number"
                      variant="outlined"
                      size="small"
                      style={{ width: "100px" }}
                    />
                  </TableCell>

                  {/* Discount Valid Upto */}
                  <TableCell
                    style={{
                      backgroundColor:
                        INDICATOR_CONFIG.highlightCell &&
                        service.changedFields?.includes("discount_valid_upto")
                          ? "#ffeb99"
                          : isRowHighlighted
                          ? "#ffe6e6"
                          : "inherit",
                    }}
                  >
                    <TextField
                      value={
                        service.discount_valid_upto
                          ? new Date(service.discount_valid_upto)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          service.id,
                          "discount_valid_upto",
                          e.target.value
                        )
                      }
                      inputProps={{
                        min: new Date().toISOString().split("T")[0], // Disables past dates
                      }}
                      type="date"
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>

                  {/* Reset Button */}
                  <TableCell>
                    <Button
                      onClick={() => handleRemoveService(service.id)}
                      variant="outlined"
                      color="error"
                      disabled={!service.toSave && !service.alreadyMapped}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default TentHouseService;
