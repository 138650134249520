import React from 'react'
import CreateEvent from '../eventComponent/EventTabs/CreateEvent'

const CreateEventCTA = () => {
    return (
        <div className="create-event-cta">
            <div className="create-event-head">
                <img src={`${process.env.PUBLIC_URL}/assets/images/calendar.png`}
                    alt="Create Event"
                    style={{width:'30px'}}
                />
                <h3>What Event are you Planning</h3>
            </div>
            <div className="create-event-btn">
                <p>Creat a event and find yourself how easy it is.</p>
                <CreateEvent />
            </div>
        </div>
    )
}
export default CreateEventCTA