import React, { useCallback, useEffect, useState } from "react";
import { format } from 'date-fns';
import {
    CssBaseline,
    Stack,
    IconButton
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css"
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getProviderServicesByID, fetchProviderData } from "../../Apiservices/Auth/servers/fetchProviderData";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LoadMap from "./LoadMap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../store/cartSlice";

import providerData from "../../ProviderServices.json"
import CreateEventCTA from "./CreateEventCTA";

function EventProviderServices() {
    const cart = useSelector((state) => state.cart.serviceList);
    //console.log(cart);

    const dispatch = useDispatch();

    const checkForAdd = (itemId) => {
        const found = cart.find(opt => (
            opt.itemID === itemId
        ));
        return found;
    }

    const addToService = (opt) => {
        dispatch(addToCart(opt));
    }

    const removeService = (opt) => {
        dispatch(removeFromCart(opt));
    }

    const [loading, setLoading] = useState(true);
    //const [providerData, setProviderData] = useState([]);
    const [providerServices, setProviderServices] = useState([]);

    const location = useLocation();
    const { serviceDetails, providerDetails, eventDetails } = location.state || {}; // Get event details
    const [profileDialogVisible, setProfileDialogVisible] = useState(false);

    const handleDialog = () => {
        setProfileDialogVisible(true)
    }

    const navigate = useNavigate();

    const backtoEvents = () => {
        if (eventDetails && Object.keys(eventDetails).length > 0) {
            const info = {
                serviceDetails: serviceDetails,
                eventDetails: eventDetails
            }
            navigate("/providers", { state: info });
            window.scrollTo({ top: 0 });
        } else {
            const info = {
                serviceDetails: serviceDetails,
                eventDetails: {}
            }
            navigate("/providers", { state: info });
            window.scrollTo({ top: 0 });
        }
    }

    const { id, firstName, lastName, addresses, mobileNumber } = providerDetails;

    /* const fetchProviders = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await fetchProviderData();
            setProviderData(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, []); */

    const fetchProviderServices = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await getProviderServicesByID(id);
            setProviderServices(provider_data.services);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        //fetchProviders();
        fetchProviderServices();
    }, []);


    const handleEventService = (service) => {
        const info = {
            serviceDetails: service,
            eventDetails: (eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails : {}
        }
        service.id === 2 ? navigate("/dashboard", { state: info }) : navigate("/providers", { state: info });
        window.scrollTo({ top: 0 });
    }

    const createEventHandle = () => {
        navigate("/dashboard");
        window.scrollTo({ top: 0 });
    };

    return (
        <div style={{ width: "100%" }}>
            <CssBaseline />
            <Stack spacing={7} sx={{ flexGrow: 1 }}>
                <ResponsiveAppBar params={"dashboard"} />
            </Stack>
            <div className="main-container">
                <div className="frontend-container">
                    <div style={{ width: '100%', paddingBottom: '30px' }}>
                        <div className="event-form-inner">
                            <div className="event-form-head">
                                <div className="event-service-head-inner">
                                    {/* <img
                                        style={{
                                            display: "block",
                                            width: "50px",
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/images/${serviceDetails.id}.png`}
                                        alt={serviceDetails.serviceName}
                                    /> */}
                                    <IconButton
                                        onClick={backtoEvents}
                                        style={{ color: '#FF895D', padding: '8px' }}
                                    >
                                        <KeyboardBackspaceOutlinedIcon />
                                    </IconButton>
                                    <div>
                                        <h3>{firstName} {lastName}</h3>
                                        <div className="share-invite-container" style={{ marginTop: 0 }}>
                                            <span className="vm" onClick={handleDialog} style={{ fontSize: '14px', textTransform: 'uppercase' }}>
                                                Contact Details <i style={{ fontSize: '12px' }} className="pi pi-chevron-right"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="btns group">
                                    <Button
                                        icon="pi pi-angle-left"
                                        className="custom-button"
                                        label="Go Back"
                                        onClick={backtoEvents}
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        className="custom-button"
                                        label="Create Event"
                                        onClick={createEventHandle}
                                    />
                                </div> */}
                            </div>
                            <div className="event-form-body list2">
                                <div className="event-providerservcies">
                                    {/* <div className="event-service-list">
                                        <div className="event-service alter">
                                            <div className="event-service-head">
                                                <h3>Create an event and find yourself how easy it is.</h3>
                                                <Button
                                                    className="custom-button"
                                                    icon="pi pi-plus"
                                                    label="Create Event"
                                                    size="small"
                                                    onClick={() => navigate('/dashboard')}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {loading ? (
                                        <h3 style={{ width: '100%', textAlign: "center" }}><i className="pi pi-spin pi-spinner-dotted" style={{ fontSize: '2rem', color: '#0cc0df' }}></i></h3>
                                    ) : providerServices.length > 0 ? providerServices.filter(item => item.serviceMaster.providerTypeId === serviceDetails.id).map((item) => {
                                        return (<div key={item.serviceMaster.id} className={`event-service alter ${cart && cart.length > 0 && checkForAdd(`${item.service_master_id}_${item.service_provider_id}_${(eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails.id : 0}`) && 'sel-event'}`}>
                                            <div className="event-service-headtop">
                                                <h3>{item.serviceMaster.serviceName}</h3>

                                                <div style={{ marginBottom: '10px' }} className="desc">{item.serviceMaster.description}</div>

                                                {new Date(item.discountValidUpto) > new Date() && item.discountPrice ? <div className="price"><s>&#8377;{item.price}</s><br /><strong>&#8377;{item.discountPrice}</strong><span style={{ color: 'red', fontSize: '20px' }}>*</span></div> : <div className="price"><strong>&#8377;{item.price}</strong></div>}
                                                {new Date(item.discountValidUpto) > new Date() && <div className="validupto"><span style={{ color: 'red', fontSize: '20px' }}>*</span> Valid upto {format(new Date(item.discountValidUpto), "MMMM dd, yyyy")}</div>}

                                            </div>
                                            <div className="event-service-middle">
                                                {cart && cart.length > 0 && checkForAdd(`${item.service_master_id}_${item.service_provider_id}_${(eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails.id : 0}`) ? <Button
                                                    icon="pi pi-minus"
                                                    className="custom-button cancel"
                                                    label="Remove Service"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    onClick={() => removeService({
                                                        'itemID': `${item.service_master_id}_${item.service_provider_id}_${(eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails.id : 0}`
                                                    })}
                                                /> : <Button
                                                    icon="pi pi-plus"
                                                    className="custom-button"
                                                    label="Add Service"
                                                    size="small"
                                                    style={{ width: '100%' }}
                                                    onClick={() => addToService({
                                                        'itemID': `${item.service_master_id}_${item.service_provider_id}_${(eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails.id : 0}`,
                                                        'service': `${item.serviceMaster.serviceName}`,
                                                        'provider': `${firstName} ${lastName}`,
                                                        'event': `${(eventDetails && Object.keys(eventDetails).length > 0) ? eventDetails.eventTitle : null}`,
                                                        'amount': new Date(item.discountValidUpto) > new Date() && item.discountPrice ? item.discountPrice : item.price
                                                    })}
                                                />}
                                            </div>
                                        </div>
                                        );
                                    }) : <h4 style={{ width: '100%' }} className="text-center">We are not currently offering {serviceDetails.serviceName} services</h4>}
                                </div>
                                <div className="event-service-list sidebar">
                                    <CreateEventCTA />
                                    {providerData.length > 0 && providerData.map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                        <div className="event-service-head">
                                            <div>
                                                <img
                                                    style={{
                                                        display: "block",
                                                        width: "30px",
                                                    }}
                                                    src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                                    alt={item.serviceCardName}
                                                />
                                                <div>
                                                    <h3>{item.serviceCardName}</h3>
                                                    {/* <div className="event-rsvp">
                                                        {item.serviceDescription}
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="pi pi-chevron-right"></div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <Dialog
                                header={`${firstName} ${lastName}`}
                                visible={profileDialogVisible}
                                style={{ width: "calc(100% - 15px)", maxWidth: "400px" }}
                                modal
                                onHide={() => setProfileDialogVisible(false)}
                            >
                                <div className="event-service-list">
                                    <div className="event-service alter">
                                        <div className="event-service-head" style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '15px' }}>
                                            <LoadMap locationLat={addresses[0].latitude} locationLong={addresses[0].longitude} width="100%" height="180px" />
                                            <div>
                                                <LocationOnOutlinedIcon className="plicon" />
                                                {addresses[0].formattedAddress}
                                            </div>
                                            <div>
                                                <PhoneAndroidOutlinedIcon className="plicon" />
                                                {mobileNumber}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
            <Footer params={"dashboard"} />
        </div>
    )
}

export default EventProviderServices