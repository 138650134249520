import config from "../../../config";
import axiosInstance from '../../../util/axiosInstance';

import { getToken } from "../../../util/Cookies";

export const fetchReligionCaste = async () => {
    try {
        const response = await axiosInstance.get('/religions', {
            method: "GET",
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Network response was not ok');
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};