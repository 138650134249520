import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Collapse,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  getPriestEvents,
  getPriestProviderEvents,
  savePriestEvents,
} from "../../Apiservices/Auth/servers/fetchProviderData";

function PriestManageServices(props) {
  const [loading, setLoading] = useState(true);
  const [priestEventMaster, setPriestEventMaster] = useState([]);
  const [priestEventMasterFiltered, setPriestEventMasterFiltered] = useState(
    []
  );
  // const [priestMappedEvent, setPriestMappedEvent] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);

  const INDICATOR_CONFIG = {
    highlightRow: true, // Highlights the entire row if `toSave` is true
    highlightCell: false, // Highlights individual cells that have been changed
    showRedDot: false, // Shows a red dot (🔴) before service name if `toSave` is true
    showUnsavedLabel: true, // Displays "(Unsaved)" next to service name if `toSave` is true
  };

  // Fetch services data
  const resultedServicesFunc = useCallback(async () => {
    setLoading(true);
    try {
      const priestEventMaster = await getPriestEvents(); // All events
      const priesAddedServices = await getPriestProviderEvents(); // Services that are already mapped
      let transformedData = [];

      // Sort categories alphabetically
      priestEventMaster.sort((a, b) => a.name.localeCompare(b.name));

      priestEventMaster.forEach((category) => {
        const newCategory = {
          id: category.id,
          categoryName: category.name,
          categoryDescription: category.longDescription, // Category description
          events: [],
          isOpen: true,
        };

        let mappedEvents = [];
        let unmappedEvents = [];

        category.events.forEach((event) => {
          const existingEvent = priesAddedServices.find(
            (mapped) =>
              mapped.categoryId === category.id &&
              mapped.events.some(
                (mappedEvent) => mappedEvent.eventId === event.id
              )
          );

          const newEvent = {
            id: event.id, // Event ID
            name: event.name, // Event Name
            categoryId: category.id,
            description: event.description, // Event Description
            eventMappingId: existingEvent
              ? existingEvent.events.find((e) => e.eventId === event.id)
                  ?.eventMappingId
              : "",
            price: existingEvent
              ? existingEvent.events.find((e) => e.eventId === event.id)?.price
              : "", // Use price from existing data if exists
            discountPrice: existingEvent
              ? existingEvent.events.find((e) => e.eventId === event.id)
                  ?.discountPrice
              : "",
            discountValidUpto: existingEvent
              ? existingEvent.events.find((e) => e.eventId === event.id)
                  ?.discountValidUpto
              : "",
            toSave: false, // Flag to track changes
            errorMessage: "", // Error message for validation
            alreadyMapped: !!existingEvent, // Flag if already mapped
          };

          // Separate mapped & unmapped events
          if (newEvent.alreadyMapped) {
            mappedEvents.push(newEvent);
          } else {
            unmappedEvents.push(newEvent);
          }

          // newCategory.events.push(newEvent);
        });
        mappedEvents.sort((a, b) => a.name - b.name);
        unmappedEvents.sort((a, b) => a.name - b.name);

        newCategory.events = [...mappedEvents, ...unmappedEvents];

        transformedData.push(newCategory);
      });

      setPriestEventMaster(transformedData);
      setPriestEventMasterFiltered(transformedData);

      // setPriestMappedEvent(priesAddedServices);
    } catch (error) {
      console.error("Failed to fetch services:", error);
    } finally {
      setLoading(false);
      setHasChanges(false);
      setHasErrors(false);
    }
  }, []);

  useEffect(() => {
    resultedServicesFunc();
  }, []);
  

  const handleCategoryToggle = (categoryId) => {
    setPriestEventMaster((prevState) => {
      return prevState.map((category) =>
        category.id === categoryId
          ? { ...category, isOpen: !category.isOpen }
          : category
      );
    });

    setPriestEventMasterFiltered((prevFiltered) => {
      return prevFiltered.map((category) =>
        category.id === categoryId
          ? { ...category, isOpen: !category.isOpen }
          : category
      );
    });
  };

  const handleSaveChanges = async () => {
    // Extract only events that need to be saved
    const updatedData = priestEventMaster.flatMap((category) =>
      category.events
        .filter((event) => event.toSave) // Only pick modified events
        .map((event) => ({
          eventMappingId: event.eventMappingId,
          eventId: event.id,
          price: event.price,
          discountPrice: event.discountPrice,
          discountValidUpto: event.discountValidUpto,
        }))
    );

    // Prevent saving if there's nothing to update
    if (updatedData.length === 0) {
      console.warn("No changes to save.");
      return;
    }

    try {
      console.log("Saving data:", updatedData);
      await savePriestEvents(updatedData);
      await resultedServicesFunc(); // Reset change tracker
    } catch (error) {
      console.error("Failed to save changes:", error);
    }
  };

  const checkForErrors = () => {
    const hasErrors = priestEventMaster.some((category) =>
      category.events.some((event) => event.errorMessage)
    );
    setHasErrors(hasErrors);
  };

  useEffect(() => {
    checkForErrors();
  }, [priestEventMaster]);

  const handleInputChange = (categoryId, eventId, field, value) => {
    setPriestEventMaster((prevState) => {
      return prevState.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            events: category.events.map((event) => {
              if (event.id === eventId) {
                return {
                  ...event,
                  [field]: value,
                  toSave: true, // Mark as needing to be saved
                  errorMessage: validateFields({ ...event, [field]: value }), // Validate and set error message
                };
              }
              return event;
            }),
          };
        }
        return category;
      });
    });

    // Also update the filtered state to reflect the changes
    setPriestEventMasterFiltered((prevState) =>
      prevState.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            events: category.events.map((event) => {
              if (event.id === eventId) {
                return {
                  ...event,
                  [field]: value,
                  toSave: true,
                  errorMessage: validateFields({ ...event, [field]: value }),
                };
              }
              return event;
            }),
          };
        }
        return category;
      })
    );
    setHasChanges(true);
    // checkForErrors();
  };
  // Define the validateFields function
  const validateFields = (event) => {
    let errors = [];

    if (
      event.discountPrice &&
      Number(event.discountPrice) > Number(event.price)
    ) {
      errors.push("Discount price must be less than or equal to price.");
    }

    if (
      event.discountValidUpto &&
      new Date(event.discountValidUpto) < new Date()
    ) {
      errors.push("Discount valid date cannot be in the past.");
    }

    if (
      (event.discountPrice || event.discountValidUpto) &&
      (!event.price || Number(event.price) <= 0)
    ) {
      errors.push(
        "Price is required when discount price or discount valid date is provided."
      );
    }

    return errors.length > 0 ? errors.join(" ") : "";
  };

  const handleRemoveEvent = (categoryId, eventId) => {
    const updateCategoryEvents = (categories) =>
      categories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              events: category.events.map((event) =>
                event.id === eventId
                  ? {
                      ...event,
                      price: null, // Set price to null
                      discountPrice: null, // Set discount price to null
                      discountValidUpto: null, // Set discount valid date to null
                      toSave: true, // Mark as modified
                      errorMessage: "", // Clear error messages
                    }
                  : event
              ),
            }
          : category
      );

    setPriestEventMaster((prevState) => updateCategoryEvents(prevState));
    setPriestEventMasterFiltered((prevState) =>
      updateCategoryEvents(prevState)
    );
    setHasChanges(true);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    if (searchValue === "") {
      setPriestEventMasterFiltered([...priestEventMaster]); // Ensure a new reference
      return;
    }

    const filteredData = priestEventMaster
      .map((category) => {
        const filteredEvents = category.events.filter(
          (event) =>
            (event.name?.toLowerCase() || "").includes(searchValue) || // Handle null/undefined
            (event.description?.toLowerCase() || "").includes(searchValue) // Handle null/undefined
        );

        return filteredEvents.length > 0
          ? { ...category, events: filteredEvents }
          : null; // Remove category if no matching event
      })
      .filter(Boolean); // Remove null values

    setPriestEventMasterFiltered(filteredData);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper style={{ padding: "20px" }}>
      <h3>Item List</h3>
      <TextField
        fullWidth
        label="Search Events"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: "10px" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!hasChanges || hasErrors}
        >
          Save Changes
        </Button>
      </div>
      <Grid container spacing={2}>
        {priestEventMasterFiltered.map((category) => (
          <Grid item xs={12} key={category.id}>
            <Paper style={{ padding: "10px", marginBottom: "10px" }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <h4>{category.categoryName}</h4>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: "40px",
                      height: "40px",
                      minWidth: "40px", // Ensures button does not expand
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition:
                        "background-color 0.3s ease-in-out, transform 0.3s ease-in-out",
                      "&:hover": {
                        backgroundColor: "#1976d2",
                        transform: "scale(1.1)", // Slight scale effect on hover
                      },
                    }}
                    onClick={() => handleCategoryToggle(category.id)}
                  >
                    <ExpandMoreIcon
                      sx={{
                        fontSize: 24,
                        transition: "transform 0.3s ease-in-out",
                        transform: category.isOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </Button>
                </Box>
                <hr
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    margin: "10px 0",
                  }}
                />
              </Box>

              <Collapse in={category.isOpen}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Event Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Price</b>
                        </TableCell>
                        <TableCell>
                          <b>Discount Price</b>
                        </TableCell>
                        <TableCell>
                          <b>Discount Valid Upto</b>
                        </TableCell>
                        <TableCell>
                          <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {category.events.map((event) => (
                        <TableRow
                          key={event.id}
                          style={{
                            backgroundColor: event.toSave
                              ? "#ffe6e6"
                              : "inherit",
                          }}
                        >
                          <TableCell
                            style={{
                              backgroundColor:
                                INDICATOR_CONFIG.highlightCell &&
                                event.errorMessage
                                  ? "#ffeb99"
                                  : "inherit",
                            }}
                          >
                            {INDICATOR_CONFIG.showRedDot && event.toSave && (
                              <span
                                style={{ color: "red", marginRight: "5px" }}
                              >
                                ●
                              </span>
                            )}
                            {event.name}
                            {INDICATOR_CONFIG.showUnsavedLabel &&
                              event.toSave && (
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  (Unsaved)
                                </span>
                              )}
                            {event.errorMessage && (
                              <Typography
                                color="error"
                                variant="caption"
                                display="block"
                              >
                                {event.errorMessage}
                              </Typography>
                            )}
                          </TableCell>

                          <TableCell>
                            <TextField
                              value={event.price ?? ""}
                              onChange={(e) =>
                                handleInputChange(
                                  category.id,
                                  event.id,
                                  "price",
                                  e.target.value
                                )
                              }
                              type="number"
                              variant="outlined"
                              size="small"
                              style={{ width: "150px" }}
                            />
                          </TableCell>

                          <TableCell>
                            <TextField
                              value={event.discountPrice ?? ""}
                              onChange={(e) =>
                                handleInputChange(
                                  category.id,
                                  event.id,
                                  "discountPrice",
                                  e.target.value
                                )
                              }
                              type="number"
                              variant="outlined"
                              size="small"
                              style={{ width: "150px" }}
                            />
                          </TableCell>

                          <TableCell>
                            <TextField
                              value={
                                event.discountValidUpto
                                  ? event.discountValidUpto.split("T")[0]
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  category.id,
                                  event.id,
                                  "discountValidUpto",
                                  e.target.value
                                )
                              }
                              type="date"
                              variant="outlined"
                              size="small"
                              style={{ width: "180px" }}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0],
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() =>
                                handleRemoveEvent(category.id, event.id)
                              }
                              variant="outlined"
                              color="error"
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default PriestManageServices;
