import React, { useCallback, useRef, useState } from "react";
import {
  addCateringProviderMenuImage,
  addCateringProviderServices,
} from "../../Apiservices/Auth/servers/fetchProviderData";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button as PrimeButton } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function EditCateringServiceForm({
  cuisines,
  mealTypes,
  services,
  resultedServices,
  setServices,
  fetchProviderServices,
  setTempToAddServices,
  setCateringTempSelectedServices,
}) {
  const MAX_FILE_SIZE = 10000000;
  const toast = useRef(null);
  const fileRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditServiceTabularFormLoading, setIsEditServiceTabularFormLoading] =
    useState(false);

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(""); // Clear error after 3 seconds
    }, 3000);
  };

  const handleFieldChange = useCallback(
    (event, index) => {
      setServices((prevServices) =>
        prevServices.map((service, i) =>
          i === index
            ? { ...service, [event.target.name]: event.value }
            : service
        )
      );
    },
    [setServices]
  );

  const handleRemove = (index) => {
    const updatedServices = services.filter((_, i) => i !== index);

    setServices(updatedServices);
    setCateringTempSelectedServices(updatedServices);
    setTempToAddServices(updatedServices);
  };

  const onUpload = async (event, index) => {
    if (event?.files?.length === 0) {
      console.error("No file uploaded.");
      showErrorMessage(`No menu uploaded`);
      return;
    }

    const file = event?.files?.[0];

    if (file?.size > MAX_FILE_SIZE) {
      console.error("File size exceeds the 10MB limit.");
      showErrorMessage(`File size should be less than 10MB.`);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await addCateringProviderMenuImage(formData);

    if (!response?.file?.id) {
      showErrorMessage(`Something went wrong file cannot be uploaded.`);
    } else {
      setServices((prevServices) =>
        prevServices.map((service, i) =>
          i === index
            ? { ...service, image_ids: [response?.file?.id] }
            : service
        )
      );
    }
    if (fileRef && fileRef.current) {
      fileRef.current.clear();
    }
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setIsEditServiceTabularFormLoading(true);

    const hasUnselectedMealCategory = services.filter(
      (svc) => svc.diet_category === null || svc.diet_category === undefined
    );

    if (hasUnselectedMealCategory.length > 0) {
      console.log(
        "❌ Error: Some services are missing meal category:",
        hasUnselectedMealCategory
      );
      showErrorMessage(
        `Some services are missing meal category: ${hasUnselectedMealCategory
          .map((service) => service["meal_name"])
          .join(", ")}`
      );
      setIsEditServiceTabularFormLoading(false);
      return;
    }

    const hasUnselectedCuisine = services.filter(
      (svc) => svc.type_id === undefined || svc.type_id === null
    );

    if (hasUnselectedCuisine.length > 0) {
      console.log(
        "❌ Error: Some services are missing cuisine type:",
        hasUnselectedCuisine
      );
      showErrorMessage(
        `Some services are missing cuisine type: ${hasUnselectedCuisine
          .map((service) => service["meal_name"])
          .join(", ")}`
      );
      setIsEditServiceTabularFormLoading(false);
      return;
    }

    const hasMissingMinPeople = services?.filter(
      (svc) => !svc.min_people && svc.min_people !== 0
    );

    if (hasMissingMinPeople && hasMissingMinPeople.length > 0) {
      console.log(
        "❌ Error: Some services are missing cuisine type:",
        hasMissingMinPeople
      );
      showErrorMessage(
        `Some services are missing min people: ${hasMissingMinPeople
          .map((service) => service["meal_name"])
          .join(", ")}`
      );
      setIsEditServiceTabularFormLoading(false);
      return;
    }

    const hasMissingPrices = services.some(
      (service) => !service.regular_price && service.regular_price !== 0 // Ensure price is not null, undefined, or blank
    );
    if (hasMissingPrices) {
      const missingServices = services
        .filter(
          (service) => !service.regular_price && service.regular_price !== 0
        )
        .map((service) => service["meal_name"]); // Log only service names
      console.log(
        "❌ Error: Some services are missing prices:",
        missingServices
      );
      showErrorMessage(
        `Some services are missing prices: ${missingServices.join(", ")}`
      );
      setIsEditServiceTabularFormLoading(false);
      return;
    }

    // Validate if discountPrice is valid and check discountValidUpto
    const invalidDiscounts = services.filter((service) => {
      const regular_price = parseFloat(service.regular_price || 0); // Safely convert price
      const discounted_price = parseFloat(service.discounted_price || 0); // Safely convert discountPrice

      if (discounted_price > 0 && discounted_price > regular_price) {
        return true; // Discount price exceeds the regular price
      }

      if (
        service.valid_until &&
        new Date(service.valid_until).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0) // Check if date is today or in the past
      ) {
        return true; // Discount valid date is not in the future
      }

      if (
        (discounted_price > 0 && !service.valid_until) ||
        (!discounted_price && service.valid_until)
      ) {
        return true; // Discount price is set but discountValidUpto is not
      }

      return false;
    });

    if (invalidDiscounts.length > 0) {
      console.log(
        "❌ Error: Some services have invalid discount prices or dates:",
        invalidDiscounts.map((service) => service?.["meal_name"]) // Log only service names
      );

      showErrorMessage(
        `Some services have invalid discount prices or dates: ${invalidDiscounts
          .map((service) => service?.["meal_name"])
          .join(", ")}`
      );

      setIsEditServiceTabularFormLoading(false);
      return;
    }

    // Prepare the request payload
    const payload = {
      cateringItems: services.map((service) => ({
        image_ids: service.image_ids,
        category_id: service.diet_category,
        meal_id: service.meal_id, // Ensure this is available in your data
        type_id: service.type_id,
        min_people: service.min_people,
        regular_price: service.regular_price ? service.regular_price : null, // Convert to string
        discounted_price: service.discounted_price
          ? service.discounted_price
          : null,
        valid_until: service.valid_until
          ? new Date(service.valid_until).toISOString()
          : null,
      })),
    };

    try {
      const response = await addCateringProviderServices(payload);

      if (response && response?.data?.length > 0) {
        setServices([]);
        setTempToAddServices([]);
        setCateringTempSelectedServices([]);

        fetchProviderServices();
      } else {
        console.error("❌ Error adding services:", response);
        showErrorMessage("Failed to add services. Please try again.");
      }
    } catch (error) {
      console.error("❌ Error adding services:", error);
      showErrorMessage("Failed to add services. Please try again.");
    } finally {
      setIsEditServiceTabularFormLoading(false);
    }
  };

  const priceBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <InputNumber
        value={rowData.regular_price}
        onValueChange={(e) => handleFieldChange(e, rowIndex)}
        mode="decimal"
        min={0}
        step={1.0}
        showButtons
        name="regular_price"
        placeholder="Price"
      />
    );
  };

  const minPeopleBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <InputNumber
        value={rowData.min_people}
        onValueChange={(e) => handleFieldChange(e, rowIndex)}
        mode="decimal"
        min={0}
        step={1.0}
        name="min_people"
        showButtons
        placeholder="Min people"
      />
    );
  };

  const discountPriceBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <InputNumber
        value={rowData.discounted_price}
        onValueChange={(e) => handleFieldChange(e, rowIndex)}
        mode="decimal"
        min={0}
        step={1.0}
        showButtons
        name="discounted_price"
        placeholder="Discount Price"
      />
    );
  };

  const cuisineTypeBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <div className="card flex justify-content-center">
        <Dropdown
          value={rowData.type_id}
          onChange={(e) => handleFieldChange(e, rowIndex)}
          options={cuisines}
          optionLabel="type_name"
          optionValue="type_id"
          placeholder="Select"
          required
          name="type_id"
          className="w-full md:w-14rem"
        />
      </div>
    );
  };

  const discountValidUptoBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <Calendar
        value={rowData.valid_until}
        showButtonBar
        onClearButtonClick={(e) => handleFieldChange(e, rowIndex)}
        onChange={(e) => handleFieldChange(e, rowIndex)}
        showIcon
        minDate={new Date()}
        dateFormat="dd/mm/yy"
        placeholder="Discount Valid Upto"
        name="valid_until"
      />
    );
  };

  const deleteButtonBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <PrimeButton
        icon="pi pi-times"
        className="p-button-danger p-button-text"
        onClick={() => handleRemove(rowIndex)}
        tooltip="Remove"
      />
    );
  };

  const mealTypeBodyTemplate = (rowData, { rowIndex }) => {
    const currentMealType = mealTypes.find(
      (type) => type.category_id === rowData.diet_category
    );

    const mealOptions = mealTypes?.map((meal) => {
      return {
        label: meal?.category_name,
        value: meal?.category_id,
      };
    });

    const isVeg = currentMealType?.category_name?.toLowerCase() === "veg";

    return (
      <>
        {resultedServices?.find((meal) => meal?.meal_id === rowData?.meal_id)
          ?.diet_category === null ? (
          // Show dropdown if diet_category is null
          <Dropdown
            value={rowData.diet_category}
            options={mealOptions}
            onChange={(e) => handleFieldChange(e, rowIndex)}
            placeholder="Select Meal Type"
            name="diet_category"
            style={{ width: "150px" }}
          />
        ) : (
          // If diet_category is not null, display box and circle as before
          <div
            className="box"
            style={{
              border: isVeg ? "3px solid green" : "3px solid #ff895d",
            }}
          >
            <div
              className="circle"
              style={{
                background: isVeg ? "green" : "#ff895d",
              }}
            ></div>
          </div>
        )}
      </>
    );
  };

  const menuUploadBodyTemplate = (rowData, { rowIndex }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <FileUpload
          id={rowIndex}
          ref={fileRef}
          mode="basic"
          name="menu_upload"
          accept="image/*"
          customUpload
          disabled={services[rowIndex]?.image_ids?.[0] ? true : false}
          auto
          maxFileSize={MAX_FILE_SIZE}
          chooseLabel={
            services[rowIndex]?.image_ids?.[0] ? "Selected" : "Choose"
          }
          multiple={false} // Ensures only one file can be selected
          onSelect={(e) => onUpload(e, rowIndex)}
        />
        {services[rowIndex]?.image_ids?.[0] && (
          <PrimeButton
            icon="pi pi-times"
            style={{
              background: "transparent",
              color: "#ff895d",
              border: "1px solid #ff895d",
              borderRadius: "100%",
              width: "30px",
              height: "30px",
            }}
            text
            onClick={() => {
              setServices((prevServices) =>
                prevServices.map((service, i) =>
                  i === rowIndex ? { ...service, image_ids: [] } : service
                )
              );
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="p-d-flex p-jc-center p-mt-5">
      <div className="p-card p-shadow-4" style={{ padding: "2rem" }}>
        <Toast ref={toast} />
        {isEditServiceTabularFormLoading ? (
          <div className="p-d-flex p-jc-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
            {/* You can replace this with a custom spinner or any other loading indicator */}
          </div>
        ) : (
          <DataTable
            value={services}
            paginator
            rows={5}
            style={{ marginBottom: "2rem" }}
          >
            <Column field={"meal_name"} header={"Service Name"} />
            <Column
              field={"category_id"}
              header={"Meal Category (Veg/Non Veg)"}
              body={mealTypeBodyTemplate}
            />
            <Column
              field={"menu_upload"}
              header={"Upload a Menu"}
              body={menuUploadBodyTemplate}
            />
            <Column
              field={"type_id"}
              header={"Cuisine Type"}
              body={cuisineTypeBodyTemplate}
            />
            <Column
              header="Min People"
              body={minPeopleBodyTemplate}
              // Ensure fixed width for the column
            />
            <Column
              header="Price"
              body={priceBodyTemplate}
              // Ensure fixed width for the column
            />
            <Column header="Discount Price" body={discountPriceBodyTemplate} />
            <Column
              header="Discount Valid Upto"
              body={discountValidUptoBodyTemplate}
            />
            <Column
              header="Actions"
              body={deleteButtonBodyTemplate}
              style={{ textAlign: "center", width: "100px" }}
            />
          </DataTable>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {errorMessage && (
            <span
              className="blink"
              style={{
                color: "red",
                marginRight: "1rem",
                alignSelf: "center",
                fontSize: "0.9rem",
              }}
            >
              {errorMessage}
            </span>
          )}
          <PrimeButton
            label="Submit"
            icon="pi pi-check"
            onClick={handleSubmit}
            className="p-button-success"
          />
        </div>
      </div>
    </div>
  );
}

export default EditCateringServiceForm;
