import React from "react";
import "./Howdoesitwork.css"; // Custom CSS for circular layout

function Howdoesitwork() {
  return (
    <div className="howdoesitwork">
      <div className="frontend-container">
        <div className="hdwork">
          <div className="header">
            <div className="header-circle">
              <div className="header-circle-outer">
                <div className="header-circle-inner">
                  <h2 className="font-legal font-h2">How Does it Work</h2>
                </div>
              </div>
              {/* <div className="indicator create"></div>
              <div className="indicator invite"></div>
              <div className="indicator select"></div>
              <div className="indicator payconfirm"></div> */}
            </div>
          </div>
          <div className="steps">
            <div className="step create">
              {/* <div className="num">1</div> */}
              <div className="desc">
                <h3 className="font-legal">Create</h3>
                <p>Create an Event (or) Select from Packages</p>
              </div>
            </div>
            <div className="step invite">
              {/* <div className="num">2</div> */}
              <div className="desc">
                <h3 className="font-legal">Invite</h3>
                <p>Invite Friends/Family via WhatsApp</p>
              </div>
            </div>
            <div className="step select">
              {/* <div className="num">3</div> */}
              <div className="desc">
                <h3 className="font-legal">Select</h3>
                <p>Select Provider from the List</p>
              </div>
            </div>
            <div className="step payconfirm">
              {/* <div className="num">4</div> */}
              <div className="desc">
                <h3 className="font-legal">Pay & Confirm</h3>
                <p>Pay Online & Confirm Order</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Howdoesitwork;
