import React from "react";
import Services from "../../ProviderServices.json";
import CreateEvent from "../eventComponent/EventTabs/CreateEvent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import config from "../../config";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";

function AIEnabled({ buttonLabel }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const providerListStorageKey = config.providerListStorageKey;
  const service = Services.find((obj) => obj.id === 1);

  const navtoProviders = (service) => {
    var info = {
      serviceDetails: service,
      eventDetails: {},
    };
    if (isLoggedIn) {
      service.id === 2
        ? navigate("/dashboard", { state: info })
        : navigate("/providers", { state: info });
      window.scrollTo({ top: 0 });
    } else {
      alertdialog(info);
    }
  };

  const alertdialog = (info) => {
    confirmDialog({
      message: `You need login to check How easy it is.`,
      header: "Please Login or Sign-Up",
      defaultFocus: "accept",
      accept: () => {
        localStorage.setItem(providerListStorageKey, JSON.stringify(info));
        navigate("/login");
      },
    });
  };

  const testimonials = [
    {
      id: 1,
      message:
        "Thurent made my daughter’s birthday party so much easier to plan. The RSVP tracking helped me know exactly how many guests to expect, saving us time and money!",
      author: "Priya R.",
    },
    {
      id: 2,
      message:
        "Morbi hendrerit felis leo, eget ornare mi consectetur a. Proin dignissim justo non neque semper, at viverra augue rutrum",
      author: "Nick",
    },
    {
      id: 3,
      message:
        "Praesent dignissim sapien vitae mollis hendrerit. Vestibulum sodales, tellus sit amet convallis finibus, mauris tellus posuere est, a tempus ex est a libero.",
      author: "Thurent",
    },
  ];

  const testimonialTemplate = (testimonial) => {
    return (
      <div>
        <h3 className="message">{testimonial.message}</h3>
        <h5 className="author">-{testimonial.author}</h5>
      </div>
    );
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        Discover Step-by-Step Rituals and proceedings of an event "AI Enabled
        Event Details" by clicking here:
      </p>

      <p style={{ textAlign: "center" }}>
        <Button
          className="custom-button cancel"
          icon="pi pi-arrow-right"
          iconPos="right"
          label={buttonLabel}
          onClick={() => navtoProviders(service)}
        />
      </p>

      {isLoggedIn ? (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Create an event and find yourself how easy it is.</h3>
              <CreateEvent />
            </div>
          </div>
        </div>
      ) : (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Start Planning Smarter—Join Thurent Now!</h3>
              <Button
                className="custom-button"
                icon="pi pi-sign-in"
                iconPos="right"
                label="Sign up"
                onClick={() => navigate("/signup")}
                size="small"
              />
            </div>
          </div>
        </div>
      )}

      <p>
        Our "AI Enabled Event Details" gives you step by step instructions on
        what and how to conduct the event. We also provide quick snippets about
        the history or significance of each ritual or festival, as well . Just
        imagine using your phone, get a list that you can knock off easily one
        task after the other.
      </p>

      <h2 className="font-legal">What our clients think about us?</h2>
      <div className="testimonials">
        <Carousel
          value={testimonials}
          itemTemplate={testimonialTemplate}
          showNavigators={false}
          autoplayInterval={4000}
          circular={true}
        />
      </div>

      {/* <div className="event-service-list">
                <div className="event-service alter">
                    <div className="event-service-head">
                        <h3>READY? Create an event and find yourself how easy it is.</h3>
                        <CreateEvent />
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default AIEnabled;
