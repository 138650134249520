import React from "react";
import LoadMap from "../EventServices/LoadMap";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "primeflex/primeflex.css";
function ProviderListingCard({
  key,
  latitude,
  longitude,
  name,
  address,
  distance,
  mobile,
  email,
  price,
  discount,
  validUpto,
}) {
  return (
    <div
      key={key}
      className="event-service w-full"
      //   onClick={() => {
      //     if (hasValidServices) {
      //       console.log("There is prices we can directly add them ");
      //     } else {
      //       handleProviderServcies(item);
      //     }
      //   }}
    >
      {latitude && longitude && (
        <LoadMap
          locationLat={latitude}
          locationLong={longitude}
          width="100px"
          height="100px"
        />
      )}
      <div className="flex w-full">
        <div className="event-service-head w-9">
          <div className="event-service-headtop">
            <h3>{name}</h3>
          </div>
          {address?.length > 0 && (
            <div>
              <LocationOnOutlinedIcon className="plicon" />
              {address}
            </div>
          )}

          <div className="bottom align-items-center">
            {!isNaN(distance) && (
              <>
                <RouteOutlinedIcon className="plicon" />
                {`${(distance / 1000).toFixed(1)} km`}
              </>
            )}{" "}
            {mobile && (
              <>
                <PhoneAndroidOutlinedIcon className="plicon" />
                {mobile}
              </>
            )}
          </div>
          <div className="align-items-center">
            {email && (
              <>
                <EmailOutlinedIcon className="plicon" />
                {email}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-column align-items-end w-3">
          {!isNaN(discount) &&
          discount > 0 &&
          new Date().getTime() < new Date(validUpto).getTime() ? (
            <>
              <span className="line-through text-sm">₹{price}</span>
              <span className="text-xl font-bold">₹{price - discount}</span>
            </>
          ) : (
            !isNaN(price) && <span className="text-xl font-bold">₹{price}</span>
          )}
        </div>
      </div>

      {/* {hasValidServices ? (
    <div className="event-providerservcies full">
      <div
        key={item.services[0].serviceMaster.id}
        className={`event-service alter ${
          cart &&
          cart.length > 0 &&
          checkForAdd(
            `${
              item.services[0].service_master_id
            }_${
              item.services[0].service_provider_id
            }_${
              eventDetails &&
              Object.keys(eventDetails).length > 0
                ? eventDetails.id
                : 0
            }`
          ) &&
          "sel-event"
        }`}
      >
        <div className="event-service-headtop">
          <div
            style={{ margin: 0, lineHeight: 1 }}
            className="desc"
          >
            <small>Service:</small>
          </div>
          <h3>
            {
              item.services[0].serviceMaster
                .serviceName
            }
          </h3>
          {new Date(
            item.services[0].discountValidUpto
          ) > new Date() &&
          item.services[0].discountPrice ? (
            <div className="price">
              <s>&#8377;{item.services[0].price}</s>
              <br />
              <strong>
                &#8377;
                {item.services[0].discountPrice}
              </strong>
              <span
                style={{
                  color: "red",
                  fontSize: "20px",
                }}
              >
                *
              </span>
            </div>
          ) : (
            <div className="price">
              <strong>
                &#8377;{item.services[0].price}
              </strong>
            </div>
          )}
          {new Date(
            item.services[0].discountValidUpto
          ) > new Date() && (
            <div className="validupto">
              <span
                style={{
                  color: "red",
                  fontSize: "20px",
                }}
              >
                *
              </span>{" "}
              Valid upto{" "}
              {format(
                new Date(
                  item.services[0].discountValidUpto
                ),
                "MMMM dd, yyyy"
              )}
            </div>
          )}
        </div>
        <div className="event-service-middle">
          {cart &&
          cart.length > 0 &&
          checkForAdd(
            `${
              item.services[0].service_master_id
            }_${
              item.services[0].service_provider_id
            }_${
              eventDetails &&
              Object.keys(eventDetails).length > 0
                ? eventDetails.id
                : 0
            }`
          ) ? (
            <Button
              icon="pi pi-minus"
              className="custom-button cancel"
              label="Remove Service"
              size="small"
              style={{ width: "100%" }}
              onClick={() =>
                removeService({
                  itemID: `${
                    item.services[0]
                      .service_master_id
                  }_${
                    item.services[0]
                      .service_provider_id
                  }_${
                    eventDetails &&
                    Object.keys(eventDetails)
                      .length > 0
                      ? eventDetails.id
                      : 0
                  }`,
                })
              }
            />
          ) : (
            <Button
              icon="pi pi-plus"
              className="custom-button"
              label="Add Service"
              size="small"
              style={{ width: "100%" }}
              onClick={() =>
                addToService({
                  itemID: `${
                    item.services[0]
                      .service_master_id
                  }_${
                    item.services[0]
                      .service_provider_id
                  }_${
                    eventDetails &&
                    Object.keys(eventDetails)
                      .length > 0
                      ? eventDetails.id
                      : 0
                  }`,
                  service: `${item.services[0].serviceMaster.serviceName}`,
                  provider: `${item.firstName} ${item.lastName}`,
                  event: `${
                    eventDetails &&
                    Object.keys(eventDetails)
                      .length > 0
                      ? eventDetails.eventTitle
                      : null
                  }`,
                  amount:
                    new Date(
                      item.services[0].discountValidUpto
                    ) > new Date() &&
                    item.services[0].discountPrice
                      ? item.services[0]
                          .discountPrice
                      : item.services[0].price,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="pi pi-chevron-right"></div>
  )} */}
    </div>
  );
}

export default ProviderListingCard;
