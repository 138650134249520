import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { homePagePath } from "../../constant";

const BackToHome = () => {
  const navigate = useNavigate();
  return (
    <div
      className="back-to-home-container"
      style={{ textAlign: "center", marginTop: "15px" }}
    >
      <Button
        label="Back to Home"
        icon="pi pi-home"
        className="custom-button cancel"
        iconPos="right"
        onClick={() => navigate(homePagePath)}
        size="small"
      />
    </div>
  );
};

export default BackToHome;
