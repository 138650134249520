import React, { useEffect, useRef, useState } from "react";
import { CssBaseline, Container, Stack, Classes, Typography, Card, CardContent, AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, CardMedia, TextField, Box, Paper, Divider } from '@mui/material';
import { useLocation } from "react-router-dom";
import { getGuestListWithResponse } from "../../services/RSVPServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button} from "primereact/button"
import ResponsiveAppBar from '../appBarcomponent/ResponsiveAppbar';
import Footer from "../Footer";

const GuestReponseList = () => {
  const location = useLocation();
  const { shareToken } = location.state || {}; // Get share token from state
  const [guestList, setGuestList] = useState([]); // Fixed variable name from 'gusetList' to 'guestList'
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dt = useRef(null);
  // Fetch the guest list when shareToken is available
  useEffect(() => {
    const fetchGuestList = async () => {
      if (shareToken) {
        try {
          const response = await getGuestListWithResponse({ shareToken });
          console.log("Guest List Response:", response);
          setGuestList(response); // Assuming response is the guest list array
        } catch (err) {
          console.error("Error fetching guest list:", err);
          setError("Failed to fetch guest list.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchGuestList();
  }, [shareToken]);

  const exportCSV = () => {
    dt.current.exportCSV(); // Use the exportCSV method of DataTable
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ width: '100%' }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"dashboard"} />
      </Stack>
      <div className="main-container">
        <div className="frontend-container">
          <div style={{ width: '100%' }}>
            {guestList.length === 0 ? (
              <p>No guests found for this event.</p>
            ) : (
              <div>
                <Button
                  label="Export to CSV"
                  icon="pi pi-file"
                  onClick={exportCSV}
                  style={{ marginBottom: "1rem" }}
                />
                <DataTable
                  ref={dt}
                  value={guestList}
                  paginator
                  rows={10}
                  stripedRows
                  exportFilename="guest_list"
                >
                  <Column field="name" header="Name" sortable />
                  <Column field="phoneNumber" header="Phone Number" sortable />
                  <Column field="membersCount" header="Members Count" sortable />
                  <Column
                    field="isComing"
                    header="Status"
                    body={(rowData) => (rowData.isComing ? "Attending" : "Not Attending")}
                    sortable
                  />
                  <Column field="remarks" header="Remarks" sortable />
                </DataTable>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
    </div>
  );
};

export default GuestReponseList;
