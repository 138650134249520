import axiosInstance from "../../../util/axiosInstance"; // Make sure to import your axios instance

export const fetchEvent = async () => {
  try {
    const url = `/my-events`;
    const response = await axiosInstance.get(url);
    const { upcomingEvents, pastEvents, cancelledEvents } = response.data || [];
    //console.log("events data:", upcomingEvents, pastEvents);
    return { upcomingEvents, pastEvents, cancelledEvents };
  } catch (ex) {
    console.log("failed to load event:", ex.message);
    return [];
  }
};

export const deleteEvent = async (eventID) => {
  try {
    const url = `/my-events/${eventID}`;
    const response = await axiosInstance.delete(url);
    return response.data || [];
  } catch (ex) {
    console.log("failed to load event:", ex.message);
    return [];
  }
};
