import config from "../../../config";
import axiosInstance from "../../../util/axiosInstance";

import { getToken } from "../../../util/Cookies";

export const fetchProviderData = async () => {
  try {
    const response = await axiosInstance.get("/provider-types", {
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getServiceProviderServices = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/services", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getCateringProviderServices = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/cuisine/meals-type", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getCateringProviderCuisines = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/cuisine/cuisine-types", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to get the provider cuisines"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getCateringProviderCategories = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/cuisine/categories", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to get the provider categories"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const addCateringProviderServices = async (cateringServices) => {
  const token = getToken();

  try {
    const response = await axiosInstance.post(
      "/catering-items",
      cateringServices,
      {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const updateCateringProviderServices = async (cateringServices) => {
  const token = getToken();

  try {
    const response = await axiosInstance.put(
      "/catering-items",
      cateringServices,
      {
        method: "PUT",
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const deleteCateringProviderServices = async (cateringServiceItems) => {
  const token = getToken();

  try {
    const response = await axiosInstance.delete("/catering-items", {
      method: "DELETE",
      headers: {
        Authorization: `${token}`,
      },
      data: cateringServiceItems,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getCateringProviderAddedServices = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/catering-items", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to get already added catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const addCateringProviderMenuImage = async (formData) => {
  console.log("FormData", formData);

  const token = getToken();

  try {
    const response = await axiosInstance.post("/gallery/upload", formData, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getPriestProviderEvents = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/priest/mappedEvents", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response?.data?.data || [];
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getPriestEvents = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/master/priestEventMaster", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response?.data?.data || [];
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getServiceProvidersByID = async (Id, Lat, Long, eventId) => {
  try {
    let url = `/provider?typeId=${Id}&latitude=${Lat}&longitude=${Long}&order=asc`;
    if (eventId != null) {
      url += `&eventTypeId=${eventId}`;
    }
    const response = await axiosInstance.get(url, {
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getPriestsListByLocation = async (Lat, Long, eventId) => {
  try {
    let url = `/priest/list?eventId=${eventId}&latitude=${Lat}&longitude=${Long}&order=desc`;
    const response = await axiosInstance.get(url, {
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getTentHouseListByLocation = async (Lat, Long, eventId) => {
  try {
    let url = `/tent-house/list?eventId=${eventId}&latitude=${Lat}&longitude=${Long}&order=asc`;
    const response = await axiosInstance.get(url, {
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getCateringListByLocation = async (Lat, Long, eventId) => {
  try {
    let url = `/caterer/list?eventId=${eventId}&latitude=${Lat}&longitude=${Long}&order=asc`;
    const response = await axiosInstance.get(url, {
      method: "GET",
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getProviderServicesByID = async (Id) => {
  try {
    const response = await axiosInstance.get(
      `/provider/services?providerId=${Id}`,
      {
        method: "GET",
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getTentHouseProviderServices = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/tent-house/services", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const getTentHouseProviderAddedServices = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get("/tent-house/mappings", {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to get the provider details");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const deleteTentHouseProviderServices = async (
  tentHouseServiceItems
) => {
  const token = getToken();

  try {
    const response = await axiosInstance.delete("/tent-house/mappings", {
      method: "DELETE",
      headers: {
        Authorization: `${token}`,
      },
      data: tentHouseServiceItems,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const updateTentHouseProviderServices = async (tentHouseServices) => {
  const token = getToken();

  try {
    const response = await axiosInstance.put(
      "/tent-house/mappings",
      tentHouseServices,
      {
        method: "PUT",
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to update the tent house services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const addTentHouseProviderServices = async (tentHouseServices) => {
  const token = getToken();

  try {
    const response = await axiosInstance.post(
      "/tent-house/mappings",
      tentHouseServices,
      {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(
        response.message || "Unable to add the catering services"
      );
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const syncTentHouseProviderServices = async (tentHouseServices) => {
  const token = getToken();
  try {
    const response = await axiosInstance.post(
      "/tent-house/sync-mappings",
      { services: tentHouseServices },
      {
        method: "POST",
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to sync tent house services");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export const savePriestEvents = async (updatedData) => {
  const API_URL = "/priest/sync-priest-mappings"; // Adjust the endpoint as needed

  const token = getToken();
  try {
    const response = await axiosInstance.post(API_URL, {
      mappings: updatedData || [],
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || "Unable to sync proist services");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};
