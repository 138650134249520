import React from "react";
import "../css/AccountDialog.css";
import { Link } from 'react-router-dom'
import Services from "../ProviderServices.json"

function ServicesDialog({ isOpen, onClose }) {
    if (!isOpen) return null; // If dialog is not open, don't render
    return (
        <div className="account-dialog-overlay services-dialog">
            <div className="account-dialog-content">
                <div className="account-details">
                    {Services.length > 0 && Services.map((item) => <Link key={item.id} className="account-d" to={`/service/${item.slug}`}>{item.serviceCardName}</Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ServicesDialog