import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ServiceProviderLogin.css";
import {
  loginWithOTP,
  loginWithPassword,
  postPendingEvent,
  sendLoginOTP,
} from "../../services/ServiceProviderAuthServices";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { handlePendingEvent, setCredentials } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import BackToHome from "../BacktoHomeComponent/BackToHome";
import { homePagePath } from "../../constant";

const ServiceProviderLogin = () => {
  const [usingOTP, setUsingOTP] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpSentTime, setOtpSentTime] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneRegExp = /^[6-9]\d{9}$/;
  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const toast = useRef(null); // Create a ref for Toast

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showErrorDialogVisible, setShowErrorDialogVisible] = useState(false);
  const [errorDialogCont, setErrorDialogCont] = useState({});

  useEffect(() => {}, []);
  const handleToggle = () => {
    formik.setFieldValue("password", ""); // Clear the password field
    formik.setFieldValue("otp", ""); // Clear the OTP field
    if (usingOTP) {
      formik.setErrors({
        identifier: formik.errors.identifier, // Keep identifier error if any
        password: undefined, // Clear password error
        otp: undefined, // Clear OTP error
      });
      formik.setFieldTouched("otp", false); // Reset touched for OTP field
    } else {
      formik.setErrors({
        identifier: formik.errors.identifier, // Keep identifier error if any
        password: undefined, // Clear password error
        otp: undefined, // Clear OTP error
      });
      formik.setFieldTouched("password", false); // Reset touched for Password field
    }

    console.log("Value and error removed");
    setUsingOTP((prevUsingOTP) => !prevUsingOTP);
    setOtpSent(false); // Reset OTP state when toggling between login methods
    setOtpSentTime(null);
  };

  const showMessage = (detail, summary = "Success") => {
    toast.current.show({
      severity: "success",
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const showError = (detail, summary = "Error") => {
    toast.current.show({
      severity: "error",
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const showErrorDialog = (msg) => {
    setShowErrorDialogVisible(true);
    switch (msg) {
      case "User not found.":
        setErrorDialogCont({
          content: "User not found. Please signup",
          btn: true,
        });
        break;
      default:
        setErrorDialogCont({ content: msg, btn: false });
    }
  };

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
      otp: "",
    },
    validationSchema: Yup.object({
      identifier: Yup.string()
        .required("Mobile Number is required")
        .test(
          "is-valid-phone-or-email",
          "Invalid mobile number",
          function (value) {
            if (!value) return false; // Empty check is already handled by .required()
            const isValidPhone = phoneRegExp.test(value);
            console.log(isValidPhone);
            return isValidPhone;
          }
        ),
      password: Yup.lazy(() => {
        return !usingOTP
          ? Yup.string()
              .required("Password is required")
              .min(6, "Password must be at least 6 characters")
          : Yup.string().notRequired(); // This ensures that no validation is done on password when using OTP
      }),
      otp:
        usingOTP && otpSent
          ? Yup.string()
              .required("OTP is required")
              .matches(/^[a-zA-Z0-9]{6}$/, "OTP must be 6 characters")
          : null,
    }),
    onSubmit: async (values) => {
      try {
        if (!usingOTP) {
          // Password
          const data = await loginWithPassword(
            values.identifier,
            values.password
          );
          console.log("data =>");
          console.log(data);
          // localStorage.setItem("authToken", data.token);
          // await postEvent();
          console.log("Login With PAssword", data);
          //showMessage(data.message);

          handlePostlogin(data);
          navigate("/service-provider/dashboard");
        } else if (!otpSent) {
          // Send OTP
          await handleResendOTP(values.identifier);
        } else {
          const data = await loginWithOTP(values.identifier, values.otp);
          console.log("data =>");
          console.log(data);
          // localStorage.setItem("authToken", data.token);
          //showMessage(data.message);
          console.log("Login With OTP", data);
          // await postEvent();
          handlePostlogin(data);
          // dispatch(setCredentials(data));
          // alert(data.message);
          navigate("/service-provider/dashboard");
        }
      } catch (error) {
        showErrorDialog(error.message || "");
        //showError(error.message || "Something Went Wrong");
        // alert(error.message || "An error occurred.");
      }
    },
  });

  const handlePostlogin = async (data) => {
    dispatch(setCredentials(data));
    dispatch(handlePendingEvent());
  };

  const handleResendOTP = async (identifier) => {
    const data = await sendLoginOTP(identifier);
    const { message, detail } = data;
    setOtpSent(true);
    setOtpSentTime(new Date().toLocaleTimeString());
    // alert(detail);
    //showMessage(detail);
  };

  return (
    <div className="auth-screens-bg">
      {/* <Toast ref={toast} /> */}
      <Dialog
        className="auth-dialog"
        visible={showErrorDialogVisible}
        modal
        header={"Error"}
        footer={
          errorDialogCont.btn ? (
            <Button
              size="small"
              label="Signup"
              onClick={() => navigate("/service-provider/signup")}
            />
          ) : (
            <Button
              size="small"
              label="OK"
              onClick={() => setShowErrorDialogVisible(false)}
            />
          )
        }
        onHide={() => {
          if (!showErrorDialogVisible) return;
          setShowErrorDialogVisible(false);
        }}
      >
        <p style={{ fontWeight: "500", margin: "0" }}>
          {errorDialogCont.content}
        </p>
      </Dialog>
      <div className="login-container">
        <div>
          <div>
            {/* <h2>Login</h2> */}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/thurent.png`}
              alt="Logo"
              className="auth-logo"
              onClick={() => {
                navigate(homePagePath);
              }}
            />
            <form onSubmit={formik.handleSubmit}>
              {/* Phone Number/Email field */}
              <input
                type="text"
                name="identifier"
                maxLength="10"
                placeholder="Mobile Number"
                disabled={otpSent && usingOTP} // Disable this field after OTP is sent
                value={formik.values.identifier}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="login-input"
              />
              {formik.touched.identifier && formik.errors.identifier ? (
                <div className="error">{formik.errors.identifier}</div>
              ) : null}

              {/* Password field - only show when not using OTP */}
              {!usingOTP && (
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="login-input"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ position: "absolute", right: "14px", top: "5px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              )}

              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}

              {/* OTP field - always show when using OTP */}
              {usingOTP && (
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="login-input"
                  name="otp"
                  disabled={!otpSent} // Disable until OTP is sent
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              )}
              {formik.touched.otp && formik.errors.otp ? (
                <div className="error">{formik.errors.otp}</div>
              ) : null}

              {/* Buttons & Links */}
              <div
                className={`login-options ${
                  usingOTP && !otpSent ? "single" : ""
                }`}
              >
                {/* Forgot Password */}
                {!usingOTP && (
                  <Link
                    to="/service-provider/forgot-password"
                    className="text-button"
                  >
                    Forgot Password?
                  </Link>
                )}

                {/* Resend OTP */}
                {usingOTP && otpSent && (
                  <button
                    type="button"
                    onClick={() => handleResendOTP(formik.values.identifier)}
                    className="text-button"
                  >
                    Resend OTP
                  </button>
                )}

                {/* Toggle between Use OTP and Use Password */}
                <button
                  type="button"
                  onClick={handleToggle}
                  className="text-button"
                >
                  {usingOTP ? "Use Password" : "Use OTP"}
                </button>
              </div>

              {/* Conditional Buttons */}
              {!otpSent && usingOTP && (
                <button type="submit" className="login-button">
                  Send OTP
                </button>
              )}
              {otpSent && usingOTP && (
                <button type="submit" className="login-button">
                  Submit OTP
                </button>
              )}

              {!usingOTP && (
                <button type="submit" className="login-button">
                  LOGIN
                </button>
              )}
            </form>
          </div>

          <button
            className="login-button-secondary"
            onClick={() => navigate("/service-provider/signup")}
          >
            New User? SIGNUP{" "}
          </button>
          <div
            style={{
              fontSize: "0.95rem",
              fontWeight: "500",
              marginTop: "15px",
              textAlign: "left",
            }}
          >
            By creating an account or logging in, you agree to Thurent’s{" "}
            <Link
              to="/privacy-policy"
              style={{ color: "#FF895D", margin: "0 4px" }}
            >
              Privacy Policy
            </Link>
            .
          </div>
          <BackToHome></BackToHome>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderLogin;
