import React, { useCallback, useEffect, useState } from "react";
import {
  CssBaseline,
  Stack,
  TextField,
  MenuItem,
  IconButton,
  Alert,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import { format } from "date-fns";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  fetchchatGptData,
  fetchchatGptHowTo,
} from "../../Apiservices/Auth/servers/chatGptServer";
import {
  getServiceProvidersByID,
  fetchProviderData,
  getPriestsListByLocation,
  getTentHouseListByLocation,
  getCateringListByLocation,
} from "../../Apiservices/Auth/servers/fetchProviderData";
import { fetchReligionCaste } from "../../Apiservices/Auth/servers/fetchReligionCaste";
import { fetchLanguages } from "../../Apiservices/Auth/servers/fetchLanguages";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import LoadMap from "./LoadMap";
import config from "../../config";
import { capitalizeFirstLetter } from "../../util/typeCasting";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../store/cartSlice";
import providerData from "../../ProviderServices.json";
//import ReligionAndCaste from "../../ReligionAndCaste.json";
import ButtonMailto from "../ButtonMailto";
import CreateEventCTA from "./CreateEventCTA";
import MyButton1 from "../CustomComponents/MyButton1";
import ProviderListingCard from "../ServiceProvider/ProviderListingCard";
import { Accordion, AccordionTab } from "primereact/accordion";
import Services from "../../ProviderServices.json";
import { fetchStates } from "../../Apiservices/Auth/servers/fetchStates";

function EventServices() {
  const cart = useSelector((state) => state.cart.serviceList);
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();

  const checkForAdd = (itemId) => {
    const found = cart.find((opt) => opt.itemID === itemId);
    return found;
  };

  const addToService = (opt) => {
    dispatch(addToCart(opt));
  };

  const removeService = (opt) => {
    dispatch(removeFromCart(opt));
  };

  const [loading, setLoading] = useState(true);
  const [providerListLoading, setProvideListLoading] = useState(false);
  const [castes, setCastes] = useState([]);
  //const [providerData, setProviderData] = useState([]);
  const [providerInfo, setProviderInfo] = useState([]);
  const [ReligionAndCaste, setReligionAndCaste] = useState({});
  const [states, setStates] = useState([]);
  const [languages, setLanguages] = useState([]);

  const location = useLocation();
  const { serviceDetails, eventDetails } = location.state || {}; // Get event details

  console.log(eventDetails);

  const {
    id,
    serviceName,
    serviceCardName,
    serviceDescription,
    serviceTitle,
    serviceSubTitle,
  } = serviceDetails;
  const { eventTitle, locationLat, locationLong, eventId } = eventDetails;

  const [eventStateTitle, setEventStateTitle] = useState(
    eventTitle ? eventTitle : ""
  );
  const [AIdialogVisible, setAIdialogvisible] = useState(false);
  const [howTo, setHowTO] = useState("");
  const [context, setcontext] = useState("");

  const navigate = useNavigate();

  const backtoEvents = () => {
    navigate("/dashboard");
  };

  const providerListStorageKey = config.providerListStorageKey;
  const providerList = localStorage.getItem(providerListStorageKey);

  if (providerList) {
    localStorage.removeItem(providerListStorageKey);
  }

  const fetchReligionCasteData = useCallback(async () => {
    setLoading(true);
    try {
      const ReligionCastedata = await fetchReligionCaste();
      setReligionAndCaste(ReligionCastedata);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchLanguagesData = useCallback(async () => {
    setLoading(true);
    try {
      const LanguagesData = await fetchLanguages();
      setLanguages(LanguagesData);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchStatesData = useCallback(async () => {
    setLoading(true);
    try {
      const states = await fetchStates();
      setStates(states);
    } catch (error) {
      console.error("Failed to fetch states:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchProviderList = useCallback(async () => {
    setProvideListLoading(true);
    try {
      let provider_data;

      switch (id) {
        case 7:
          provider_data = await getPriestsListByLocation(
            locationLat,
            locationLong,
            eventId
          );
          break;
        case 8:
          provider_data = await getTentHouseListByLocation(
            locationLat,
            locationLong,
            eventId
          );
          break;
        case 9:
          provider_data = await getCateringListByLocation(
            locationLat,
            locationLong,
            eventId
          );
          break;
      }
      setProviderInfo(provider_data.data);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setProvideListLoading(false);
    }
  }, [id]);

  useEffect(() => {
    //fetchProviders();
    fetchReligionCasteData();
    fetchStatesData();
    fetchLanguagesData();
    id !== 1 && fetchProviderList();
  }, [id]);

  console.log("providerInfo", providerInfo);

  console.log("ReligionAndCaste", ReligionAndCaste);

  const handleEventService = (service) => {
    const info = {
      serviceDetails: service,
      eventDetails:
        eventDetails && Object.keys(eventDetails).length > 0
          ? eventDetails
          : {},
    };
    service.id === 2
      ? navigate("/dashboard", { state: info })
      : navigate("/providers", { state: info });
    window.scrollTo({ top: 0 });
  };

  const handleProviderServcies = (provider) => {
    const info = {
      serviceDetails: serviceDetails,
      providerDetails: provider,
      eventDetails: eventDetails,
    };
    navigate("/provider/services", { state: info });
    window.scrollTo({ top: 0 });
  };

  function removeBlankAttributes(obj) {
    const result = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  useEffect(() => {
    id === 1 && howTo === "" && setAIdialogvisible(true);
    setLoading(false);
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Religion: "",
      Caste: "",
      SubCaste: "",
      Language: "",
      Location: "",
      event: eventStateTitle,
    },
    onSubmit: async (values, { resetForm }) => {
      const altered = removeBlankAttributes(values);

      const question = `
      Please give me a detailed checklist for event arrangements to help plan and organize the ${altered?.event} ceremony in ${altered?.Location} as per ${altered?.Religion} ${altered?.Caste} with ${altered?.SubCaste} subcaste, speaking ${altered?.Language} traditions.
      Return the JSON response as below by filling the details for each keys except the keys starting with a number.

      {
  "Pre – Event Planning (Before the Ceremony)": {
    "a) Consult a Purohit (Priest) to finalize the Shubha Muhurta (auspicious date & time)": "Enter description based on the key and event details",
    "b) Decide the Venue – Home, temple, or community hall": "Enter description based on the key and event details",
    "c) Prepare the Guest List – Close family, friends, and community members": "Enter description based on the key and event details",
    "d) Send Invitations – Digital or printed invites in [language] if preferred": "Enter description based on the key and event details",
    "Book Services": {
      "• Purohit & Assistants for puja and homa": "Enter description based on the key and event details",
      "• Caterer for traditional [local cuisine] food": "Enter description based on the key and event details",
      "• Decorators for flowers, mandap, and rangoli": "Enter description based on the key and event details",
      "• Photographer/Videographer to document the rituals": "Enter description based on the key and event details"
    },
    "f) Arrange Accommodation for outstation guests if required": "Enter description based on the key and event details"
  },
  "Puja Samagri (Puja Materials)": {
    "a) Idols & Photos – [specific deities for the event]": "Enter description based on the key and event details",
    "b) Puja Kalash (Sacred Pot) – [material options]": "Enter description based on the key and event details",
    "c) Flowers & Garlands – [flowers specific to the culture]": "Enter description based on the key and event details",
    "d) Coconut with husk – For Kalasha Puja": "Enter description based on the key and event details",
    "e) Betel leaves & nuts (Paan & Supari) – At least 20 pairs": "Enter description based on the key and event details",
    "f) Fruits – [specific fruits used in the culture]": "Enter description based on the key and event details",
    "g) Panchamrit – [ingredients specific to the culture]": "Enter description based on the key and event details",
    "h) Sandalwood Paste & Kumkum – For tilak & puja rituals": "Enter description based on the key and event details",
    "i) Akshata (Unbroken Rice) – Required for all pujas": "Enter description based on the key and event details",
    "j) Incense sticks, camphor, and dhoop – For aarti": "Enter description based on the key and event details",
    "k) Ghee & Oil Lamps (Deepam) – Minimum 2 brass or silver lamps": "Enter description based on the key and event details",
    "l) Mango Leaves – For Kalash decoration": "Enter description based on the key and event details",
    "m) Sacred Thread (Yagnopaveetam) – For the birthday person": "Enter description based on the key and event details",
    "n) Holy Water – [specific holy water if applicable]": "Enter description based on the key and event details",
    "o) Rice, wheat, pulses, and clothes – For Tula Daan (weighing donation)": "Enter description based on the key and event details",
    "p) Silver or Gold Ornaments – If donating during Tula Daan": "Enter description based on the key and event details"
  },
  "Rituals & Homas Setup": {
    "a) Ganapati Puja – To remove obstacles": "Enter description based on the key and event details",
    "b) Punyaha Vachanam – Purification ritual with sacred water": "Enter description based on the key and event details",
    "c) Navagraha Homa – To balance planetary influences": "Enter description based on the key and event details",
    "d) Mrityunjaya Homa – For longevity and protection": "Enter description based on the key and event details",
    "e) Ayushya Homa – For good health": "Enter description based on the key and event details",
    "f) Dampati Puja – Couple honored as divine representations": "Enter description based on the key and event details",
    "g) Mangalya Dharana – Renewal of marital vows (husband ties new Mangalsutra to wife)": "Enter description based on the key and event details",
    "h) Abhishekam – Sacred bath for the birthday person with Panchamrit": "Enter description based on the key and event details",
    "i) Tula Daan – Charity based on body weight (Rice, clothes, silver, or grains)": "Enter description based on the key and event details",
    "j) Brahmana Bhojan – Feeding of Brahmins & guests": "Enter description based on the key and event details",
    "k) Dakshina & Donations – Offering money, clothes, or food to Brahmins & the needy": "Enter description based on the key and event details",
  },
  "Attire & Gifts": {
    "a) New attire for the birthday person (e.g., Dhoti, Saree, etc.)": "Enter description based on the key and event details",
    "b) Special gifts for the couple or birthday person": "Enter description based on the key and event details",
    "c) Flower Garland (Mala) – For the couple during Dampati Puja": "Enter description based on the key and event details",
    "d) Vastra Daan (Clothing Donation) – New clothes for Brahmins and the poor": "Enter description based on the key and event details"
  },
  "Food & Prasadam Arrangement": {
    "a) Special Prasadam for Puja – [specific traditional food items]": "Enter description based on the key and event details",
    "Regional Cuisine Menu": {
      "• [List of traditional dishes specific to the culture]": "Enter description based on the key and event details"
    },
    "c) Annadanam (Charity Food Distribution) – Arrange food for the needy & temple visitors": "Enter description based on the key and event details"
  },
  "Additional Arrangements": {
    "a) Photographer/Videographer – To capture the rituals": "Enter description based on the key and event details",
    "b) Live Streaming (if needed) – For family members who can't attend": "Enter description based on the key and event details",
    "c) Return Gifts (Guest Thank You Gifts) – [specific cultural gift ideas]": "Enter description based on the key and event details",
    "d) Post-Event Visit to [temple/holy place] (Optional)": "Enter description based on the key and event details"
  },
  "Post – Event Traditions": {
    "a) Seek Blessings from Elders & Priests": "Enter description based on the key and event details",
    "b) Distribute Prasadam to guests & neighbors": "Enter description based on the key and event details",
    "c) Perform [specific Puja or ritual] (if not done during the event)": "Enter description based on the key and event details",
    "d) Pilgrimage to [holy places] (if possible)": "Enter description based on the key and event details"
  },
  "Optional: Special Event Additions": {
    "a) Cultural Program – [local music, dance, or performance]": "Enter description based on the key and event details",
    "b) Family Video Presentation – Showcasing the life journey of the birthday person": "Enter description based on the key and event details",
    "c) Speech by Family Members – Expressing gratitude & sharing memories": "Enter description based on the key and event details"
  }
}  
  
I want you to fill the details based on the prompt details in "Enter description based on the key and event details" field.
Also, consider altering the keys if any religion/belief might get hurted. Such as temple should be replaced with gurudwara, mosque same goes with other religions and beliefs.
      `;
      // Also, I want you to fill a relevant emoji for each value replaced instead on "Enter description based on the key and event details" field at the start.
      try {
        setLoading(true);
        setAIdialogvisible(false);
        const apiResponse = await fetchchatGptHowTo({
          question: question,
        });
        if (eventDetails.eventMasterDetails.is_priest_required) {
          setcontext(
            `Just as Grandma used to say, here are the essential steps followed for ${values.event}:`
          );
        }
        setHowTO(JSON.parse(apiResponse["answer"]));
      } catch (error) {
        console.error("Failed to services:", error);
      } finally {
        setLoading(false);
        resetForm();
      }
    },
  });

  useEffect(() => {
    if (ReligionAndCaste?.religion?.length > 0) {
      const hinduReligion = { name: "Hindu" };
      handleReligionFieldChange("Religion", hinduReligion);
    }
  }, [ReligionAndCaste]);

  // useEffect(() => {
  //   if (languages?.length > 0) {
  //     formik.setFieldValue("Language", "Telugu");
  //   }
  // }, [languages]);

  useEffect(() => {
    if (eventDetails?.state) {
      formik.setFieldValue("Location", eventDetails?.state);
    }
  }, [eventDetails]);

  const textFieldStyles = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "4px",
    marginBottom: "0",
  };

  const setCasteByReligion = (value) => {
    const result = ReligionAndCaste.religion.find((obj) => obj.name === value);
    setCastes(result.castes);
  };

  const handleReligionChange = (field) => (event) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
    setCasteByReligion(value);
  };

  const handleReligionFieldChange = (field, value) => {
    formik.setFieldValue(field, value.name);
    setCasteByReligion(value.name);
  };

  const handleCasteFieldChange = (field, value) => {
    formik.setFieldValue(field, value.name);
  };

  const handleFieldChange = (field) => (event) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
  };

  const handleAIenabledService = () => {
    setAIdialogvisible(true);
  };

  const renderProviderListById = (item, id) => {
    if (id === 7) {
      return (
        <ProviderListingCard
          key={item.id}
          latitude={item.latitude}
          longitude={item.longitude}
          name={`${item.firstName} ${item.lastName}`}
          address={item.formattedAddress}
          distance={item.distanceInMeter}
          mobile={item.mobile}
          email={item.email}
          price={item.price}
          discount={item.discount}
          validUpto={item.discountValidUpto}
        />
      );
    } else if (id === 8) {
      return (
        <ProviderListingCard
          key={item.id}
          latitude={item.latitude}
          longitude={item.longitude}
          name={item.name}
          address={item.formattedAddress}
          distance={item.distanceInMeter}
          mobile={item.mobile}
          email={item.emailId}
          price={item.price}
          discount={item.discount}
        />
      );
    } else if (id === 9) {
      return (
        <ProviderListingCard
          key={item.id}
          latitude={item.latitude}
          longitude={item.longitude}
          name={item.name}
          address={item.formattedAddress}
          distance={item.distanceInMeter}
          mobile={item.mobile}
          email={item.email}
          price={item.price}
          discount={item.discount}
        />
      );
    }
  };

  const renderButtonOnCase = (sentence) => {
    if (
      sentence?.toLowerCase()?.includes("purohit") ||
      sentence?.toLowerCase()?.includes("consult") ||
      sentence?.toLowerCase()?.includes("priest") ||
      sentence?.toLowerCase()?.includes("pooja") ||
      sentence?.toLowerCase()?.includes("puja") ||
      sentence?.toLowerCase()?.includes("homa")
    ) {
      const priestData = providerData?.find((data) => data.type === "Priest");
      return (
        <div
          className="event-service-head"
          style={{
            width: "max-content",
            padding: "5px",
            margin: "10px",
            cursor: "pointer",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={() => handleEventService(priestData)}
        >
          <div>
            <img
              style={{
                display: "block",
                width: "30px",
              }}
              src={`${process.env.PUBLIC_URL}/assets/images/${priestData.id}.png`}
              alt={priestData.serviceCardName}
            />
            <div>
              <h3>{priestData.serviceCardName}</h3>
            </div>
          </div>
          <div className="pi pi-chevron-right"></div>
        </div>
      );
    } else if (
      sentence?.toLowerCase()?.includes("invite") ||
      sentence?.toLowerCase()?.includes("invitation") ||
      sentence?.toLowerCase()?.includes("invitations")
    ) {
      const inviteData = providerData?.find((data) => data.type === "Invite");
      return (
        <div
          className="event-service-head"
          style={{
            width: "max-content",
            padding: "5px",
            margin: "10px",
            cursor: "pointer",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={() => handleEventService(inviteData)}
        >
          <div>
            <img
              style={{
                display: "block",
                width: "30px",
              }}
              src={`${process.env.PUBLIC_URL}/assets/images/${inviteData.id}.png`}
              alt={inviteData.serviceCardName}
            />
            <div>
              <h3>{inviteData.serviceCardName}</h3>
            </div>
          </div>
          <div className="pi pi-chevron-right"></div>
        </div>
      );
    } else if (
      sentence?.toLowerCase()?.includes("prasad") ||
      sentence?.toLowerCase()?.includes("prasadam") ||
      sentence?.toLowerCase()?.includes("food") ||
      sentence?.toLowerCase()?.includes("caterer") ||
      sentence?.toLowerCase()?.includes("catering") ||
      sentence?.toLowerCase()?.includes("cuisine") ||
      sentence?.toLowerCase()?.includes("veg") ||
      sentence?.toLowerCase()?.includes("non-veg")
    ) {
      const cateringData = providerData?.find(
        (data) => data.type === "Catering"
      );
      return (
        <div
          className="event-service-head"
          style={{
            width: "max-content",
            padding: "5px",
            margin: "10px",
            cursor: "pointer",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={() => handleEventService(cateringData)}
        >
          <div>
            <img
              style={{
                display: "block",
                width: "30px",
              }}
              src={`${process.env.PUBLIC_URL}/assets/images/${cateringData.id}.png`}
              alt={cateringData.serviceCardName}
            />
            <div>
              <h3>{cateringData.serviceCardName}</h3>
            </div>
          </div>
          <div className="pi pi-chevron-right"></div>
        </div>
      );
    } else if (
      sentence?.toLowerCase()?.includes("cultural") ||
      sentence?.toLowerCase()?.includes("program") ||
      sentence?.toLowerCase()?.includes("event")
    ) {
      const rentalsData = providerData?.find((data) => data.type === "Rentals");
      return (
        <div
          className="event-service-head"
          style={{
            width: "max-content",
            padding: "5px",
            margin: "10px",
            cursor: "pointer",
            borderRadius: "10px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={() => handleEventService(rentalsData)}
        >
          <div>
            <img
              style={{
                display: "block",
                width: "30px",
              }}
              src={`${process.env.PUBLIC_URL}/assets/images/${rentalsData.id}.png`}
              alt={rentalsData.serviceCardName}
            />
            <div>
              <h3>{rentalsData.serviceCardName}</h3>
            </div>
          </div>
          <div className="pi pi-chevron-right"></div>
        </div>
      );
    }

    return <></>;
  };

  const renderSection = (sectionData) => {
    return Object.keys(sectionData).map((key) => {
      const value = sectionData[key];

      if (typeof value === "object") {
        return (
          <li key={key}>
            {key}
            <ol style={{ listStyle: "none" }}>
              {Object.keys(value).map((nestedKey) => (
                <li key={nestedKey}>
                  -{" "}
                  <span>
                    {value[nestedKey]} {renderButtonOnCase(value[nestedKey])}
                  </span>
                </li>
              ))}
            </ol>
          </li>
        );
      }

      return (
        <li key={key}>
          <span>
            {value} {renderButtonOnCase(value)}
          </span>
        </li>
      );
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"dashboard"} />
      </Stack>
      <div className="main-container">
        <div className="frontend-container">
          <div style={{ width: "100%" }}>
            <div className="event-form-inner">
              <div
                className="event-form-body list"
                style={{
                  gridTemplateColumns: id === 1 ? "unset" : "3.75fr 1.25fr",
                }}
              >
                <div className="event-form-body-inner">
                  <div
                    className="event-form-head banner"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/servicebanner/${id}.png)`,
                    }}
                  >
                    <div className="banner-content">
                      <h1>{serviceTitle}</h1>
                      <div className="sub-head">{serviceSubTitle}</div>
                    </div>
                  </div>
                  {id === 1 && (
                    <div className="event-service-list sidebar flex-column lg:flex-row">
                      {providerData.length > 0 &&
                        providerData
                          .filter((item) => item.id !== id)
                          .map((item) => (
                            <div
                              key={item.id}
                              className="event-service justify-content-center"
                              onClick={() => handleEventService(item)}
                            >
                              <div className="event-service-head">
                                <div>
                                  <img
                                    style={{
                                      display: "block",
                                      width: "30px",
                                    }}
                                    src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                    alt={item.serviceCardName}
                                  />
                                  <div>
                                    <h3>{item.serviceCardName}</h3>
                                    {/* <div className="event-rsvp">
                                    {item.serviceDescription}
                                   </div> */}
                                  </div>
                                </div>
                                <div className="pi pi-chevron-right"></div>
                              </div>
                            </div>
                          ))}
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "10px",
                      marginBottom: "10px",
                      paddingRight: "5px",
                    }}
                  >
                    <MyButton1
                      title="Back to Dashboard"
                      method={backtoEvents}
                      // icon="pi pi-arrow-left"
                    ></MyButton1>
                  </div>
                  {id === 1 ? (
                    <div className="event-providers">
                      {loading ? (
                        <h3 style={{ textAlign: "center" }}>
                          <i
                            className="pi pi-spin pi-spinner-dotted"
                            style={{ fontSize: "2rem", color: "#0cc0df" }}
                          ></i>
                        </h3>
                      ) : (
                        <div
                          className={`event-service alter no-data ${
                            howTo && "alignleft"
                          }`}
                        >
                          {howTo ? (
                            <>
                              <p className="text-xl font-bold">{context}</p>
                              <div>
                                <Accordion activeIndex={0}>
                                  {Object.keys(howTo).map((sectionKey) => (
                                    <AccordionTab
                                      header={
                                        <h3 className="font-legal">
                                          {sectionKey}
                                        </h3>
                                      }
                                      key={sectionKey}
                                    >
                                      <ol type="1">
                                        {renderSection(howTo[sectionKey])}
                                      </ol>
                                    </AccordionTab>
                                  ))}
                                </Accordion>
                              </div>
                            </>
                          ) : (
                            <>
                              <h3>KNOW ABOUT YOUR EVENT</h3>
                              <h4>
                                Get to know what to do exactly (AI enabled).
                              </h4>

                              <Button
                                iconPos="right"
                                icon="pi pi-chevron-right"
                                className="custom-button"
                                label="Get AI enabled Process"
                                onClick={handleAIenabledService}
                                size="small"
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="event-providers">
                      {providerListLoading ? (
                        <h3 style={{ textAlign: "center" }}>
                          <i
                            className="pi pi-spin pi-spinner-dotted"
                            style={{ fontSize: "2rem", color: "#0cc0df" }}
                          ></i>
                        </h3>
                      ) : providerInfo.length > 0 ? (
                        providerInfo.map((item) => {
                          // const hasValidServices =
                          //   item.events && item.events.length > 0 && item.price;
                          return renderProviderListById(item, id);

                          // <div
                          //   key={item.id}
                          //   className="event-service"
                          //   onClick={() => {
                          //     if (hasValidServices) {
                          //       console.log(
                          //         "There is prices we can directly add them "
                          //       );
                          //     } else {
                          //       handleProviderServcies(item);
                          //     }
                          //   }}
                          // >
                          //   {item.latitude && item.longitude && (
                          //     <LoadMap
                          //       locationLat={item.latitude}
                          //       locationLong={item.longitude}
                          //       width="100px"
                          //       height="100px"
                          //     />
                          //   )}
                          //   <div className="event-service-head">
                          //     <div className="event-service-headtop">
                          //       <h3>
                          //         {item.firstName} {item.lastName}
                          //       </h3>
                          //     </div>
                          //     <div>
                          //       <LocationOnOutlinedIcon className="plicon" />
                          //       {item.formattedAddress.length > 0
                          //         ? item.formattedAddress
                          //         : "Address not avaliable."}
                          //     </div>
                          //     <div className="bottom">
                          //       {item.distance !== null && (
                          //         <>
                          //           <RouteOutlinedIcon className="plicon" />
                          //           {`${(item.distance / 1000).toFixed(
                          //             1
                          //           )} km`}
                          //         </>
                          //       )}{" "}
                          //       <PhoneAndroidOutlinedIcon className="plicon" />
                          //       {item?.mobileNumber}
                          //     </div>
                          //   </div>
                          //   {/* {hasValidServices ? (
                          //     <div className="event-providerservcies full">
                          //       <div
                          //         key={item.services[0].serviceMaster.id}
                          //         className={`event-service alter ${
                          //           cart &&
                          //           cart.length > 0 &&
                          //           checkForAdd(
                          //             `${
                          //               item.services[0].service_master_id
                          //             }_${
                          //               item.services[0].service_provider_id
                          //             }_${
                          //               eventDetails &&
                          //               Object.keys(eventDetails).length > 0
                          //                 ? eventDetails.id
                          //                 : 0
                          //             }`
                          //           ) &&
                          //           "sel-event"
                          //         }`}
                          //       >
                          //         <div className="event-service-headtop">
                          //           <div
                          //             style={{ margin: 0, lineHeight: 1 }}
                          //             className="desc"
                          //           >
                          //             <small>Service:</small>
                          //           </div>
                          //           <h3>
                          //             {
                          //               item.services[0].serviceMaster
                          //                 .serviceName
                          //             }
                          //           </h3>
                          //           {new Date(
                          //             item.services[0].discountValidUpto
                          //           ) > new Date() &&
                          //           item.services[0].discountPrice ? (
                          //             <div className="price">
                          //               <s>&#8377;{item.services[0].price}</s>
                          //               <br />
                          //               <strong>
                          //                 &#8377;
                          //                 {item.services[0].discountPrice}
                          //               </strong>
                          //               <span
                          //                 style={{
                          //                   color: "red",
                          //                   fontSize: "20px",
                          //                 }}
                          //               >
                          //                 *
                          //               </span>
                          //             </div>
                          //           ) : (
                          //             <div className="price">
                          //               <strong>
                          //                 &#8377;{item.services[0].price}
                          //               </strong>
                          //             </div>
                          //           )}
                          //           {new Date(
                          //             item.services[0].discountValidUpto
                          //           ) > new Date() && (
                          //             <div className="validupto">
                          //               <span
                          //                 style={{
                          //                   color: "red",
                          //                   fontSize: "20px",
                          //                 }}
                          //               >
                          //                 *
                          //               </span>{" "}
                          //               Valid upto{" "}
                          //               {format(
                          //                 new Date(
                          //                   item.services[0].discountValidUpto
                          //                 ),
                          //                 "MMMM dd, yyyy"
                          //               )}
                          //             </div>
                          //           )}
                          //         </div>
                          //         <div className="event-service-middle">
                          //           {cart &&
                          //           cart.length > 0 &&
                          //           checkForAdd(
                          //             `${
                          //               item.services[0].service_master_id
                          //             }_${
                          //               item.services[0].service_provider_id
                          //             }_${
                          //               eventDetails &&
                          //               Object.keys(eventDetails).length > 0
                          //                 ? eventDetails.id
                          //                 : 0
                          //             }`
                          //           ) ? (
                          //             <Button
                          //               icon="pi pi-minus"
                          //               className="custom-button cancel"
                          //               label="Remove Service"
                          //               size="small"
                          //               style={{ width: "100%" }}
                          //               onClick={() =>
                          //                 removeService({
                          //                   itemID: `${
                          //                     item.services[0]
                          //                       .service_master_id
                          //                   }_${
                          //                     item.services[0]
                          //                       .service_provider_id
                          //                   }_${
                          //                     eventDetails &&
                          //                     Object.keys(eventDetails)
                          //                       .length > 0
                          //                       ? eventDetails.id
                          //                       : 0
                          //                   }`,
                          //                 })
                          //               }
                          //             />
                          //           ) : (
                          //             <Button
                          //               icon="pi pi-plus"
                          //               className="custom-button"
                          //               label="Add Service"
                          //               size="small"
                          //               style={{ width: "100%" }}
                          //               onClick={() =>
                          //                 addToService({
                          //                   itemID: `${
                          //                     item.services[0]
                          //                       .service_master_id
                          //                   }_${
                          //                     item.services[0]
                          //                       .service_provider_id
                          //                   }_${
                          //                     eventDetails &&
                          //                     Object.keys(eventDetails)
                          //                       .length > 0
                          //                       ? eventDetails.id
                          //                       : 0
                          //                   }`,
                          //                   service: `${item.services[0].serviceMaster.serviceName}`,
                          //                   provider: `${item.firstName} ${item.lastName}`,
                          //                   event: `${
                          //                     eventDetails &&
                          //                     Object.keys(eventDetails)
                          //                       .length > 0
                          //                       ? eventDetails.eventTitle
                          //                       : null
                          //                   }`,
                          //                   amount:
                          //                     new Date(
                          //                       item.services[0].discountValidUpto
                          //                     ) > new Date() &&
                          //                     item.services[0].discountPrice
                          //                       ? item.services[0]
                          //                           .discountPrice
                          //                       : item.services[0].price,
                          //                 })
                          //               }
                          //             />
                          //           )}
                          //         </div>
                          //       </div>
                          //     </div>
                          //   ) : (
                          //     <div className="pi pi-chevron-right"></div>
                          //   )} */}
                          // </div>
                        })
                      ) : (
                        <div className="event-service alter no-data">
                          <h3>
                            No {serviceName} in your area for the {eventTitle}
                          </h3>
                          <h4>SEND US A MESSAGE - WE WILL CONTACT YOU</h4>
                          <ButtonMailto
                            label="Send E-Mail"
                            mailto={config.emailAddress}
                            subject={`No ${serviceName} (service provider's) available in my area`}
                            body={`Hello,\n\nNo ${serviceName} (service providers) available in my area for the ${eventTitle}.\nPlease let me know when it's available.\n\n\nThanks,\n${
                              userDetails.firstName &&
                              capitalizeFirstLetter(userDetails.firstName)
                            }\n${
                              userDetails.mobileNumber &&
                              userDetails.mobileNumber
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {id !== 1 && (
                  <div className="event-service-list sidebar">
                    <div
                      className="sidebar-inner"
                      style={{ paddingTop: "100px" }}
                    >
                      {providerData.length > 0 &&
                        providerData
                          .filter((item) => item.id !== id)
                          .map((item) => (
                            <div
                              key={item.id}
                              className="event-service"
                              onClick={() => handleEventService(item)}
                            >
                              <div className="event-service-head">
                                <div>
                                  <img
                                    style={{
                                      display: "block",
                                      width: "30px",
                                    }}
                                    src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                    alt={item.serviceCardName}
                                  />
                                  <div>
                                    <h3>{item.serviceCardName}</h3>
                                    {/* <div className="event-rsvp">
                                    {item.serviceDescription}
                                   </div> */}
                                  </div>
                                </div>
                                <div className="pi pi-chevron-right"></div>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                )}

                {id === 1 && (
                  <Dialog
                    header={`AI enabled Service ${eventStateTitle}`}
                    visible={AIdialogVisible}
                    style={{
                      width: "calc(100% - 15px)",
                      maxWidth: "400px",
                    }}
                    modal
                    onHide={() => setAIdialogvisible(false)}
                  >
                    <form
                      onSubmit={formik.handleSubmit}
                      style={{ width: "100%", border: 0 }}
                    >
                      <div className="event-service-head-inner">
                        <div className="event-chantgpt-fields">
                          {eventStateTitle ? (
                            <TextField
                              type="hidden"
                              name="event"
                              value={formik.values.event}
                              style={{ position: "absolute", zIndex: -1 }}
                            />
                          ) : (
                            <TextField
                              size="small"
                              name="event"
                              label="Enter Event"
                              value={formik.values.event}
                              onChange={handleFieldChange("event")} // Use common change handler
                              variant="outlined"
                              sx={textFieldStyles}
                            />
                          )}
                          <Autocomplete
                            size="small"
                            sx={textFieldStyles}
                            options={states}
                            getOptionLabel={(option) => option.name}
                            value={
                              states.find(
                                (option) =>
                                  option.name === formik.values.Location
                              ) || null
                            }
                            onInputChange={(event, newInputValue) => {
                              // Trigger the formik value change when the input value changes
                              formik.setFieldValue("Location", newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              // Update formik value when the user selects an option
                              formik.setFieldValue(
                                "Location",
                                newValue?.name || ""
                              );
                            }}
                            filterOptions={(options, state) => {
                              // Show only options with 2 or more characters matching the input
                              return options.filter((option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Region / State"
                                variant="outlined"
                                name="Location"
                                sx={textFieldStyles}
                              />
                            )}
                            disableClearable
                            loading={languages.length === 0} // Show a loading indicator if no languages are available
                          />
                          {/* <TextField
                            size="small"
                            variant="outlined"
                            name="Language"
                            label="Select Language"
                            select
                            value={formik.values.Language}
                            onChange={handleFieldChange("Language")}
                            sx={textFieldStyles}
                          >
                            {languages.length > 0 &&
                              languages?.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                          </TextField> */}
                          <Autocomplete
                            size="small"
                            sx={textFieldStyles}
                            options={languages}
                            getOptionLabel={(option) => option.name}
                            value={
                              languages.find(
                                (option) =>
                                  option.name === formik.values.Language ||
                                  option.name ===
                                    states.find(
                                      (state) =>
                                        state.name === formik.values.Location
                                    )?.defaultLanguage
                              ) || null
                            }
                            onInputChange={(event, newInputValue) => {
                              // Trigger the formik value change when the input value changes
                              formik.setFieldValue("Language", newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              // Update formik value when the user selects an option
                              formik.setFieldValue(
                                "Language",
                                newValue?.name || ""
                              );
                            }}
                            filterOptions={(options, state) => {
                              // Show only options with 2 or more characters matching the input
                              return options.filter((option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Language"
                                variant="outlined"
                                name="Language"
                                sx={textFieldStyles}
                              />
                            )}
                            disableClearable
                            loading={languages.length === 0} // Show a loading indicator if no languages are available
                          />
                          <Autocomplete
                            size="small"
                            sx={textFieldStyles}
                            options={ReligionAndCaste?.religion || []}
                            getOptionLabel={(option) => option.name}
                            value={
                              ReligionAndCaste?.religion?.find(
                                (option) =>
                                  option.name === formik.values.Religion
                              ) || null
                            }
                            onInputChange={(event, newInputValue) => {
                              // Trigger the formik value change when the input value changes
                              formik.setFieldValue("Religion", newInputValue);
                            }}
                            onChange={(event, newValue) =>
                              handleReligionFieldChange("Religion", newValue)
                            }
                            filterOptions={(options, state) => {
                              // Show only options with 2 or more characters matching the input
                              return options.filter((option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Religion"
                                variant="outlined"
                                name="Religion"
                                sx={textFieldStyles}
                              />
                            )}
                            disableClearable
                            loading={ReligionAndCaste?.religion?.length === 0} // Show a loading indicator if no religions are available
                          />

                          <Autocomplete
                            size="small"
                            sx={textFieldStyles}
                            options={castes || []}
                            getOptionLabel={(option) => option.name}
                            value={
                              castes.find(
                                (option) => option.name === formik.values.Caste
                              ) || null
                            }
                            onInputChange={(event, newInputValue) => {
                              // Trigger the formik value change when the input value changes
                              formik.setFieldValue("Caste", newInputValue);
                            }}
                            onChange={(event, newValue) =>
                              handleCasteFieldChange("Caste", newValue)
                            }
                            filterOptions={(options, state) => {
                              // Show only options with 2 or more characters matching the input
                              return options.filter((option) =>
                                option.name
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Caste"
                                variant="outlined"
                                name="Caste"
                                sx={textFieldStyles}
                              />
                            )}
                            disableClearable
                            loading={castes.length === 0} // Show a loading indicator if no castes are available
                          />

                          <TextField
                            size="small"
                            variant="outlined"
                            name="SubCaste"
                            label="Select Sub Caste"
                            value={formik.values.SubCaste}
                            onChange={handleFieldChange("SubCaste")}
                            sx={textFieldStyles}
                          />

                          <span
                            style={{
                              fontSize: "10px",
                              color: "#737373",
                            }}
                          >
                            WE ONLY COLLECT THIS INFORMATION TO GIVE YOU
                            SUITABLE DETAILS AND FILTER SERVICES, AND NOT FOR
                            ANY OTHER REASON
                          </span>
                          <Button
                            style={{ width: "100%" }}
                            type="submit"
                            className="custom-button"
                            label="Submit"
                          />
                        </div>
                      </div>
                    </form>
                  </Dialog>
                )}

                {/* {
                  <div className="event-service-list sidebar">
                    {id === 1 && (
                      <>
                        <div className="event-service alter">
                          <div className="event-service-head">
                            <div>
                              <div>
                                <h3>
                                  KNOW ABOUT YOUR EVENT:
                                  <br />
                                  Get to know what to do exactly (AI enabled).
                                </h3>
                                <Button
                                  iconPos="right"
                                  icon="pi pi-chevron-right"
                                  className="custom-button"
                                  style={{ marginTop: "20px" }}
                                  label="Get AI enabled Process"
                                  onClick={handleAIenabledService}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
    </div>
  );
}

export default EventServices;
