import React from 'react';
import { Button } from 'primereact/button';
import '../css/CartDialog.css';
import { removeFromCart } from '../store/cartSlice';
import { useDispatch, useSelector } from "react-redux";

const CartDialog = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.serviceList);
  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + parseFloat(item.amount), 0);
  };
  const removeService = (opt) => {
    dispatch(removeFromCart(opt));
  }

  return (
    <div className={`cart-dialog ${isOpen ? 'open' : ''}`}>
      <div className="cart-header">
        <h2>Services</h2>
        <button className="close-button" onClick={onClose}><i className="pi pi-times"></i></button>
      </div>
      <div className="cart-items">
        {(cart && cart.length > 0) ? cart.map(item => (
          <div className='cart-item' key={item.itemID}>
            <div>
              <h3>{item.service}</h3>
              <h4>Event: {item.event}</h4>
              <h4>Provider: {item.provider}</h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
              <div className='remove' onClick={() => removeService({
                'itemID': `${item.itemID}`
              })}><i className="pi pi-times"></i></div>
              <h3 className='amount'>₹{item.amount}</h3>
            </div>
          </div>
        ))
          : <div className='cart-item full'><h3>Cart is empty</h3></div>}
      </div>
      {cart && cart.length > 0 && <div className='cart-total'><label>Total</label> <span>₹{getTotalPrice().toFixed(2)}</span></div>}
      <div className="cart-footer">
        <div className='btns group'>
          {(cart && cart.length > 0) ? <Button size="small" className="checkout-button">Checkout</Button> : <Button size="small" className="checkout-button" disabled>Checkout</Button>}
          <Button size="small" className="cancel" onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};

export default CartDialog;
