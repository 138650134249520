import React from "react";
import { Link } from "react-router-dom";
import Services from "../ProviderServices.json";
function ProviderServices() {
  return (
    <div
      className="middle-content"
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <div className="frontend-container">
        <div style={{ width: "100%" }}>
          <h2 className="font-legal">
            Enjoy your events with friends & family.
            <br /> We take care of Hosting.
          </h2>
          <div className="middle-content-services">
            {Services.map((item) => {
              return (
                <Link
                  key={item.id}
                  className="event-service"
                  to={`/service/${item.slug}`}
                >
                  <div className="event-service-head">
                    <div>
                      <img
                        style={{
                          display: "block",
                          width: "50px",
                        }}
                        src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                        alt={item.serviceName}
                      />
                      <div>
                        <h3 className="font-legal">{item.serviceCardName}</h3>
                        {/* <h5>{item.serviceSubName}</h5> */}
                        <div className="event-rsvp">
                          {item.serviceSubDescription}
                        </div>
                      </div>
                    </div>
                    <div className="pi pi-chevron-right"></div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProviderServices;
