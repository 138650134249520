import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Services from "../ProviderServices.json";
import { logOut } from "../store/authSlice";
import config from "../config";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { homePagePath } from "../constant";

function MiddleComponent() {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth.role);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const navigate = useNavigate();

  const loginpag = () => {
    navigate("/login");
  };

  const logoutAccount = () => {
    dispatch(logOut()); // Dispatch the logout action to clear user state
    navigate(homePagePath); // Redirect the user to the login page after logging out
    window.location.reload();
  };

  const scrollToTop = () => {
    isLoggedIn
      ? role === "customer"
        ? navigate("/dashboard")
        : navigate("/service-provider/dashboard")
      : navigate(homePagePath);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="frontend-container">
      <div className="footer-content">
        <div
          className={`footer-content-wrapper ${
            isLoggedIn ? role !== "customer" && "equal" : ""
          }`}
        >
          {isLoggedIn ? (
            role === "customer" && (
              <div className="section">
                <h3 className="font-legal">Services</h3>
                {Services.length > 0 &&
                  Services.map((item) => (
                    <Link
                      key={item.id}
                      className="footer-link"
                      to={`/service/${item.slug}`}
                    >
                      <span>
                        <strong>{item.serviceSubName}</strong>
                        {item.serviceSubDescription}
                      </span>
                      <span className="pi pi-chevron-right"></span>
                    </Link>
                  ))}
              </div>
            )
          ) : (
            <div className="section">
              <h3 className="font-legal">Services</h3>
              {Services.length > 0 &&
                Services.map((item) => (
                  <Link
                    key={item.id}
                    className="footer-link"
                    to={`/service/${item.slug}`}
                  >
                    <span>
                      <strong>{item.serviceSubName}</strong>
                      {item.serviceSubDescription}
                    </span>
                    <span className="pi pi-chevron-right"></span>
                  </Link>
                ))}
            </div>
          )}

          <div className="section contact">
            <h3 className="font-legal">Contact</h3>
            <p>
              <i className="pi pi-phone"></i>
              <span>{config.phoneNumber}</span>
            </p>
            <p>
              <i className="pi pi-map-marker"></i>
              <span>
                {config.companyFullName}
                <br />
                {config.contactAddress.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </p>
            <p>
              <i className="pi pi-envelope"></i>
              <span>{config.emailAddress}</span>
            </p>
            {isLoggedIn ? (
              role === "customer" && (
                <>
                  <h3 style={{ marginTop: "30px" }} className="font-legal">
                    Subscribe to our newsletter
                  </h3>
                  <IconField style={{ marginTop: "15px" }}>
                    <InputIcon className="pi pi-send"></InputIcon>
                    <InputText
                      placeholder="Enter your email"
                      style={{ width: "100%" }}
                    />
                  </IconField>
                </>
              )
            ) : (
              <>
                <h3 style={{ marginTop: "30px" }} className="font-legal">
                  Subscribe to our newsletter
                </h3>
                <IconField style={{ marginTop: "15px" }}>
                  <InputIcon className="pi pi-send"></InputIcon>
                  <InputText
                    placeholder="Enter your email"
                    style={{ width: "100%" }}
                  />
                </IconField>
              </>
            )}
          </div>

          {isLoggedIn && role !== "customer" && (
            <div className="section">
              <h3 className="font-legal">Subscribe to our newsletter</h3>
              <IconField style={{ marginTop: "15px" }}>
                <InputIcon className="pi pi-send"></InputIcon>
                <InputText
                  placeholder="Enter your email"
                  style={{ width: "100%" }}
                />
              </IconField>
            </div>
          )}

          <div className="section">
            <h3 className="font-legal">Quick Links</h3>
            <ul>
              <li>
                <span
                  onClick={scrollToTop}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Home
                </span>
              </li>
              <li>
                <Link to={"/about-us"}>About Us</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
              <li>
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </li>
              {!isLoggedIn ? (
                <li>
                  <span
                    onClick={loginpag}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Log-in / Sign-Up
                  </span>
                </li>
              ) : (
                <li>
                  <span
                    onClick={logoutAccount}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Logout
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MiddleComponent;
