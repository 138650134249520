import React, { useState } from 'react'
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createEvent, updateEvent } from '../../../Apiservices/Auth/servers/createEvents';
import CreateUpdateEventDialog from '../../CreateUpdateEventDialog/CreateUpdateEventDialog'
import { useNavigate } from "react-router-dom";

const CreateEvent = () => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const [loading, setLoading] = useState(true);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const navigate = useNavigate();

    const alertdialog = () => {
        confirmDialog({
            message: `You need login to check How easy it is.`,
            header: "Please Login or Sign-Up",
            defaultFocus: "accept",
            accept: () => {
                navigate("/login");
            },
        });
    };

    const openCreateEventDialog = () => {
        // navigate("/dashboard/event/create");
        if (isLoggedIn) {
            setIsUpdate(false);
            setDialogVisible(true);
            setSelectedEvent(null);
        } else {
            alertdialog();
        }
    };
    const handleCloseDialog = () => {
        setDialogVisible(false);
        setSelectedEvent(null);
    };

    const handleSubmitEvent = async ({ eventDetails }) => {
        handleCloseDialog()
        setLoading(true);
        try {
            if (isUpdate) {
                console.log("Updating event:", eventDetails);
                await updateEvent(eventDetails, eventDetails.id);
            } else {
                console.log("Creating new event:", eventDetails);

                await createEvent(eventDetails);
            }
        } catch (error) {
            console.log("Something Went Wrong while Creating/updating Event ");
            console.log(error);
        } finally {
            navigate('/dashboard')
            setLoading(false);
            // setIsFirstVisit(false);
        }
    };

    return (
        <>
            <Button
                className="custom-button"
                icon="pi pi-plus"
                label="Create Event"
                onClick={openCreateEventDialog}
                size="small"
            />
            <CreateUpdateEventDialog
                isVisible={dialogVisible}
                onClose={handleCloseDialog}
                eventDetails={selectedEvent}
                isUpdate={isUpdate}
                onSubmit={handleSubmitEvent}
            />
        </>
    )
}

export default CreateEvent