import React from "react";
import Services from "../../ProviderServices.json";
import CreateEvent from "../eventComponent/EventTabs/CreateEvent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";
import config from "../../config";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";

function SendInvitation({ buttonLabel }) {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const providerListStorageKey = config.providerListStorageKey;
  const service = Services.find((obj) => obj.id === 2);

  const navtoProviders = (service) => {
    var info = {
      serviceDetails: service,
      eventDetails: {},
    };
    if (isLoggedIn) {
      service.id === 2
        ? navigate("/dashboard", { state: info })
        : navigate("/providers", { state: info });
      window.scrollTo({ top: 0 });
    } else {
      alertdialog(info);
    }
  };

  const alertdialog = (info) => {
    confirmDialog({
      message: `You need login to check How easy it is.`,
      header: "Please Login or Sign-Up",
      defaultFocus: "accept",
      accept: () => {
        localStorage.setItem(providerListStorageKey, JSON.stringify(info));
        navigate("/login");
      },
    });
  };

  const testimonials = [
    {
      id: 1,
      message:
        "Thurent made my daughter’s birthday party so much easier to plan. The RSVP tracking helped me know exactly how many guests to expect, saving us time and money!",
      author: "Priya R.",
    },
    {
      id: 2,
      message:
        "Morbi hendrerit felis leo, eget ornare mi consectetur a. Proin dignissim justo non neque semper, at viverra augue rutrum",
      author: "Nick",
    },
    {
      id: 3,
      message:
        "Praesent dignissim sapien vitae mollis hendrerit. Vestibulum sodales, tellus sit amet convallis finibus, mauris tellus posuere est, a tempus ex est a libero.",
      author: "Thurent",
    },
  ];

  const testimonialTemplate = (testimonial) => {
    return (
      <div>
        <h3 className="message">{testimonial.message}</h3>
        <h5 className="author">-{testimonial.author}</h5>
      </div>
    );
  };

  return (
    <>
      <p style={{ textAlign: "center" }}>
        Your event deserves the best. With Thurent’s innovative invitation
        system, you can focus on what truly matters, creating memories that last
        a lifetime.
      </p>

      <p style={{ textAlign: "center" }}>
        <Button
          className="custom-button cancel"
          icon="pi pi-arrow-right"
          iconPos="right"
          label={buttonLabel}
          onClick={() => navtoProviders(service)}
        />
      </p>

      {isLoggedIn ? (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Create an event and find yourself how easy it is.</h3>
              <CreateEvent />
            </div>
          </div>
        </div>
      ) : (
        <div className="event-service-list">
          <div className="event-service alter">
            <div className="event-service-head">
              <h3>Start Planning Smarter—Join Thurent Now!</h3>
              <Button
                className="custom-button"
                icon="pi pi-sign-in"
                iconPos="right"
                label="Sign up"
                onClick={() => navigate("/signup")}
                size="small"
              />
            </div>
          </div>
        </div>
      )}

      <h2 className="font-legal">Why Thurent Invitations?</h2>
      <div className="middle-content-services">
        <div className="event-service alter">
          <div className="event-service-head">
            <div>
              <div>
                <h3 className="font-legal">Effortless Invitation Sharing:</h3>
                <div className="event-rsvp">
                  Seamlessly send event invites through WhatsApp directly from
                  our app.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-service alter">
          <div className="event-service-head">
            <div>
              <div>
                <h3 className="font-legal">Real-time RSVP Tracking:</h3>
                <div className="event-rsvp">
                  Get instant responses and keep tabs on your guest count
                  effortlessly.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-service alter">
          <div className="event-service-head">
            <div>
              <div>
                <h3 className="font-legal">Smart Insights:</h3>
                <div className="event-rsvp">
                  Use attendee data to fine-tune your event spending—reduce
                  waste, enhance experiences.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <blockquote>Plan Smart, Celebrate Big with Thurent!</blockquote>

      <div className="middle-content-services">
        <div className="event-service alter half">
          <h2 className="event-service-inner-head font-legal">How It Works</h2>
          <div className="event-service-head">
            <div>
              <div>
                <div className="event-service-list-item">
                  <h5>1. Create Your Event:</h5>
                  <p>Add your event details in the Thurent app.</p>
                </div>
                <div className="event-service-list-item">
                  <h5>2. Send Invitations:</h5>
                  <p>
                    Share beautifully designed invites via WhatsApp with just a
                    tap.
                  </p>
                </div>
                <div className="event-service-list-item">
                  <h5>3. Track Responses:</h5>
                  <p>Stay updated with live RSVP tracking.</p>
                </div>
                <div className="event-service-list-item">
                  <h5>4. Plan Smarter:</h5>
                  <p>
                    Leverage guest data insights to align your spending with
                    actual attendance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="custom-button"
            label="Try It Today—Free!"
            size="small"
          />
        </div>
        <div className="event-service alter half">
          <h2 className="event-service-inner-head font-legal">
            Benefits You’ll Love
          </h2>
          <div className="event-service-head">
            <div>
              <div>
                <div className="event-service-list-item">
                  <h5>Save Time:</h5>
                  <p>No more manual tracking of RSVPs.</p>
                </div>
                <div className="event-service-list-item">
                  <h5>Stay Organized:</h5>
                  <p>
                    Keep all your event details and guest responses in one
                    place.
                  </p>
                </div>
                <div className="event-service-list-item">
                  <h5>Enhance Guest Experience:</h5>
                  <p>
                    Plan food, seating, and activities that match the actual
                    headcount.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="font-legal">What our clients think about us?</h2>
      <div className="testimonials">
        <Carousel
          value={testimonials}
          itemTemplate={testimonialTemplate}
          showNavigators={false}
          autoplayInterval={4000}
          circular={true}
        />
      </div>
    </>
  );
}

export default SendInvitation;
