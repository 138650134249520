import React, { useEffect } from "react";
import { CssBaseline, Stack, IconButton } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Footer from "./Footer";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import "./eventComponent/EventTabs/EventTabs.css";
import Services from "../ProviderServices.json";
import config from "../config";
import ButtonMailto from "./ButtonMailto";
import CreateEvent from "./eventComponent/EventTabs/CreateEvent";
import CreateEventCTA from "./EventServices/CreateEventCTA";
import { homePagePath } from "../constant";

export const Contactuspage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const role = useSelector((state) => state.auth.role);

  const backtoHome = () => {
    isLoggedIn
      ? role === "customer"
        ? navigate("/dashboard")
        : navigate("/service-provider/dashboard")
      : navigate(homePagePath);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
      </Stack>
      <div className={`main-container ${!isLoggedIn && "no-auth"}`}>
        <div className="frontend-container">
          <div style={{ width: "100%" }}>
            <div className="event-form-inner">
              <div
                className={`event-form-body ${
                  isLoggedIn && role === "provider" ? "alter full" : "list"
                }`}
              >
                <div className="event-form-body-inner">
                  <div className="event-form-head">
                    <div className="event-service-head-inner">
                      <IconButton
                        onClick={backtoHome}
                        style={{
                          backgroundColor: "#0cc0df",
                          color: "#fff",
                          padding: "8px",
                        }}
                      >
                        <KeyboardBackspaceOutlinedIcon />
                      </IconButton>
                      <div>
                        <h1 className="font-legal font-25px">Contact</h1>
                        {/* <div className="event-rsvp">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="event-form-content event-service-content">
                    <div className="section contact">
                      <h3 className="font-legal">Contact Details</h3>
                      <p>
                        <i className="pi pi-phone"></i>
                        <span>{config.phoneNumber}</span>
                      </p>
                      <p>
                        <i className="pi pi-map-marker"></i>
                        <span>
                          {config.companyFullName}
                          <br />
                          {config.contactAddress
                            .split("\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </span>
                      </p>
                      <p style={{ marginBottom: "40px" }}>
                        <i className="pi pi-envelope"></i>
                        <span>{config.emailAddress}</span>
                      </p>

                      <h4
                        style={{ marginBottom: "15px" }}
                        className="font-legal"
                      >
                        SEND US A MESSAGE - WE WILL CONTACT YOU
                      </h4>
                      <ButtonMailto
                        label="Send E-Mail"
                        mailto={config.emailAddress}
                        subject="General enquiry"
                        body={`Hi,\n\n`}
                      />
                    </div>
                  </div>
                </div>
                {(!isLoggedIn || (isLoggedIn && role === "customer")) && (
                  <div className="event-service-list sidebar">
                    <CreateEventCTA />
                    {Services.length > 0 &&
                      Services.map((item) => (
                        <Link
                          key={item.id}
                          className="event-service"
                          to={`/service/${item.slug}`}
                        >
                          <div className="event-service-head">
                            <div>
                              <img
                                style={{
                                  display: "block",
                                  width: "30px",
                                }}
                                src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                alt={item.serviceCardName}
                              />
                              <div>
                                <h3>{item.serviceCardName}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
      <ConfirmDialog />
    </div>
  );
};
export default Contactuspage;
