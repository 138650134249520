import React, { useEffect } from "react";
import { CssBaseline, Stack, IconButton } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css";
import config from "../../config";
import Services from "../../ProviderServices.json";
import CreateEvent from "../eventComponent/EventTabs/CreateEvent";
import AIEnabled from "./AIEnabled";
import DefaultService from "./DefaultService";
import SendInvitation from "./SendInvitation";
import CreateEventCTA from "../EventServices/CreateEventCTA";
import { homePagePath } from "../../constant";

export const Servicespage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const providerListStorageKey = config.providerListStorageKey;

  const { slug } = useParams();

  const service = Services.find((obj) => obj.slug === slug);

  const {
    id,
    serviceName,
    serviceDescription,
    serviceCardName,
    serviceSubDescription,
    serviceTitle,
    serviceSubTitle,
  } = service;

  const buttonLabel =
    id === 1
      ? 'Try our "AI enabled" Feature'
      : id === 2
      ? 'Try our "Invitations" Feature'
      : `See ${serviceName} in Your Area`;

  const backtoHome = () => {
    navigate(homePagePath);
  };

  const alertdialog = (info) => {
    confirmDialog({
      message: `You need login to check How easy it is.`,
      header: "Please Login or Sign-Up",
      defaultFocus: "accept",
      accept: () => {
        localStorage.setItem(providerListStorageKey, JSON.stringify(info));
        navigate("/login");
      },
    });
  };

  const navtoProviders = (service) => {
    var info = {
      serviceDetails: service,
      eventDetails: {},
    };
    if (isLoggedIn) {
      service.id === 2
        ? navigate("/dashboard", { state: info })
        : navigate("/providers", { state: info });
      window.scrollTo({ top: 0 });
    } else {
      alertdialog(info);
    }
  };

  const renderServiceContent = (id) => {
    switch (id) {
      case 1:
        return <AIEnabled buttonLabel={buttonLabel} />;
      case 2:
        return <SendInvitation buttonLabel={buttonLabel} />;
      default:
        return <DefaultService id={id} buttonLabel={buttonLabel} />;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [slug]);

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
      </Stack>
      <div className={`main-container ${isLoggedIn && "no-auth"}`}>
        <div className="frontend-container">
          <div style={{ width: "100%" }}>
            <div className="event-form-inner">
              <div className="event-form-body list">
                <div className="event-form-body-inner">
                  <div
                    className="event-form-head banner"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/servicebanner/${id}.png)`,
                    }}
                  >
                    <div className="banner-content">
                      <h1>{serviceTitle}</h1>
                      <div className="sub-head">{serviceSubTitle}</div>
                    </div>
                    {/* <div className="event-service-head-inner">
                                            <IconButton
                                                onClick={backtoHome}
                                                style={{ backgroundColor: '#0cc0df', color: '#fff', padding: '8px' }}
                                            >
                                                <KeyboardBackspaceOutlinedIcon />
                                            </IconButton>
                                            <div>
                                                <h3>{serviceCardName}</h3>
                                                <div className="event-rsvp">
                                                    {serviceSubDescription}
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                  <div className="event-form-content event-service-content">
                    <div className="event-service-data">
                      {renderServiceContent(id)}
                    </div>
                    {/* serviceContent ? <><div className="event-service-list" style={{ marginBottom: '15px' }}>
                                            <div className="event-service alter">
                                                <div className="event-service-head">
                                                    <h3>Create an event and find yourself how easy it is.</h3>
                                                    <CreateEvent />
                                                </div>
                                            </div>
                                        </div></> : renderServiceContent(id) */}
                  </div>
                </div>
                <div className="event-service-list sidebar">
                  <div className="sidebar-inner">
                    <CreateEventCTA />
                    {Services.length > 0 &&
                      Services.filter((item) => item.id !== id).map((item) => (
                        <Link
                          key={item.id}
                          className="event-service"
                          to={`/service/${item.slug}`}
                        >
                          <div className="event-service-head">
                            <div>
                              <img
                                style={{
                                  display: "block",
                                  width: "30px",
                                }}
                                src={`${process.env.PUBLIC_URL}/assets/images/${item.id}.png`}
                                alt={item.serviceCardName}
                              />
                              <div>
                                <h3 className="font-legal">
                                  {item.serviceCardName}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
      <ConfirmDialog />
    </div>
  );
};
export default Servicespage;
