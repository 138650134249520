import { createEvent } from "../Apiservices/Auth/servers/createEvents";
import config from "../config";
import axiosInstance from "../util/axiosInstance";
import { getToken } from "../util/Cookies";

export const editBasicInfo = async (info) => {
  const token = getToken();
  try {
      const response = await axiosInstance.put('/user/profile', info, {
          method: "PUT",
          headers: {
              Authorization: `${token}`,
          }
      })
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error(response.message || 'Unable to get the provider details');
      }
  } catch (error) {
      if (error.response && error.response.status === 409) {
          return error.response.data;
      } else {
          console.error('There was a problem with the fetch operation:', error);
      }

  }
};

export const loginWithPassword = async (identifier, password) => {
  try {
    const response = await axiosInstance.post("/auth/login", {
      identifier,
      password,
    });
    return response.data; // Return the response data directly
  } catch (error) {
    // Check if error response exists and log the error message
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};

export const sendLoginOTP = async (identifier) => {
  try {
    const response = await axiosInstance.post("/auth/send-login-otp", {
      identifier,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};
export const sendVerificationOTP = async (identifier) => {
  try {
    const response = await axiosInstance.post("/auth/send-verification-otp", {
      identifier,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};

export const verifyWithOTP = async (values) => {
  try {
      const response = await axiosInstance.post("/auth/verify-otp", values);
      return response.data;
  } catch (error) {
      if (error.response) {
          throw new Error(error.response.data.message); // Throw error message from server
      } else {
          throw new Error("Something Went Wrong"); // General error message
      }
  }
};

export const loginWithOTP = async (identifier, otpCode) => {
  try {
    const response = await axiosInstance.post("/auth/login-otp", {
      identifier,
      otpCode,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};

export const requestResetPassword = async (identifier) => {
  try {
    console.log("Request Password Reset is called");
    const response = await axiosInstance.post("/auth/request-password-reset", {
      identifier,
    });

    if (response.status === 200) {
      const { message, mobileNumber } = response.data;
      return `${message} to ${mobileNumber || "your registered mobile number"}`;
    }

    return null; // In case the status is not 200
  } catch (error) {
    if (error.response && error.response.status) {
      throw new Error(
        error.response.data.message ||
          error.response.data.error ||
          "An unexpected error occurred"
      );
    } else {
      throw new Error("Something went wrong. Please try again later.");
    }
  }
};

export const resetPassword = async (info) => {
  const token = getToken();
  try {
      const response = await axiosInstance.post('/auth/change-password', info, {
          method: "POST",
          headers: {
              Authorization: `${token}`,
          }
      })
      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error('Unable to get the provider details');
      }
  } catch (error) {
      if (error.response && error.response.status === 409) {
          return error.response.data;
      } else {
          console.error('There was a problem with the fetch operation:', error);
      }
  }
};

export const resetPasswordViaOtp = async ({
  identifier,
  otpCode,
  newPassword,
}) => {
  try {
    const response = await axiosInstance.post("/auth/reset-password-via-otp", {
      identifier,
      otpCode,
      newPassword,
    });

    // Check the response for the message
    if (response.status === 200) {
      const { message } = response.data;
      return message;
    }
  } catch (error) {
    // Handle error cases such as OTP invalid or expired
    throw new Error(
      error.response?.data?.message ||
        "An error occurred while resetting password"
    );
  }
};

export const postPendingEvent = async () => {
  //Create if any Event is there
  const eventBody = localStorage.getItem(config.eventLocalStorageKey);
  setFormType(CREATION_FORM);
  if (eventBody) {
    const parsedData = JSON.parse(eventBody);
    // console.log(parsedData);
    if (parsedData.isPending) {
      const createdEvent = await createEvent(parsedData);

      setFormType(EDIT_FORM, createdEvent?.event?.id);
      // setIsFirstVisit(false);
      localStorage.removeItem(config.eventLocalStorageKey); // Remove data after event creation
    } else {
      console.log("Event is not pending. No action taken.");
    }
  } else {
    console.log("No event data found in local storage.");
  }
};

// // Function to set the "isFirstVisit" value in localStorage
// export const setIsFirstVisit = (isFirstVisit) => {
//   console.log("Set is First Visit", isFirstVisit ? "true" : "false");
//   localStorage.setItem("isFirstVisit", isFirstVisit ? "true" : "false");
// };

// // Function to get the "isFirstVisit" value from localStorage
// export const getIsFirstVisit = () => {
//   const isFirstVisit = localStorage.getItem("isFirstVisit");
//   console.log("get is First Visit", isFirstVisit);
//   return isFirstVisit === "true"; // Explicitly check for "true" string
// };

export const FORM_TYPE_KEY = "eventFormType";
export const FORM_ID_KEY = "eventFormId";

// Constants for form types
export const EDIT_FORM = "editForm";
export const CREATION_FORM = "creationForm";

const setLocalStorageValue = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value)); // Convert the value to a JSON string
    console.log(`Set ${key}:`, value);
  } catch (error) {
    console.error(`Error setting localStorage value for key "${key}":`, error);
  }
};

const getLocalStorageValue = (key) => {
  try {
    const storedValue = localStorage.getItem(key);
    console.log(`Get ${key}:`, storedValue);
    return storedValue ? JSON.parse(storedValue) : null;
  } catch (error) {
    console.error(`Error getting localStorage value for key "${key}":`, error);
    return null;
  }
};

const removeLocalStorageValue = (key) => {
  try {
    localStorage.removeItem(key);
    console.log(`Removed ${key} from localStorage`);
  } catch (error) {
    console.error(`Error removing localStorage value for key "${key}":`, error);
  }
};

export const setFormType = (formType, id = null) => {
  if (formType === null) {
    removeLocalStorageValue(FORM_TYPE_KEY);
    removeLocalStorageValue(FORM_ID_KEY);
    console.log(`Cleared ${FORM_TYPE_KEY} and ${FORM_ID_KEY}`);
    return;
  }

  if (formType !== EDIT_FORM && formType !== CREATION_FORM) {
    console.log(
      `Invalid form type. Must be '${EDIT_FORM}' or '${CREATION_FORM}`
    );
    throw new Error(
      `Invalid form type. Must be '${EDIT_FORM}' or '${CREATION_FORM}'.`
    );
  }
  setLocalStorageValue(FORM_TYPE_KEY, formType);
  if (formType === EDIT_FORM) {
    setLocalStorageValue(FORM_ID_KEY, id);
    console.log(`Saved ${FORM_ID_KEY}:`, id);
  }
};

export const getFormTypeData = () => {
  const formType = getLocalStorageValue(FORM_TYPE_KEY);
  const formId =
    formType === EDIT_FORM ? getLocalStorageValue(FORM_ID_KEY) : null;
  return { formType, formId };
};
