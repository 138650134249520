import axiosInstance from "../util/axiosInstance";

export const loginWithPassword = async (identifier, password) => {
  try {
    const response = await axiosInstance.post("/auth-providers/login", {
      identifier,
      password,
    });
    return response.data; // Return the response data directly
  } catch (error) {
    // Check if error response exists and log the error message
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};

export const sendLoginOTP = async (identifier) => {
  try {
    const response = await axiosInstance.post("/auth-providers/send-login-otp", {
      identifier,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  }
};

export const loginWithOTP = async (identifier, otpCode) => {
  try {
    const response = await axiosInstance.post("/auth-providers/login-otp", {
      identifier,
      otpCode,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
  } 
};

export const requestResetPassword = async (identifier) => {
  try {
    console.log("Request Password Reset is called");
    const response = await axiosInstance.post("/auth-providers/request-password-reset", {
      identifier,
    });

    if (response.status === 200) {
      const { message, mobileNumber } = response.data;
      return `${message} to ${mobileNumber || "your registered mobile number"}`;
    }

    return null; // In case the status is not 200
  } catch (error) {
    if (error.response && error.response.status) {
      throw new Error(
        error.response.data.message ||
          error.response.data.error ||
          "An unexpected error occurred"
      );
    } else {
      throw new Error("Something went wrong. Please try again later.");
    }
  }
};

export const resetPasswordViaOtp = async ({
  identifier,
  otpCode,
  newPassword,
}) => {
  try {
    const response = await axiosInstance.post("/auth-providers/reset-password-via-otp", {
      identifier,
      otpCode,
      newPassword,
    });

    // Check the response for the message
    if (response.status === 200) {
      const { message } = response.data;
      return message;
    }
  } catch (error) {
    // Handle error cases such as OTP invalid or expired
    throw new Error(
      error.response?.data?.message ||
        "An error occurred while resetting password"
    );
  }
};
