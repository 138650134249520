import React from "react";
import "../css/AccountDialog.css";
import { useNavigate } from "react-router-dom";
// import { homePagePath } from "../constant";

const AccountDialog = ({ isOpen, onClose, users, logout }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout(); // Call the logout fun
    // navigate(homePagePath);
    // window.location.reload();
    // console.log("Reload");
  };
  if (!isOpen) return null; // If dialog is not open, don't render

  return (
    <div className="account-dialog-overlay">
      <div className="account-dialog-content">
        {/* <div className="account-header">
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div> */}
        <div className="account-details">
          {users !== null && (
            <div className="account-content">
              <p
                className="account-d"
                onClick={() => navigate("/dashboard/my-account")}
              >
                My Account
              </p>
              <p className="account-d">My Orders</p>
              <button
                className="logout-button"
                onClick={() => {
                  handleLogout();
                }}
              >
                LOGOUT
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDialog;
