import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        serviceList: []
    },
    reducers: {
        addToCart: (state, action) => {
            const optInCart = state.serviceList.find((opt) => (
                opt.itemID === action.payload.itemID
            ));
            if (!optInCart)
                state.serviceList.push({ ...action.payload });
        },
        removeFromCart: (state, action) => {
            const removeItem = state.serviceList.filter((opt) => opt.itemID !== action.payload.itemID);
            state.serviceList = removeItem;
        },
    }
});

export const { addToCart, removeFromCart } = cartSlice.actions;
export default cartSlice.reducer;