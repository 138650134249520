export const passwordRegEx =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/;

export const homePagePath = "/";

export const searchSelectEventText =
  "SELECT EVENT - Type 3 or more letters here";

export const searchSelectCateringText =
  "SELECT CATERING - Type 3 or more letters here";

export const searchSelectTentHouseText =
  "SELECT TENT HOUSE - Type 3 or more letters here";
