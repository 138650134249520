// import { loginRequest, loginSuccess, loginFailure } from '../action/loginAction';
import { profileFailuer,profileRequest,profileSuccess } from '../action/profileAction';
import config from '../../../config'
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from '../../../util/axiosInstance';

import { getToken } from "../../../util/Cookies";
import { decodeToken } from "react-jwt";

export const getProfile = async () => {
  //dispatch(profileRequest());
  // const token=sessionStorage.getItem('token');

  const token = getToken();
  const decodedToken = decodeToken(token);
  const userType = decodedToken?.userType || null;

  try {
    const response = await axiosInstance.get(`/${userType === 'provider' ? 'provider' : 'user'}/profile`, {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    })    
    const data =  response.data;
    if (response.status === 200) {
      return data.data;
    } else {
      throw new Error(data.message || 'Login failed');
    }
  } catch (error) {
   console.error('There was a problem with the fetch operation:', error);
  }
};

export const getServiceProviderProfile = async () => {
  const token = getToken();

  try {
    const response = await axiosInstance.get('/provider/profile', {
      method: "GET",
      headers: {
        Authorization: `${token}`,
      },
    })    
    const data =  response.data;
    if (response.status === 200) {
      return data.data;
    } else {
      throw new Error(data.message || 'Unable to get the provider details');
    }
  } catch (error) {
   console.error('There was a problem with the fetch operation:', error);
  }
};
