import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Button,
  Paper,
  List,
  ListItem,
  Checkbox,
  Typography,
  createFilterOptions,
  MenuItem,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { fetchServices } from "../../Apiservices/Auth/servers/fetchServices";
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import "./ServiceProviderDashboard.css";
import { Toast } from "primereact/toast";
import {
  updateServiceProviderServices,
  addServiceProviderServices,
  editServiceProviderServices,
  addPriestEventServices,
  deletePriestEventServices,
} from "../../Apiservices/Auth/servers/updateServiceProviderServices";
import {
  addCateringProviderMenuImage,
  addCateringProviderServices,
  deleteCateringProviderServices,
  getCateringProviderAddedServices,
  getCateringProviderCategories,
  getCateringProviderCuisines,
  getCateringProviderServices,
  getPriestEvents,
  getPriestProviderEvents,
  getServiceProviderServices,
  updateCateringProviderServices,
} from "../../Apiservices/Auth/servers/fetchProviderData";
import { InputNumber } from "primereact/inputnumber";
import { Button as PrimeButton } from "primereact/button";
import CollapseExpandTree from "../CustomComponents/CollapseExpandTree";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import EditCateringServiceForm from "./EditCateringServiceForm";
import config from "../../config";
import { OverlayPanel } from "primereact/overlaypanel";
import { Card } from "primereact/card";
import {
  searchSelectCateringText,
  searchSelectEventText,
} from "../../constant";

function CateringService(props) {
  const [showImage, setShowImage] = useState(false);
  const [showIncludedItems, setShowIncludedItems] = useState(false);
  const [currentMealType, setCurrentMealType] = useState(null);
  const [includedItems, setIncludedItems] = useState([]);

  const [imageData, setImageData] = useState({});
  const MAX_FILE_SIZE = 10000000;
  const [providerServices, setProviderServices] = useState([]);
  // Add new service data
  const [resultedServices, setResultedServices] = useState([]);
  // Veg/Non Veg data
  const [mealTypes, setMealTypes] = useState([]);
  // Cuisine Type
  const [cuisines, setCuisines] = useState([]);

  const fetchMealTypes = async () => {
    const mealTypes = await getCateringProviderCategories();

    if (mealTypes) {
      setMealTypes(mealTypes);
    }
  };

  const fetchProviderServices = async () => {
    const addedServices = await getCateringProviderAddedServices();

    if (addedServices) {
      setProviderServices(addedServices.data);
    }
  };

  const fetchCuisines = async () => {
    const cuisines = await getCateringProviderCuisines();

    if (cuisines) {
      setCuisines(cuisines);
    }
  };

  useEffect(() => {
    fetchMealTypes();
    fetchCuisines();
    fetchProviderServices();
  }, []);

  const [cateringTempSelectedServices, setCateringTempSelectedServices] =
    useState([]);

  const [services, setServices] = useState([]);
  const [editService, setEditService] = useState({});
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const oneYearFromToday = new Date(
    new Date().setFullYear(today.getFullYear() + 1)
  );
  const [tempToAddServices, setTempToAddServices] = useState([]);

  const [isAddServiceDialogVisible, setAddServiceIsDialogVisible] =
    useState(false);
  const [isEditServiceDialogVisible, setEditServiceIsDialogVisible] =
    useState(false);

  const openAddServiceDialog = () => {
    setAddServiceIsDialogVisible(true);
  };

  const closeAddServiceDialog = () => {
    setTempToAddServices([]);
    setCateringTempSelectedServices([]);
    setAddServiceIsDialogVisible(false);
  };

  const closeEditServiceDialog = () => {
    setEditService({});
    setEditServiceIsDialogVisible(false);
  };

  const resultedServicesFunc = useCallback(async () => {
    setLoading(true);
    try {
      const cateringServices = await getCateringProviderServices();

      setResultedServices(cateringServices);
      const includedItemsMap = cateringServices?.map((meal) => {
        return {
          meal_name: meal.meal_name,
          defaults: meal.defaults,
        };
      });
      setIncludedItems(includedItemsMap);
    } catch (error) {
      console.error("Failed to services:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    resultedServicesFunc();
  }, [resultedServicesFunc]);

  const handleDelete = async (itemId) => {
    setLoading(true);
    const toDeleteService = {
      itemIds: [itemId],
    };

    try {
      await deleteCateringProviderServices(toDeleteService);
    } catch (error) {
      console.error("Failed to services:", error);
    } finally {
      setLoading(false);
      fetchProviderServices();
    }
  };

  const deleteServiceConfirm = (serviceID) => {
    confirmDialog({
      message: "Do you want to delete this service?",
      header: "Delete Confirmation",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(serviceID),
      reject,
    });
  };

  const reject = () => {};

  const handleEdit = async (obj) => {
    setEditService(obj);
    setEditServiceIsDialogVisible(true);
  };

  useEffect(() => {
    setServices((prevServices) => {
      return tempToAddServices.map((service) => {
        const tempService = prevServices.find(
          (prevService) => prevService.meal_id === service.meal_id
        );

        if (tempService) {
          return {
            category_id: service.diet_category,
            meal_id: service.meal_id, // Ensure this is available in your data
            type_id: service.type_id,
            min_people: service.min_people,
            regular_price: service.regular_price ? service.regular_price : null, // Convert to string
            discounted_price: service.discounted_price
              ? service.discounted_price
              : null,
            valid_until: service.valid_until
              ? new Date(service.valid_until).toISOString()
              : null,
            ...tempService,
          };
        } else {
          return service;
        }
      });
    });
  }, [tempToAddServices]);

  function replaceEmptyWithNull(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (
          (typeof value === "string" && value.trim() === "") ||
          value === undefined
        ) {
          newObj[key] = null;
        } else {
          newObj[key] = value;
        }
      }
    }

    return newObj;
  }

  const fileInputRef = useRef(null);

  // Formik Configuration
  const formik2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_id: editService?.item_id,
      meal_id: editService?.meal?.meal_id,
      category_id: editService?.category?.category_id,
      image_ids: [editService?.gallery?.[0]?.id], // Store image files
      type_id: editService?.type?.type_id || 0,
      min_people: editService?.min_people || 0,
      regular_price: editService?.regular_price
        ? Number(editService?.regular_price)
        : 0,
      discounted_price: editService?.discounted_price
        ? Number(editService?.discounted_price)
        : 0,
      valid_until: editService?.valid_until
        ? new Date(editService?.valid_until)
        : null,
    },
    validationSchema: Yup.object({
      type_id: Yup.string().required("Type is required"),
      min_people: Yup.number()
        .min(1, "At least 1 person required")
        .required("Min people required"),
      regular_price: Yup.number()
        .min(0, "Price should be positive")
        .required("Price is required"),
      discounted_price: Yup.number()
        .min(0, "Discounted price should be positive")
        .nullable()
        .test(
          "is-less-than-regular",
          "Discount price should be less than regular price",
          function (value) {
            return !value || value < this.parent.regular_price;
          }
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const payload = {
        cateringItems: [values],
      };

      const response = await updateCateringProviderServices(payload);

      if (response && response?.data?.length > 0) {
        fetchProviderServices();
      } else {
        console.error("❌ Error adding services:", response);
      }

      setLoading(false);
      resetForm();
      closeEditServiceDialog();
    },
  });

  // Handle File Upload
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    formik2.setFieldValue("image_ids", files);
  };

  // Clear File Input
  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    formik2.setFieldValue("image_ids", []);
  };

  const handleFieldChange2 = (field) => (event) => {
    const value = event.target.value;
    formik2.setFieldValue(field, value);
    //updateLocalStorage({ ...formik.values, [field]: value });
  };

  const textFieldStyles = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "4px",
    marginBottom: "10px",
  };
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0cc0df",
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: 700,
    },
  }));

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    trim: true,
    stringify: (option) => option?.["meal_name"],
  });

  const handleCateringChange = (catering) => {
    setCateringTempSelectedServices((prevSvc) => {
      const preSelectedCatering = prevSvc.find(
        (c) => c.meal_id === catering.meal_id
      );

      if (preSelectedCatering !== undefined) {
        return prevSvc.filter((c) => c.meal_id !== preSelectedCatering.meal_id);
      } else {
        return [...prevSvc, catering];
      }
    });
  };

  const onUpload = async (event) => {
    if (event.files.length === 0) {
      console.error("No file uploaded.");
      return;
    }

    const file = event.files[0];

    if (file.size > MAX_FILE_SIZE) {
      console.error("File size exceeds the 10MB limit.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await addCateringProviderMenuImage(formData);

    if (!response?.file?.id) {
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.clear();
      }
    } else {
      formik2.setFieldValue("image_ids", [response?.file?.id]);
    }
  };

  function sortItems(item) {
    // Define sorting order for categories
    const categoryOrder = {
      Veg: 0,
      "Non-Veg": 1,
    };

    // Define sorting order for meals within each category
    const mealOrder = {
      "Basic Veg": 0,
      "Extended Veg": 1,
      "Full Buffet Veg": 2,
      "Basic Non-Veg": 3,
      "Extended Non-Veg": 4,
      "Full Buffet Non-Veg": 5,
      Breakfast: 6,
      "Snack Time": 7,
      "High Tea": 8,
    };

    // Get category and meal names for comparison
    const categoryValue = item.category.category_name;
    const mealValue = item.meal.meal_name;

    // Return a tuple that represents the sorting order
    return [
      categoryOrder[categoryValue], // Sort by Veg/Non-Veg
      mealOrder[mealValue] !== undefined ? mealOrder[mealValue] : 999, // Sort by meal order
    ];
  }

  const mealData = useMemo(
    () => includedItems?.find((item) => item.meal_name === currentMealType),
    [currentMealType]
  );

  const totalItems =
    mealData?.defaults !== undefined
      ? Object.values(mealData?.defaults)
          .filter((item) => typeof item === "number")
          .reduce((acc, item) => acc + item, 0)
      : 0;

  return (
    <>
      <Dialog
        header={"Included Items"}
        visible={showIncludedItems}
        style={{ maxWidth: "600px", overflowY: "scroll" }}
        onHide={() => {
          if (!showIncludedItems) return;
          setShowIncludedItems(false);
          setCurrentMealType(null);
        }}
      >
        <div style={{ textAlign: "center" }} className="p-0 m-0">
          <h1>
            <i className="pi pi-align-right mr-2 text-2xl" />
            {totalItems} Items
            <i className="pi pi-align-left ml-2 text-2xl" />
          </h1>

          <div className="meal-info">
            {mealData?.defaults !== undefined &&
              Object.entries(mealData?.defaults).map(([k, v], index, arr) => {
                if (index !== arr.length - 1) {
                  return (
                    <p className="p-0 pb-1 m-0">
                      <strong>{k}-</strong> {v}
                    </p>
                  );
                }
              })}
          </div>

          <p>{mealData?.defaults?.description}</p>
        </div>
      </Dialog>
      <Dialog
        header={"Menu Image"}
        visible={showImage}
        style={{ maxWidth: "600px", overflowY: "scroll" }}
        onHide={() => {
          if (!showImage) return;
          setShowImage(false);
          setImageData({});
        }}
      >
        <img
          src={`${config.API_BASE_URL}/gallery/download/${imageData?.gallery?.[0]?.id}`}
          alt={imageData?.meal?.meal_name}
          width={"100%"}
          key={imageData?.item_id}
        />
      </Dialog>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          gap: "20px",
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <div>
          <h4>{props.providerLabel}</h4>
          <div className="desc">{props.providerDesc}</div>
        </div>
        {resultedServices.length > 0 && (
          <div>
            <Button
              size="small"
              onClick={openAddServiceDialog}
              startIcon={<AddTwoToneIcon />}
              sx={{
                fontWeight: "700",
                padding: "4px 16px",
                color: "#ffffff",
                border: "2px solid #0cc0df",
                backgroundColor: "#0cc0df",
                "&:hover": {
                  borderColor: "#1da3ba",
                  backgroundColor: "#1da3ba",
                },
              }}
            >
              Select Menu Type
            </Button>
            <Dialog
              header="Select Menu Type to Add"
              visible={isAddServiceDialogVisible}
              onHide={closeAddServiceDialog}
              style={{
                width: "500px",
                height: "500px",
              }}
              contentStyle={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden", // Prevents the dialog itself from growing
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: "auto", // Enables vertical scrolling for the content
                  padding: "10px",
                }}
              >
                <Autocomplete
                  multiple
                  freeSolo
                  filterOptions={(options, state) => {
                    // Filter options only if the length of the input is greater than or equal to 3 characters
                    if (state.inputValue.length >= 3) {
                      return options.filter((option) =>
                        option?.["meal_name"]
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }
                    return []; // Return an empty array if less than 3 characters are entered
                  }}
                  limitTags={1}
                  id="catering-select"
                  options={resultedServices}
                  value={cateringTempSelectedServices}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.["meal_name"]}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={cateringTempSelectedServices.some(
                            (e) => e.meal_id === option.meal_id
                          )}
                          onChange={() => handleCateringChange(option)}
                        />
                        {option?.["meal_name"]}
                      </li>
                    );
                  }}
                  style={{ width: "100%", marginBottom: "20px" }}
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label={searchSelectCateringText} />
                    );
                  }}
                  onChange={(e, newMeals) => {
                    setCateringTempSelectedServices(newMeals);
                  }}
                />
                <List sx={{ wordBreak: "break-word" }}>
                  {resultedServices?.map((catering) => (
                    <ListItem key={catering.meal_id} sx={{ pl: 2 }}>
                      <Checkbox
                        checked={cateringTempSelectedServices.some(
                          (e) => e.meal_id === catering.meal_id
                        )}
                        onChange={() => handleCateringChange(catering)}
                      />
                      <Typography>{catering?.["meal_name"]}</Typography>
                    </ListItem>
                  ))}
                </List>
              </div>

              {/* Fixed Footer with Button */}
              <div
                style={{
                  padding: "10px",
                  borderTop: "1px solid #ddd",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "40px",
                    color: "#ffffff",
                    backgroundColor: "#FF895D",
                    "&:hover": {
                      backgroundColor: "#f16a39",
                    },
                  }}
                  onClick={() => {
                    setAddServiceIsDialogVisible(false);
                  }}
                >
                  Go Back
                </Button>
                <Button
                  sx={{
                    width: "100%",
                    height: "40px",
                    color: "#ffffff",
                    backgroundColor: "#0cc0df",
                    "&:hover": {
                      backgroundColor: "#1da3ba",
                    },
                  }}
                  onClick={() => {
                    setTempToAddServices([...cateringTempSelectedServices]);
                    setAddServiceIsDialogVisible(false);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Dialog>
          </div>
        )}
        {Object.keys(editService).length > 0 && (
          <Dialog
            header={`Update "${editService?.meal?.meal_name}" Service`}
            visible={isEditServiceDialogVisible}
            onHide={closeEditServiceDialog}
            style={{
              maxWidth: "600px",
              overflowY: "scroll",
            }}
          >
            <div className="p-fluid">
              <div className="serviceForm">
                <form
                  id={`ServiceEditForm${props.providerTypeID}`}
                  onSubmit={formik2.handleSubmit}
                >
                  <TextField
                    select
                    name="type_id"
                    label="Select Type"
                    value={formik2.values.type_id}
                    onChange={formik2.handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    error={
                      formik2.touched.type_id && Boolean(formik2.errors.type_id)
                    }
                    helperText={
                      formik2.touched.type_id && formik2.errors.type_id
                    }
                  >
                    {cuisines.map((type) => (
                      <MenuItem key={type.type_id} value={type.type_id}>
                        {type.type_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* Min People */}
                  <TextField
                    name="min_people"
                    label="Minimum People"
                    value={formik2.values.min_people}
                    onChange={formik2.handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="number"
                    error={
                      formik2.touched.min_people &&
                      Boolean(formik2.errors.min_people)
                    }
                    helperText={
                      formik2.touched.min_people && formik2.errors.min_people
                    }
                  />

                  {/* Regular Price */}
                  <TextField
                    name="regular_price"
                    label="Regular Price"
                    value={formik2.values.regular_price}
                    onChange={formik2.handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="number"
                    error={
                      formik2.touched.regular_price &&
                      Boolean(formik2.errors.regular_price)
                    }
                    helperText={
                      formik2.touched.regular_price &&
                      formik2.errors.regular_price
                    }
                  />

                  {/* Discounted Price */}
                  <TextField
                    name="discounted_price"
                    label="Discounted Price"
                    value={formik2.values.discounted_price}
                    onChange={formik2.handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="number"
                    error={
                      formik2.touched.discounted_price &&
                      Boolean(formik2.errors.discounted_price)
                    }
                    helperText={
                      formik2.touched.discounted_price &&
                      formik2.errors.discounted_price
                    }
                  />

                  {/* Valid Until Calendar */}
                  <Calendar
                    baseZIndex={1100}
                    view="date"
                    minDate={new Date()}
                    showButtonBar
                    showIcon
                    dateFormat="dd-M-yy"
                    value={formik2.values.valid_until}
                    onChange={(e) =>
                      formik2.setFieldValue("valid_until", e.value)
                    }
                    placeholder="Valid Until"
                    style={{ marginTop: "10px" }}
                    panelStyle={{ zIndex: 1100 }}
                  />
                  <FileUpload
                    ref={fileInputRef}
                    mode="basic"
                    name="menu_upload"
                    accept="image/*"
                    chooseLabel="Select New Menu"
                    customUpload
                    auto
                    maxFileSize={MAX_FILE_SIZE}
                    multiple={false} // Ensures only one file can be selected
                    onSelect={onUpload}
                    style={{ marginTop: "15px", width: "100%" }}
                  />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      padding: "20px 0",
                    }}
                  >
                    {formik2.values?.image_ids?.[0] ? (
                      <>
                        <i
                          className="pi pi-times-circle"
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "-10px",
                            fontSize: "23px",
                            cursor: "pointer",
                            borderRadius: "100%",
                            background: "#ff895d",
                            color: "#fff", // Optional: Change color if needed
                          }}
                          onClick={() => {
                            formik2.setFieldValue("image_ids", []);
                          }}
                        />
                        <img
                          src={`${config.API_BASE_URL}/gallery/download/${formik2.values?.image_ids?.[0]}`}
                          width={"200px"}
                          alt={formik2.values.type_id}
                          style={{ display: "block" }}
                        />
                      </>
                    ) : (
                      <>
                        <i className="pi pi-image" />
                        <br />
                        <span>Menu Not Available</span>
                      </>
                    )}
                  </div>

                  <Button
                    type="submit"
                    sx={{
                      width: "100%",
                      height: "40px",
                      color: "#ffffff",
                      marginTop: "10px",
                      backgroundColor: "#0cc0df",
                      "&:hover": {
                        backgroundColor: "#1da3ba",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
                {/* Form Fields */}
              </div>
            </div>
          </Dialog>
        )}
      </Box>
      <div className="services-selection">
        {tempToAddServices && tempToAddServices.length > 0 ? (
          <EditCateringServiceForm
            cuisines={cuisines}
            mealTypes={mealTypes}
            services={services}
            resultedServices={resultedServices}
            setServices={setServices}
            fetchProviderServices={fetchProviderServices}
            setTempToAddServices={setTempToAddServices}
            setCateringTempSelectedServices={setCateringTempSelectedServices}
          />
        ) : (
          <></>
        )}
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{ display: { xs: "none", md: "table-cell" } }}
                >
                  Service
                </StyledTableCell>
                {/* <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Veg/Non-Veg
                </StyledTableCell> */}
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Included Items
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Cuisine
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Min people
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Price
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Discount Price
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "255px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Discount valid upto
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "255px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Menu Image
                </StyledTableCell>
                <StyledTableCell
                  sx={{ display: { xs: "none", md: "table-cell" } }}
                  align="center"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerServices.length > 0 ? (
                // Step 1: Sort and group the services by category (Veg/Non-Veg)
                Object.entries(
                  providerServices
                    .sort((a, b) => {
                      const [aCategory, aMeal] = sortItems(a);
                      const [bCategory, bMeal] = sortItems(b);

                      // Compare first by category, then by meal
                      if (aCategory !== bCategory) {
                        return aCategory - bCategory; // Veg before Non-Veg
                      }
                      return aMeal - bMeal; // Sort by the specific meal order
                    })
                    .reduce((acc, s) => {
                      const categoryName = s.category.category_name;

                      // If the category header is not yet added to the accumulator, add it
                      if (!acc[categoryName]) {
                        acc[categoryName] = [];
                      }

                      // Push the service into the respective category array
                      acc[categoryName].push(s);

                      return acc;
                    }, {})
                ).map(([categoryName, services]) => {
                  return (
                    <>
                      {/* Category Header Row */}
                      <TableRow>
                        <TableCell colSpan={12}>
                          <h3 style={{ textAlign: "center", padding: "15px" }}>
                            {categoryName}
                          </h3>
                        </TableCell>
                      </TableRow>
                      {/* Mapping services for each category */}
                      {services.map((s) => {
                        return (
                          <TableRow
                            className="td-header-row"
                            key={s.item_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              className="td-header-top"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              <span className="td-header">Service</span>
                              {s.meal.meal_name}
                            </TableCell>
                            {/* <TableCell>
                              <span className="td-header">Veg/Non-Veg</span>
                              {s.category.category_name === "Veg" ? (
                                <div
                                  className="box"
                                  style={{
                                    border: "3px solid green",
                                  }}
                                >
                                  <div
                                    className="circle"
                                    style={{
                                      background: "green",
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                <div
                                  className="box"
                                  style={{
                                    border: "3px solid #ff895d",
                                  }}
                                >
                                  <div
                                    className="circle"
                                    style={{
                                      background: "#ff895d",
                                    }}
                                  ></div>
                                </div>
                              )}
                            </TableCell> */}
                            <TableCell>
                              <i
                                className="pi pi-info-circle text-xl"
                                style={{
                                  color: "#ff895d",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCurrentMealType(s.meal.meal_name);
                                  setShowIncludedItems(true);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <span className="td-header">Cuisine</span>
                              {s.type.type_name}
                            </TableCell>
                            <TableCell>
                              <span className="td-header">Min people</span>
                              {s.min_people}
                            </TableCell>
                            <TableCell>
                              <span className="td-header">Price</span>
                              {s.regular_price}
                            </TableCell>
                            <TableCell>
                              <span className="td-header">Discount Price</span>
                              {s.discounted_price ? s.discounted_price : "NA"}
                            </TableCell>
                            <TableCell>
                              <span className="td-header">
                                Discount valid upto
                              </span>
                              {s.valid_until
                                ? format(
                                    new Date(s.valid_until),
                                    "MMMM dd, yyyy"
                                  )
                                : "NA"}
                            </TableCell>
                            <TableCell>
                              <span className="td-header">Menu Image</span>
                              {s.gallery?.[0]?.id ? (
                                <>
                                  <img
                                    key={s.item_id}
                                    src={`${config.API_BASE_URL}/gallery/download/${s.gallery?.[0]?.id}`}
                                    width={"100px"}
                                    alt={s.meal.meal_name}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setShowImage(true);
                                      setImageData(s);
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <i className="pi pi-image" />
                                  <br />
                                  <span>Menu Not Available</span>
                                </>
                              )}
                            </TableCell>
                            <TableCell sx={{ width: "76px" }}>
                              <span className="td-header">Action</span>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <div
                                  className="action edit"
                                  onClick={() => handleEdit(s)}
                                >
                                  <EditTwoToneIcon />
                                </div>
                                <div
                                  className="action delete"
                                  onClick={() =>
                                    deleteServiceConfirm(s.item_id)
                                  }
                                  aria-controls={"dlg_confirmation"}
                                  aria-expanded={true}
                                >
                                  <CloseTwoToneIcon />
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <h3 style={{ textAlign: "center", padding: "15px" }}>
                      {`No ${props.providerLabel} services found`}
                    </h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {/* <TableBody>
              {providerServices.length > 0 ? (
                providerServices
                  ?.sort((a, b) => {
                    const [aCategory, aMeal] = sortItems(a);
                    const [bCategory, bMeal] = sortItems(b);

                    // Compare first by category, then by meal
                    if (aCategory !== bCategory) {
                      return aCategory - bCategory; // Veg before Non-Veg
                    }
                    return aMeal - bMeal; // Sort by the specific meal order
                  })
                  .map((s) => {
                    return (
                      // Render for second data format (non-priest services)
                      <TableRow
                        className="td-header-row"
                        key={s.item_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="td-header-top"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          <span className="td-header">Service</span>
                          {s.meal.meal_name}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Veg/Non-Veg</span>
                          {s.category.category_name === "Veg" ? (
                            <div
                              className="box"
                              style={{
                                border: "3px solid green",
                              }}
                            >
                              <div
                                className="circle"
                                style={{
                                  background: "green",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div
                              className="box"
                              style={{
                                border: "3px solid #ff895d",
                              }}
                            >
                              <div
                                className="circle"
                                style={{
                                  background: "#ff895d",
                                }}
                              ></div>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Cuisine</span>
                          {s.type.type_name}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Min people</span>
                          {s.min_people}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Price</span>
                          {s.regular_price}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Discount Price</span>
                          {s.discounted_price ? s.discounted_price : "NA"}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Discount valid upto</span>
                          {s.valid_until
                            ? format(new Date(s.valid_until), "MMMM dd, yyyy")
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Menu Image</span>
                          {s.gallery?.[0]?.id ? (
                            <>
                              <img
                                key={s.item_id}
                                src={`${config.API_BASE_URL}/gallery/download/${s.gallery?.[0]?.id}`}
                                width={"100px"}
                                alt={s.meal.meal_name}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowImage(true);
                                  setImageData(s);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <i className="pi pi-image" />
                              <br />
                              <span>Menu Not Available</span>
                            </>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "76px" }}>
                          <span className="td-header">Action</span>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div
                              className="action edit"
                              onClick={() => handleEdit(s)}
                            >
                              <EditTwoToneIcon />
                            </div>
                            <div
                              className="action delete"
                              onClick={() => deleteServiceConfirm(s.item_id)}
                              aria-controls={"dlg_confirmation"}
                              aria-expanded={true}
                            >
                              <CloseTwoToneIcon />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <h3
                      style={{ textAlign: "center", padding: "15px" }}
                    >{`No ${props.providerLabel} services found`}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody> */}
          </Table>
        </TableContainer>
        {loading && <div className="loading"></div>}
      </div>
    </>
  );
}

export default CateringService;
