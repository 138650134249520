import React, { useEffect, useState, useCallback, useRef } from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { fetchServices } from "../../Apiservices/Auth/servers/fetchServices";
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import "./ServiceProviderDashboard.css";
import { Toast } from "primereact/toast";
import {
  updateServiceProviderServices,
  addServiceProviderServices,
  editServiceProviderServices,
  addPriestEventServices,
  deletePriestEventServices,
} from "../../Apiservices/Auth/servers/updateServiceProviderServices";
import {
  getPriestEvents,
  getPriestProviderEvents,
  getServiceProviderServices,
} from "../../Apiservices/Auth/servers/fetchProviderData";
import { InputNumber } from "primereact/inputnumber";
import { Button as PrimeButton } from "primereact/button";
import CollapseExpandTree from "../CustomComponents/CollapseExpandTree";

function ServicesList(props) {
  const [providerServices, setProviderServices] = useState([]);
  const [resultedServices, setResultedServices] = useState([]);

  const isPriest = props.providerLabel === "Priest";
  const [priestTempSelectedEvents, setPriestTempSelectedEvents] = useState([]);

  const [services, setServices] = useState([]);
  const [editService, setEditService] = useState({});
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const oneYearFromToday = new Date(
    new Date().setFullYear(today.getFullYear() + 1)
  );
  const [tempToAddServices, setTempToAddServices] = useState([]);

  const [isAddServiceDialogVisible, setAddServiceIsDialogVisible] =
    useState(false);
  const [isEditServiceDialogVisible, setEditServiceIsDialogVisible] =
    useState(false);

  const openAddServiceDialog = () => {
    setAddServiceIsDialogVisible(true);
  };

  const closeAddServiceDialog = () => {
    setTempToAddServices([]);
    setPriestTempSelectedEvents([]);
    setAddServiceIsDialogVisible(false);
  };

  const closeEditServiceDialog = () => {
    setEditService({});
    setEditServiceIsDialogVisible(false);
  };

  function subtractArraysOfObjects(array1, array2) {
    const array2Keys = array2.map((obj) => obj["service_master_id"]);
    return array1.filter((obj) => !array2Keys.includes(obj["id"]));
  }

  const resultedServicesFunc = useCallback(async () => {
    setLoading(true);
    try {
      let providerData;

      if (isPriest) {
        const tempData = await getPriestProviderEvents();
        providerData = tempData ? tempData.data : [];
      } else {
        const tempData = await getServiceProviderServices();
        providerData = tempData ? tempData.services : [];
      }

      let serviceList;

      if (isPriest) {
        serviceList = await getPriestEvents();
        serviceList = serviceList.data;
      } else {
        serviceList = await fetchServices(props.providerTypeID);
      }

      if (providerData !== undefined) {
        setProviderServices(providerData);
        let enabledServices;
        let result;

        if (isPriest) {
          enabledServices = [...providerData];

          result = serviceList.filter(
            (service) =>
              !enabledServices.some(
                (enabled) => enabled.categoryId === service.id
              )
          );
        } else {
          enabledServices = providerData.filter((item) => {
            return item.serviceMaster.providerTypeId === props.providerTypeID;
          });

          result = await subtractArraysOfObjects(serviceList, enabledServices);
        }

        setResultedServices(result);
      } else {
        setResultedServices(serviceList);
      }
    } catch (error) {
      console.error("Failed to services:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    resultedServicesFunc();
  }, [resultedServicesFunc]);

  const handleDelete = async (itemId) => {
    let deleteServices;
    if (isPriest) {
      deleteServices = {
        eventIds: [itemId],
      };
    } else {
      deleteServices = JSON.stringify({
        toAddService: [],
        toDeleteService: [itemId],
      });
    }

    setLoading(true);
    try {
      if (isPriest) {
        await deletePriestEventServices(deleteServices);
      } else {
        await updateServiceProviderServices(deleteServices);
      }
    } catch (error) {
      console.error("Failed to services:", error);
    } finally {
      setLoading(false);
      resultedServicesFunc();
    }
  };

  const deleteServiceConfirm = (serviceID) => {
    confirmDialog({
      message: "Do you want to delete this service?",
      header: "Delete Confirmation",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(serviceID),
      reject,
    });
  };

  const reject = () => {};

  const handleEdit = async (obj) => {
    setEditService(obj);
    setEditServiceIsDialogVisible(true);
  };

  useEffect(() => {
    setServices((prevServices) => {
      return tempToAddServices.map((service) => {
        const tempService = prevServices.find(
          (prevService) => prevService.id === service.id
        );

        if (tempService) {
          return {
            ...tempService,
            price: service.price,
            discountPrice: service.discountPrice,

            discountValidUpto: service.discountValidUpto
              ? new Date(service.discountValidUpto).toISOString()
              : null,
          };
        } else {
          return service;
        }
      });
    });
  }, [tempToAddServices]);

  const EditServiceTabularForm = React.memo(() => {
    const toast = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [
      isEditServiceTabularFormLoading,
      setIsEditServiceTabularFormLoading,
    ] = useState(false);

    const showErrorMessage = (message) => {
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage(""); // Clear error after 3 seconds
      }, 3000);
    };

    const handlePriceChange = (index, value) => {
      const newServices = [...services];
      newServices[index].price = value;
      setServices(newServices);
    };

    const handleDiscountPriceChange = (index, value) => {
      const newServices = [...services];
      newServices[index].discountPrice = value;
      setServices(newServices);
    };

    const handleDateChange = (index, value) => {
      const newServices = [...services];
      newServices[index].discountValidUpto = value;
      setServices(newServices);
    };

    const handleRemove = (index) => {
      const updatedServices = services.filter((_, i) => i !== index);
      setServices(updatedServices);
      setTempToAddServices(updatedServices); // Update the parent state
    };

    const handleSubmit = async () => {
      // Validate if all services have prices
      setErrorMessage("");
      setIsEditServiceTabularFormLoading(true);
      const hasMissingPrices = services.some(
        (service) => !service.price && service.price !== 0 // Ensure price is not null, undefined, or blank
      );
      if (hasMissingPrices) {
        const missingServices = services
          .filter((service) => !service.price && service.price !== 0)
          .map((service) => service[isPriest ? "name" : "serviceName"]); // Log only service names
        console.log(
          "❌ Error: Some services are missing prices:",
          missingServices
        );
        showErrorMessage(
          `Some services are missing prices: ${missingServices.join(", ")}`
        );
        setIsEditServiceTabularFormLoading(false);
        return;
      }

      // Validate if discountPrice is valid and check discountValidUpto
      const invalidDiscounts = services.filter((service) => {
        const price = parseFloat(service.price || 0); // Safely convert price
        const discountPrice = parseFloat(service.discountPrice || 0); // Safely convert discountPrice

        if (discountPrice > 0 && discountPrice > price) {
          return true; // Discount price exceeds the regular price
        }

        if (
          service.discountValidUpto &&
          new Date(service.discountValidUpto).setHours(0, 0, 0, 0) <=
            new Date().setHours(0, 0, 0, 0) // Check if date is today or in the past
        ) {
          return true; // Discount valid date is not in the future
        }

        if (
          (discountPrice > 0 && !service.discountValidUpto) ||
          (!discountPrice && service.discountValidUpto)
        ) {
          return true; // Discount price is set but discountValidUpto is not
        }

        return false;
      });

      if (invalidDiscounts.length > 0) {
        console.log(
          "❌ Error: Some services have invalid discount prices or dates:",
          invalidDiscounts.map(
            (service) => service?.[isPriest ? "name" : "serviceName"]
          ) // Log only service names
        );

        showErrorMessage(
          `Some services have invalid discount prices or dates: ${invalidDiscounts
            .map((service) => service?.[isPriest ? "name" : "serviceName"])
            .join(", ")}`
        );

        setIsEditServiceTabularFormLoading(false);
        return;
      }

      // Prepare the request payload
      const payload = {
        [isPriest ? "mappings" : "services"]: services.map((service) => ({
          [isPriest ? "eventId" : "serviceId"]: service.id, // Ensure this is available in your data
          price: service.price ? service.price.toString() : null, // Convert to string
          discountPrice: service.discountPrice
            ? service.discountPrice.toString()
            : null,
          discountValidUpto: service.discountValidUpto
            ? new Date(service.discountValidUpto).toISOString()
            : null,
        })),
      };

      try {
        let response;

        if (isPriest) {
          response = await addPriestEventServices(payload);
        } else {
          response = await addServiceProviderServices(payload);
        }

        if (response?.success && response?.details?.errors?.length === 0) {
          console.log("✅ Services added successfully:", response);
          setServices([]);
          setTempToAddServices([]);
          setResultedServices([]);
          setPriestTempSelectedEvents([]);
        } else {
          console.error("❌ Error adding services:", response);
          showErrorMessage("Failed to add services. Please try again.");
        }
      } catch (error) {
        console.error("❌ Error adding services:", error);
        showErrorMessage("Failed to add services. Please try again.");
      } finally {
        setIsEditServiceTabularFormLoading(false);
        resultedServicesFunc();
      }
    };

    const priceBodyTemplate = (rowData, { rowIndex }) => {
      return (
        <InputNumber
          value={rowData.price}
          onValueChange={(e) => handlePriceChange(rowIndex, e.value)}
          mode="decimal"
          min={0}
          step={1.0}
          showButtons
          placeholder="Price"
        />
      );
    };

    const discountPriceBodyTemplate = (rowData, { rowIndex }) => {
      return (
        <InputNumber
          value={rowData.discountPrice}
          onValueChange={(e) => handleDiscountPriceChange(rowIndex, e.value)}
          mode="decimal"
          min={0}
          step={1.0}
          showButtons
          placeholder="Discount Price"
        />
      );
    };

    const discountValidUptoBodyTemplate = (rowData, { rowIndex }) => {
      return (
        <Calendar
          value={rowData.discountValidUpto}
          showButtonBar
          onClearButtonClick={() => handleDateChange(rowIndex, null)}
          onChange={(e) => handleDateChange(rowIndex, e.value)}
          showIcon
          minDate={new Date()}
          dateFormat="dd/mm/yy"
          placeholder="Discount Valid Upto"
        />
      );
    };

    const deleteButtonBodyTemplate = (rowData, { rowIndex }) => {
      return (
        <PrimeButton
          icon="pi pi-times"
          className="p-button-danger p-button-text"
          onClick={() => handleRemove(rowIndex)}
          tooltip="Remove"
        />
      );
    };

    return (
      <div className="p-d-flex p-jc-center p-mt-5">
        <div className="p-card p-shadow-4" style={{ padding: "2rem" }}>
          <Toast ref={toast} />
          {isEditServiceTabularFormLoading ? (
            <div className="p-d-flex p-jc-center">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
              {/* You can replace this with a custom spinner or any other loading indicator */}
            </div>
          ) : (
            <DataTable
              value={services}
              paginator
              rows={5}
              style={{ marginBottom: "2rem" }}
            >
              <Column
                field={isPriest ? "name" : "serviceName"}
                header={"Service Name"}
              />
              <Column
                header="Price"
                body={priceBodyTemplate}
                // Ensure fixed width for the column
              />
              <Column
                header="Discount Price"
                body={discountPriceBodyTemplate}
              />
              <Column
                header="Discount Valid Upto"
                body={discountValidUptoBodyTemplate}
              />
              <Column
                header="Actions"
                body={deleteButtonBodyTemplate}
                style={{ textAlign: "center", width: "100px" }}
              />
            </DataTable>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {errorMessage && (
              <span
                className="blink"
                style={{
                  color: "red",
                  marginRight: "1rem",
                  alignSelf: "center",
                  fontSize: "0.9rem",
                }}
              >
                {errorMessage}
              </span>
            )}
            <PrimeButton
              label="Submit"
              icon="pi pi-check"
              onClick={handleSubmit}
              className="p-button-success"
            />
          </div>
        </div>
      </div>
    );
  });

  function removeBlankAttributes(obj) {
    const result = {};
    for (const key in obj) {
      if (obj[key] !== "" && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  function replaceEmptyWithNull(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (
          (typeof value === "string" && value.trim() === "") ||
          value === undefined
        ) {
          newObj[key] = null;
        } else {
          newObj[key] = value;
        }
      }
    }

    return newObj;
  }

  const formik = useFormik({
    initialValues: {
      serviceId: "",
      price: "",
      discountPrice: "",
      discountValidUpto: "",
    },
    validationSchema: Yup.object({
      serviceId: Yup.string().required("Service is required"),
      price: Yup.number()
        .integer("Price should be number")
        .required("Price is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const altered = removeBlankAttributes(values);
      const services = [altered];
      const activateServices = JSON.stringify({ services });
      //console.log("activateServices", activateServices)
      setLoading(true);
      try {
        const response = await addServiceProviderServices(activateServices);
      } catch (error) {
        console.error("Failed to services:", error);
      } finally {
        setLoading(false);
        resultedServicesFunc();
        setAddServiceIsDialogVisible(false);
        setEditServiceIsDialogVisible(false);
        resetForm();
      }
    },
  });

  const formik2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceId: editService?.service_master_id,
      price: editService?.price ? Number(editService?.price) : "",
      discountPrice: editService?.discountPrice
        ? Number(editService?.discountPrice)
        : "",
      discountValidUpto: editService?.discountValidUpto
        ? new Date(editService?.discountValidUpto)
        : "",
    },
    validationSchema: Yup.object({
      price: Yup.number()
        .typeError("Price should be a number") // Prevents alphabets or other invalid inputs
        .integer("Price should be an integer")
        .required("Price is required"),

      discountPrice: Yup.number()
        .typeError("Discount price should be a number") // Prevents alphabets or other invalid inputs
        .integer("Discount should be an integer")
        .test(
          "is-less-than-price",
          "Discount price should be less than price",
          function (value) {
            return !value || value < this.parent.price; // Ensures discountPrice < price
          }
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (isPriest) {
        values.eventId = editService?.eventId;
      } else {
        values.serviceId = editService?.service_master_id;
      }
      const altered = replaceEmptyWithNull(values);
      const services = [altered];
      const editServices = JSON.stringify({
        [isPriest ? "mappings" : "services"]: services,
      });

      setLoading(true);
      try {
        if (isPriest) {
          await addPriestEventServices(editServices);
        } else {
          await editServiceProviderServices(editServices);
        }
      } catch (error) {
        console.error("Failed to services:", error);
      } finally {
        setLoading(false);
        resultedServicesFunc();
        setAddServiceIsDialogVisible(false);
        setEditServiceIsDialogVisible(false);
        resetForm();
      }
    },
  });

  const handleFieldChange2 = (field) => (event) => {
    const value = event.target.value;
    formik2.setFieldValue(field, value);
    //updateLocalStorage({ ...formik.values, [field]: value });
  };

  const textFieldStyles = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "4px",
    marginBottom: "10px",
  };
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0cc0df",
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: 700,
    },
  }));

  return (
    <>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          gap: "20px",
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <div>
          <h4>{props.providerLabel}</h4>
          <div className="desc">{props.providerDesc}</div>
        </div>
        {resultedServices.length > 0 && (
          <div>
            <Button
              size="small"
              onClick={openAddServiceDialog}
              startIcon={<AddTwoToneIcon />}
              sx={{
                fontWeight: "700",
                padding: "4px 16px",
                color: "#ffffff",
                border: "2px solid #0cc0df",
                backgroundColor: "#0cc0df",
                "&:hover": {
                  borderColor: "#1da3ba",
                  backgroundColor: "#1da3ba",
                },
              }}
            >
              Add New Service
            </Button>
            <Dialog
              header="Select Services to Add"
              visible={isAddServiceDialogVisible}
              onHide={closeAddServiceDialog}
              style={{
                maxWidth: "600px",
                height: "500px", // Fixed height for the dialog
              }}
              contentStyle={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden", // Prevents the dialog itself from growing
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: "auto", // Enables vertical scrolling for the content
                  padding: "10px",
                }}
              >
                {isPriest ? (
                  <>
                    <CollapseExpandTree
                      data={resultedServices}
                      preSelectedData={tempToAddServices}
                      onSelectionChange={setPriestTempSelectedEvents}
                      categoryChildListName="events"
                      categoryName="name"
                    />
                  </>
                ) : (
                  <CollapseExpandTree
                    data={resultedServices}
                    preSelectedData={tempToAddServices}
                    onSelectionChange={setPriestTempSelectedEvents}
                    categoryChildListName="children"
                    categoryName="serviceName"
                  />
                )}
              </div>

              {/* Fixed Footer with Button */}
              <div
                style={{
                  padding: "10px",
                  borderTop: "1px solid #ddd",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "40px",
                    color: "#ffffff",
                    backgroundColor: "#FF895D",
                    "&:hover": {
                      backgroundColor: "#f16a39",
                    },
                  }}
                  onClick={() => {
                    setAddServiceIsDialogVisible(false);
                  }}
                >
                  Go Back
                </Button>
                <Button
                  sx={{
                    width: "100%",
                    height: "40px",
                    color: "#ffffff",
                    backgroundColor: "#0cc0df",
                    "&:hover": {
                      backgroundColor: "#1da3ba",
                    },
                  }}
                  onClick={() => {
                    setTempToAddServices([...priestTempSelectedEvents]);
                    setAddServiceIsDialogVisible(false);
                  }}
                >
                  Submit
                </Button>
              </div>
            </Dialog>
          </div>
        )}
        {Object.keys(editService).length > 0 && (
          <Dialog
            header={`Update "${
              isPriest
                ? editService?.eventName
                : editService?.serviceMaster?.serviceName
            }" Service`}
            visible={isEditServiceDialogVisible}
            onHide={closeEditServiceDialog}
            style={{
              minWidth: "600px",
            }}
          >
            <div className="p-fluid">
              <div className="serviceForm">
                {/* Form Fields */}
                <form
                  id={`ServiceEditForm${props.providerTypeID}`}
                  onSubmit={formik2.handleSubmit}
                >
                  <TextField
                    name="price"
                    label="Enter Price"
                    value={formik2.values.price}
                    onChange={handleFieldChange2("price")} // Use common change handler
                    size="small"
                    variant="outlined"
                    type="number"
                    sx={textFieldStyles}
                    error={
                      formik2.touched.price && Boolean(formik2.errors.price)
                    }
                    helperText={formik2.touched.price && formik2.errors.price}
                  />
                  <TextField
                    name="discountPrice"
                    label="Enter Discount Price"
                    value={formik2.values.discountPrice}
                    onChange={handleFieldChange2("discountPrice")} // Use common change handler
                    size="small"
                    variant="outlined"
                    type="number"
                    sx={textFieldStyles}
                    error={
                      formik2.touched.discountPrice &&
                      Boolean(formik2.errors.discountPrice)
                    }
                    helperText={
                      formik2.touched.discountPrice &&
                      formik2.errors.discountPrice
                    }
                  />
                  {formik2.values.discountPrice && (
                    <Calendar
                      baseZIndex={1100}
                      view="date"
                      minDate={today}
                      maxDate={oneYearFromToday}
                      showButtonBar
                      showIcon
                      dateFormat="dd-M-yy"
                      value={formik2.values.discountValidUpto}
                      defaultDate={formik2.values.discountValidUpto}
                      onChange={(e) => {
                        formik2.setFieldValue(
                          "discountValidUpto",
                          e.value ? e.value : ""
                        );
                      }}
                      placeholder="Discount Valid upto"
                      panelStyle={{ zIndex: 1100 }}
                    />
                  )}
                  <Button
                    type="submit"
                    sx={{
                      width: "100%",
                      height: "40px",
                      color: "#ffffff",
                      marginTop: "10px",
                      backgroundColor: "#0cc0df",
                      "&:hover": {
                        backgroundColor: "#1da3ba",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </form>
                {/* Form Fields */}
              </div>
            </div>
          </Dialog>
        )}
      </Box>
      <div className="services-selection">
        {/* <ServiceTable></ServiceTable> */}
        {tempToAddServices && tempToAddServices.length > 0 && (
          <EditServiceTabularForm />
        )}
        <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{ display: { xs: "none", md: "table-cell" } }}
                >
                  Service
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Price
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "210px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Discount Price
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "255px",
                    display: { xs: "none", md: "table-cell" },
                  }}
                >
                  Discount valid upto
                </StyledTableCell>
                <StyledTableCell
                  sx={{ display: { xs: "none", md: "table-cell" } }}
                  align="center"
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providerServices.length > 0 &&
              providerServices.find((item) =>
                isPriest
                  ? item.events.length > 0
                  : item.serviceMaster.providerTypeId === props.providerTypeID
              ) ? (
                providerServices
                  .filter((s) =>
                    isPriest
                      ? s.events.length > 0
                      : s.serviceMaster.providerTypeId === props.providerTypeID
                  )
                  .map((s) => {
                    return isPriest ? (
                      // Render for first data format (priest-required events)
                      s.events.map((event) => (
                        <TableRow
                          className="td-header-row"
                          key={event.eventId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="td-header-top"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {event.eventName}
                            <br />
                            <small>{event.description}</small>
                          </TableCell>
                          <TableCell>
                            <span className="td-header">Price</span>
                            {event.price ? event.price : "NA"}
                          </TableCell>
                          <TableCell>
                            <span className="td-header">Discount Price</span>
                            {event.discountPrice ? event.discountPrice : "NA"}
                          </TableCell>
                          <TableCell>
                            <span className="td-header">
                              Discount valid upto
                            </span>
                            {event.discountValidUpto
                              ? format(
                                  new Date(event.discountValidUpto),
                                  "MMMM dd, yyyy"
                                )
                              : "NA"}
                          </TableCell>
                          <TableCell sx={{ width: "76px" }}>
                            <span className="td-header">Action</span>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <div
                                className="action edit"
                                onClick={() => handleEdit(event)}
                              >
                                <EditTwoToneIcon />
                              </div>
                              <div
                                className="action delete"
                                onClick={() =>
                                  deleteServiceConfirm(event.eventId)
                                }
                                aria-controls={"dlg_confirmation"}
                                aria-expanded={true}
                              >
                                <CloseTwoToneIcon />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      // Render for second data format (non-priest services)
                      <TableRow
                        className="td-header-row"
                        key={s.serviceMaster.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="td-header-top"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {s.serviceMaster.serviceName}
                          <br />
                          <small>{s.serviceMaster.description}</small>
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Price</span>
                          {s.price}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Discount Price</span>
                          {s.discountPrice ? s.discountPrice : "NA"}
                        </TableCell>
                        <TableCell>
                          <span className="td-header">Discount valid upto</span>
                          {s.discountValidUpto
                            ? format(
                                new Date(s.discountValidUpto),
                                "MMMM dd, yyyy"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell sx={{ width: "76px" }}>
                          <span className="td-header">Action</span>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div
                              className="action edit"
                              onClick={() => handleEdit(s)}
                            >
                              <EditTwoToneIcon />
                            </div>
                            <div
                              className="action delete"
                              onClick={() =>
                                deleteServiceConfirm(s.serviceMaster.id)
                              }
                              aria-controls={"dlg_confirmation"}
                              aria-expanded={true}
                            >
                              <CloseTwoToneIcon />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={6}>
                    <h3
                      style={{ textAlign: "center", padding: "15px" }}
                    >{`No ${props.providerLabel} services found`}</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <div className="loading"></div>}
      </div>
    </>
  );
}

export default ServicesList;
