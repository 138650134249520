import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Events from "../Events.json";
import { fetchAllEventCategories } from "../Apiservices/Auth/servers/fetchCategoryEvents";
import { useTransition } from "react";

function MiddleContent() {
  const [eventCategories, setEventCategories] = useState([]);
  const [isPending, startTransition] = useTransition();

  const loadEventCategories = async () => {
    startTransition(async () => {
      const response = await fetchAllEventCategories();
      if (response) {
        setEventCategories(response);
      }
    });
  };

  useEffect(() => {
    loadEventCategories();
  }, []);

  /* const navigate = useNavigate();

    const handleEvent = (event) => {
        const info = {
            eventInfo: event
        }
        navigate("/event", { state: info });
        window.scrollTo({ top: 0 });
    } */

  return (
    <div className="middle-content">
      <div className="frontend-container">
        <div style={{ width: "100%" }}>
          <h2 className="font-legal">
            Don't remember or Not sure what that event is called? - Find it from
            the category below
          </h2>
          <div className="middle-content-services">
            {isPending ? (
              <h3 style={{ textAlign: "center" }}>
                <i
                  className="pi pi-spin pi-spinner-dotted"
                  style={{ fontSize: "2rem", color: "#0cc0df" }}
                ></i>
              </h3>
            ) : (
              eventCategories?.map((item) => (
                <Link
                  key={item.id}
                  className="event-service sel-event"
                  to={`/event/${item.name
                    ?.toLowerCase()
                    ?.replaceAll(" ", "-")}`}
                >
                  <div className="event-service-head">
                    <div>
                      <div>
                        <h3 className="font-legal">{item.name}</h3>
                        <div className="event-rsvp">
                          {item.shortDescription}
                        </div>
                      </div>
                    </div>
                    <div className="pi pi-chevron-right"></div>
                  </div>
                </Link>
              ))
            )}
          </div>
          {/* <ul className="features">
              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Browse and Order Easily:</strong> Our app allows you to explore a variety of rental options and place your order in just a few clicks.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Connect with Trusted Suppliers:</strong> We partner with reliable suppliers to ensure you receive top-notch service and products.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Save Time and Effort:</strong> No more endless searches or complicated booking processes – Thurent.com streamlines everything for you.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Enjoy Your Events:</strong> Focus on creating memorable moments with your guests while we take care of the logistics</span></li>
            </ul> */}
        </div>
      </div>
    </div>
  );
}

export default MiddleContent;
