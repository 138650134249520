import React, { useEffect, useState } from "react";
import { CssBaseline, Stack, IconButton } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Footer from "./Footer";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import ProviderServices from "./ProviderServices";
import "./eventComponent/EventTabs/EventTabs.css";
import CreateEventCTA from "./EventServices/CreateEventCTA";
import { homePagePath } from "../constant";
import { fetchAllEventCategoriesWithEvents } from "../Apiservices/Auth/servers/fetchCategoryEvents";
import EventForm from "./eventComponent/EventForm2";
import { Dialog } from "primereact/dialog";
import { useTransition } from "react";

export const Eventspage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { slug } = useParams();

  const [event, setEvent] = useState();
  const [eventCategories, setEventCategories] = useState([]);

  const [visible, setVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();

  const [isPending, startTransition] = useTransition();

  const loadEventCategories = async () => {
    startTransition(async () => {
      const response = await fetchAllEventCategoriesWithEvents();
      if (response) {
        setEvent(
          response?.find(
            (event) => event.name?.toLowerCase()?.replaceAll(" ", "-") === slug
          )
        );
        setEventCategories(response);
      }
    });
  };

  useEffect(() => {
    loadEventCategories();
  }, []);

  useEffect(() => {
    setEvent(
      eventCategories?.find(
        (event) => event.name?.toLowerCase()?.replaceAll(" ", "-") === slug
      )
    );
  }, [slug]);

  const backtoHome = () => {
    isLoggedIn ? navigate("/dashboard") : navigate(homePagePath);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [slug]);

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
      </Stack>
      <div className={`main-container ${!isLoggedIn && "no-auth"}`}>
        <div className="frontend-container">
          <div style={{ width: "100%", paddingBottom: "30px" }}>
            {isPending || !event || !eventCategories ? (
              <h3 style={{ textAlign: "center" }}>
                <i
                  className="pi pi-spin pi-spinner-dotted"
                  style={{ fontSize: "2rem", color: "#0cc0df" }}
                ></i>
              </h3>
            ) : (
              <>
                <div className="event-form-inner">
                  <div className="event-form-body list">
                    <div className="event-form-body-inner">
                      <div className="event-form-head">
                        <div className="event-service-head-inner">
                          <IconButton
                            onClick={backtoHome}
                            style={{
                              backgroundColor: "#0cc0df",
                              color: "#fff",
                              padding: "8px",
                            }}
                          >
                            <KeyboardBackspaceOutlinedIcon />
                          </IconButton>
                          <div>
                            <h1>{event?.name}</h1>
                            <div className="event-rsvp">
                              {event?.shortDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event?.longDescription,
                        }}
                        style={{
                          paddingBottom: "30px",
                        }}
                      ></div>

                      <div className="middle-content-services">
                        <h1>
                          Plan your event with ease—select the one that suits
                          your needs:
                        </h1>
                        {event?.events?.map((event) => (
                          <div
                            className="event-service event-category sel-event"
                            onClick={() => {
                              setVisible(true);
                              setSelectedEvent(event);
                            }}
                          >
                            <div className="event-service-head">
                              <div>
                                <div>
                                  <h3>{event.name}</h3>
                                </div>
                              </div>
                              <div className="pi pi-chevron-right"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Dialog
                        visible={visible}
                        onHide={() => setVisible(false)}
                        header={<>Create event for {selectedEvent?.name}</>}
                      >
                        <EventForm
                          preSelectedEvent={selectedEvent}
                          onCloseDailog={() => {
                            setVisible(false);
                            navigate("/dashboard");
                          }}
                        />
                      </Dialog>
                    </div>
                    <div className="event-service-list sidebar">
                      <CreateEventCTA />
                      {eventCategories.length > 0 &&
                        eventCategories
                          .filter((item) => item.id !== event?.id)
                          // .slice(0, 4)
                          .map((item) => (
                            <Link
                              key={item.id}
                              className="event-service sel-event"
                              to={`/event/${item.name
                                ?.toLowerCase()
                                ?.replaceAll(" ", "-")}`}
                            >
                              <div className="event-service-head">
                                <div>
                                  <div>
                                    <h3>{item.name}</h3>
                                    <div className="event-rsvp">
                                      {item.shortDescription}
                                    </div>
                                  </div>
                                </div>
                                <div className="pi pi-chevron-right"></div>
                              </div>
                            </Link>
                          ))}
                    </div>
                  </div>
                </div>
                <ProviderServices />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer params={"dashboard"} />
      <ConfirmDialog />
    </div>
  );
};
export default Eventspage;
