import React from "react";
import { Button } from "primereact/button";

const ButtonMailto = ({ mailto, label, subject, body }) => {

    const sendEmail = () => {

        const mailsubject = encodeURIComponent(subject);
        const mailbody = encodeURIComponent(body);
        const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${mailto}&su=${mailsubject}&body=${mailbody}`;
        window.open(url, "_blank");
        /* https://outlook.live.com/mail/0/deeplink/compose?popoutv2=1&to=johndoe@mail.com&subject=message&body=hey! 
        https://compose.mail.yahoo.com/?to=to_address&subject=subject&body=body  */

    }
    return (
        <Button
            icon="pi pi-send"
            iconPos="right"
            className="custom-button"
            label={label}
            size="small"
            onClick={sendEmail}
        />
    );
};

export default ButtonMailto;